import { useMediaQuery } from '@mui/material'
import { isTradeKeyEqual } from 'hooks/useV3Positions'
import { useCallback, useState } from 'react'
import { BREAKPOINTS } from 'theme'

import { useGetPositions } from '../Hooks'
import ClosePositionModal from '../Modal/ClosePositionModal'
import EditPositionModal from '../Modal/EditPositionModal'
import PositionTable from './PositionTable/PositionTable'
import PositionTableM from './PositionTable/PositionTable-m'

function PositionTableList() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const positions = useGetPositions()
  const [selectpositions, setSelectpositions] = useState<any>()

  const [showClose, setShowClose] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const currentPosition = positions?.filter((x: any) => isTradeKeyEqual(x, selectpositions ?? {}))?.[0]

  const handleDismissClose = useCallback(() => {
    setShowClose(false)
    setTimeout(() => {
      setSelectpositions(undefined)
    }, 400)
  }, [])

  const handleDismissEdit = () => {
    setShowEdit(false)
    setSelectpositions(undefined)
  }

  const handleCloseBtn = (data: any) => {
    setSelectpositions(data)
    setShowClose(true)
  }
  const handleEditBtn = (data: any) => {
    setSelectpositions(data)
    setShowEdit(true)
  }

  return (
    <>
      {isSmallScreen ? (
        <PositionTableM positions={positions} closePosition={handleCloseBtn} editPosition={handleEditBtn} />
      ) : (
        <PositionTable positions={positions} closePosition={handleCloseBtn} editPosition={handleEditBtn} />
      )}
      <ClosePositionModal isOpen={showClose} onDismiss={handleDismissClose} position={currentPosition} />
      <EditPositionModal position={currentPosition} isOpen={showEdit} onDismiss={handleDismissEdit} />
    </>
  )
}

export default PositionTableList
