import { Trans } from '@lingui/macro'
import { ChainId, Currency, CurrencyAmount, Percent, Price, Token } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { useActiveChainId } from 'connection/useActiveChainId'
import useAutoSlippageTolerance from 'hooks/useAutoSlippageTolerance'
import { usePositionTrade } from 'hooks/usePositionsTrade'
import { AllPoolsProps } from 'hooks/useQueryAllPools'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { feeProps } from 'pages/Trade/types'
import { ParsedQs } from 'qs'
import { ReactNode, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useUserSlippageToleranceWithDefault } from 'state/user/hooks'
import { BN, toWei } from 'utils/bn'

import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { compareAddress, isAddress } from '../../utils'
import { useCurrencyBalances } from '../connection/hooks'
import {
  Field,
  positionSelectCurrency,
  positionSwitchCurrencies,
  positionTypeInput,
  setLimit,
  setLong,
  setPoolInfo,
  setSliederLever,
  setToken0,
  TRADESATE,
} from './actions'
import { SwapState } from './reducer'

export function usePositionSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void
  onCurrencySelectionstring: (field: Field, currency: string) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeLong: (isLong: TRADESATE) => void
  onSliederLever: (sliederLever: number) => void
  onTradeInfo: (poolInfo: AllPoolsProps) => void
  onSetisToken0: (istoken0: boolean) => void
  onsetIsLimit: (islimit: boolean) => void
} {
  const dispatch = useAppDispatch()

  const state = useTradeSWAPState()

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        positionSelectCurrency({
          field,
          currencyId: currency.isToken ? currency.address : currency.isNative ? 'ETH' : '',
        })
      )
    },
    [dispatch]
  )
  const onCurrencySelectionstring = useCallback(
    (field: Field, currency: string) => {
      dispatch(
        positionSelectCurrency({
          field,
          currencyId: currency,
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(positionSwitchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(positionTypeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeLong = useCallback(
    (isLong: TRADESATE) => {
      if (!state.poolInfo) return
      if (state.isToken0) {
        if (isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, state.poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token1)
        } else {
          onCurrencySelection(Field.INPUT, state.poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token0)
        }
      } else {
        if (isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, state.poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token0)
        } else {
          onCurrencySelection(Field.INPUT, state.poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token1)
        }
      }

      dispatch(setLong({ isLong }))
      onUserInput(Field.INPUT, '')
    },
    [dispatch, onCurrencySelection, onUserInput, state.isToken0, state.poolInfo]
  )

  const onSliederLever = useCallback(
    (sliederLever: number) => {
      dispatch(setSliederLever({ sliederLever }))
    },
    [dispatch]
  )
  const onTradeInfo = useCallback(
    (poolInfo: AllPoolsProps) => {
      if (state.isToken0) {
        if (state.isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, poolInfo.token1)
        } else {
          onCurrencySelection(Field.INPUT, poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, poolInfo.token0)
        }
      } else {
        if (state.isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, poolInfo.token0)
        } else {
          onCurrencySelection(Field.INPUT, poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, poolInfo.token1)
        }
      }
      dispatch(setPoolInfo({ poolInfo }))
    },
    [dispatch, onCurrencySelection, state.isLong, state.isToken0]
  )

  const onSetisToken0 = useCallback(
    (isToken0: boolean) => {
      if (!state.poolInfo) return
      if (isToken0) {
        if (state.isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, state.poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token1)
        } else {
          onCurrencySelection(Field.INPUT, state.poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token0)
        }
      } else {
        if (state.isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, state.poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token0)
        } else {
          onCurrencySelection(Field.INPUT, state.poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token1)
        }
      }
      dispatch(setToken0({ isToken0 }))
      onUserInput(Field.INPUT, '')
    },
    [dispatch, onCurrencySelection, onUserInput, state.isLong, state.poolInfo]
  )

  const onsetIsLimit = useCallback(
    (islimit: boolean) => {
      dispatch(setLimit({ limit: islimit }))
      onUserInput(Field.INPUT, '')
    },
    [dispatch, onUserInput]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeLong,
    onSliederLever,
    onTradeInfo,
    onCurrencySelectionstring,
    onSetisToken0,
    onsetIsLimit,
  }
}

export function useTradeSWAPState() {
  return useAppSelector((state) => state.tradeSwap)
}

const BAD_RECIPIENT_ADDRESSES: { [address: string]: true } = {
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f': true, // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a': true, // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D': true, // v2 router 02
}

type SwapInfo = {
  currencies: { [field in Field]?: Currency | null }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmount?: CurrencyAmount<Currency>
  inputError?: ReactNode
  tradePool: {
    isLoading: boolean
    pool?: Pool
    poolAddr?: string
  }
  useInpuntAmount: {
    inputAmount?: CurrencyAmount<Currency>
    outputAount?: CurrencyAmount<Currency>
  }
  allowedSlippage: Percent
  autoSlippage: Percent
}

// from the current swap inputs, compute the best trade and return it.
export function usePositionSwapInfo(
  state: SwapState,
  chainId: ChainId | undefined,
  isLong: boolean,
  currentSqrtRatioX96?: Price<Token, Token> | undefined,
  fee?: feeProps,
  limitPrice?: string
): SwapInfo {
  const { account } = useActiveChainId()
  const { sliederLever } = useTradeSWAPState()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    poolInfo,
    isLimit,
  } = state

  const inputCurrency = useCurrency(inputCurrencyId, chainId)
  const outputCurrency = useCurrency(outputCurrencyId, chainId)
  const to: string | null = account ?? null

  const limitPriceRatio = useMemo(() => {
    if (!state || !inputCurrency || !outputCurrency || !limitPrice || !poolInfo || Number(limitPrice) <= 0) return

    const tokenA = CurrencyAmount.fromRawAmount(inputCurrency, toWei(1, inputCurrency.decimals).toFixed(0))
    const tokenB = CurrencyAmount.fromRawAmount(outputCurrency, toWei(limitPrice, outputCurrency.decimals).toFixed(0))

    const price = new Price({ baseAmount: tokenA, quoteAmount: tokenB })
    return price
  }, [inputCurrency, limitPrice, outputCurrency, poolInfo, state])

  const currentSqrtPrice = isLimit ? limitPriceRatio : currentSqrtRatioX96

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [inputCurrency ?? undefined, outputCurrency ?? undefined], [inputCurrency, outputCurrency])
  )

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  )

  const tradePool = usePositionTrade(inputCurrency ?? undefined, outputCurrency ?? undefined)

  const useInpuntAmount: {
    inputAmount?: CurrencyAmount<Currency>
    outputAount?: CurrencyAmount<Currency>
  } = useMemo(() => {
    if (!tradePool.pool || !inputCurrency || !outputCurrency || !parsedAmount || !currentSqrtPrice)
      return {
        inputAmount: parsedAmount,
        outputAount: undefined,
      }
    let inputAmount: any
    let outputAount: any

    if (compareAddress(outputCurrency.wrapped.address, tradePool.pool.token1.address)) {
      let limitPrice = currentSqrtPrice.toSignificant()

      if (!state.isToken0 && state.isLimit) {
        limitPrice = currentSqrtPrice.invert().toSignificant()
      }

      if (isExactIn) {
        inputAmount = parsedAmount
        const outAmount = BN(typedValue).times(sliederLever).times(limitPrice).toFixed()
        const pasAmount = toWei(outAmount, outputCurrency.decimals).toFixed(0)
        try {
          outputAount = CurrencyAmount.fromRawAmount(outputCurrency, pasAmount)
        } catch (error) {
          console.log('error', error)
        }
      } else {
        outputAount = parsedAmount

        const inputAmounts = BN(typedValue).div(sliederLever).div(limitPrice).toFixed()
        const pasAmount = toWei(inputAmounts, inputCurrency.decimals).toFixed(0)
        try {
          inputAmount = CurrencyAmount.fromRawAmount(inputCurrency, pasAmount)
        } catch (error) {
          console.log('error', error)
        }
      }
    } else {
      let limitPrice = currentSqrtPrice.invert().toSignificant()

      if (!state.isToken0) {
        if (state.isLimit) {
          limitPrice = currentSqrtPrice.toSignificant()
        }
      }

      if (isExactIn) {
        inputAmount = parsedAmount
        const outAmount = BN(sliederLever).times(typedValue).times(limitPrice).toFixed()

        const pasAmount = toWei(outAmount, outputCurrency.decimals).toFixed(0)
        try {
          outputAount = CurrencyAmount.fromRawAmount(outputCurrency, pasAmount)
        } catch (error) {
          console.log('error', error)
        }
      } else {
        outputAount = parsedAmount
        const input_amount = BN(typedValue).div(sliederLever).div(limitPrice).toFixed()

        const pasAmount = toWei(input_amount, inputCurrency.decimals).toFixed(0)
        try {
          inputAmount = CurrencyAmount.fromRawAmount(inputCurrency, pasAmount)
        } catch (error) {
          console.log('error', error)
        }
      }
    }

    return {
      inputAmount,
      outputAount,
    }
  }, [
    currentSqrtPrice,
    inputCurrency,
    isExactIn,
    outputCurrency,
    parsedAmount,
    sliederLever,
    state.isLimit,
    state.isToken0,
    tradePool.pool,
    typedValue,
  ])

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  )

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  )

  // allowed slippage for classic trades is either auto slippage, or custom user defined slippage if auto slippage disabled
  const classicAutoSlippage = useAutoSlippageTolerance(undefined)

  // slippage for uniswapx trades is defined by the quote response
  const uniswapXAutoSlippage = undefined

  // Roguex Interface recommended slippage amount
  const autoSlippage = uniswapXAutoSlippage ?? classicAutoSlippage
  const classicAllowedSlippage = useUserSlippageToleranceWithDefault(autoSlippage)

  // slippage amount used to submit the trade
  const allowedSlippage = uniswapXAutoSlippage ?? classicAllowedSlippage

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined

    if (!account) {
      inputError = <Trans>Connect Wallet</Trans>
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>
    }
    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>
    }

    const formattedTo = isAddress(to)
    if (!to || !formattedTo) {
      inputError = inputError ?? <Trans>Enter a recipient</Trans>
    } else {
      if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
        inputError = inputError ?? <Trans>Invalid recipient</Trans>
      }
    }

    if (isLimit && limitPrice == '') {
      inputError = <Trans>Please enter Limit price</Trans>
    }

    if (
      isExactIn &&
      parsedAmount &&
      relevantTokenBalances &&
      relevantTokenBalances[0] &&
      parsedAmount.greaterThan(relevantTokenBalances[0].asFraction)
    ) {
      inputError = <Trans> {parsedAmount.currency.symbol} Insufficient balance</Trans>
    }

    if (
      !isExactIn &&
      parsedAmount &&
      relevantTokenBalances &&
      relevantTokenBalances[1] &&
      parsedAmount.greaterThan(relevantTokenBalances[1].asFraction)
    ) {
      inputError = <Trans> {parsedAmount.currency.symbol} Insufficient balance</Trans>
    }
    if (state.isToken0) {
      if (
        state.isLong == TRADESATE.LONG &&
        fee &&
        useInpuntAmount.outputAount &&
        useInpuntAmount.outputAount.greaterThan(fee.liquidity0.toString())
      ) {
        inputError = <Trans> Insufficient liquidity for this trade.</Trans>
      }
      if (
        state.isLong == TRADESATE.SHORT &&
        fee &&
        useInpuntAmount.outputAount &&
        useInpuntAmount.outputAount.greaterThan(fee.liquidity1.toString())
      ) {
        inputError = <Trans> Insufficient liquidity for this trade.</Trans>
      }
    } else {
      if (
        state.isLong == TRADESATE.LONG &&
        fee &&
        useInpuntAmount.outputAount &&
        useInpuntAmount.outputAount.greaterThan(fee.liquidity1.toString())
      ) {
        inputError = <Trans> Insufficient liquidity for this trade.</Trans>
      }
      if (
        state.isLong == TRADESATE.SHORT &&
        fee &&
        useInpuntAmount.outputAount &&
        useInpuntAmount.outputAount.greaterThan(fee.liquidity0.toString())
      ) {
        inputError = <Trans> Insufficient liquidity for this trade.</Trans>
      }
    }
    return inputError
  }, [
    account,
    currencies,
    fee,
    isExactIn,
    isLimit,
    limitPrice,
    parsedAmount,
    relevantTokenBalances,
    state.isLong,
    state.isToken0,
    to,
    useInpuntAmount.outputAount,
  ])

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      tradePool,
      autoSlippage,
      allowedSlippage,
      useInpuntAmount,
    }),
    [allowedSlippage, autoSlippage, currencies, currencyBalances, inputError, parsedAmount, tradePool, useInpuntAmount]
  )
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    const upper = urlParam.toUpperCase()
    if (upper === 'ETH') return 'ETH'
    if (upper in TOKEN_SHORTHANDS) return upper
  }
  return ''
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount)
  const independentField = parseIndependentFieldURLParameter(parsedQs.exactField)

  if (inputCurrency === '' && outputCurrency === '' && typedValue === '' && independentField === Field.INPUT) {
    // Defaults to having the native currency selected
    inputCurrency = 'ETH'
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = ''
  }

  // const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency === '' ? null : inputCurrency ?? null,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency === '' ? null : outputCurrency ?? null,
    },
    typedValue,
    independentField,
    isLong: TRADESATE.LONG,
    isToken0: true,
    sliederLever: 1.1,
    poolInfo: undefined,
    isLimit: false,
  }
}
