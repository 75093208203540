import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as BlastIcon } from 'assets/imgs/airdrop/ranking/blast.svg'
import { ReactComponent as GoldIcon } from 'assets/imgs/airdrop/ranking/gold.svg'
import Column from 'components/Column'
import Row from 'components/Row'
import { useActiveChainId } from 'connection/useActiveChainId'
import { NoTokensStateM } from 'pages/Liquidity/Table'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme/components/text'
import { shortenAddress } from 'utils'
import { formatAmount } from 'utils/formatAmout'

const StyledTr = styled(Box)`
  display: grid;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  grid-template-columns: 50px 120px 1fr 1fr;
`

const StyledThHeader = styled(StyledTr)`
  border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
`

const StyledTh = styled(StyledTr)`
  &.rank1 {
    background: rgba(1, 147, 210, 0.4);
  }
  &.rank2 {
    background: rgba(1, 147, 210, 0.2);
  }
  &.rank3 {
    background: rgba(1, 147, 210, 0.1);
  }
  &.border {
    border-bottom: 1px dashed ${({ theme }) => theme.swapSectionBorder};
  }
`

const StyledTd = styled(Box)`
  padding: 0 6px;
`

export default function TableM({ rankingList, userPtsInfo }: any) {
  const { account } = useActiveChainId()
  const publicAddress = account?.toLowerCase()

  return (
    <Box>
      <StyledThHeader>
        <StyledTd>
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>#</Trans>
          </ThemedText.TextSecondary>
        </StyledTd>
        <StyledTd>
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Address</Trans>
          </ThemedText.TextSecondary>
        </StyledTd>
        <StyledTd>
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>PTS.</Trans>
          </ThemedText.TextSecondary>
        </StyledTd>
        <StyledTd>
          <Row justify="end">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>Earned</Trans>
            </ThemedText.TextSecondary>
          </Row>
        </StyledTd>
      </StyledThHeader>
      <div className="" style={{ position: 'relative' }}>
        <StyledTh>
          <StyledTd
          //  style={{ marginTop: '-16px' }}
          >
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              {formatAmount(userPtsInfo?.rank, 0, true)}
            </ThemedText.TextSecondary>
          </StyledTd>
          <StyledTd
          // style={{ marginTop: '-16px' }}
          >
            <ThemedText.TextSecondary fontSize={12} fontWeight={700} color="primaryBtn">
              {shortenAddress(publicAddress)}
            </ThemedText.TextSecondary>
          </StyledTd>
          <StyledTd>
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              {formatAmount(userPtsInfo?.pts_total, 2, true)} PTS.
            </ThemedText.TextSecondary>
          </StyledTd>
          <StyledTd>
            <Column gap="4px">
              <Row gap="4px" justify="end">
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  {formatAmount(userPtsInfo?.est_point, 2, true)} PTS.
                </ThemedText.TextSecondary>
                <BlastIcon width="12px" height="12px" />
              </Row>
              <Row gap="4px" justify="end">
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  {formatAmount(userPtsInfo?.est_gold, 2, true)} Gold
                </ThemedText.TextSecondary>
                <GoldIcon width="12px" height="12px" />
              </Row>
            </Column>
          </StyledTd>
        </StyledTh>
        {/* <div style={{ position: 'absolute', bottom: '6px', left: '6px' }}>
          <ShareBox />
        </div> */}
      </div>
      {rankingList?.length > 0 ? (
        rankingList?.map((item: any, i: number) => (
          <StyledTh key={i} className={i == 0 ? 'rank1' : i == 1 ? 'rank2' : i == 2 ? 'rank3' : 'border'}>
            <StyledTd>
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                {i + 1}
              </ThemedText.TextSecondary>
            </StyledTd>
            <StyledTd>
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                {shortenAddress(item.wallet_address)}
              </ThemedText.TextSecondary>
            </StyledTd>
            <StyledTd>
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                {formatAmount(item.pts_total, 2, true)} PTS.
              </ThemedText.TextSecondary>
            </StyledTd>
            <StyledTd>
              <Column gap="4px">
                <Row gap="4px" justify="end">
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    {formatAmount(item?.est_point, 2, true)} PTS.
                  </ThemedText.TextSecondary>
                  <BlastIcon width="12px" height="12px" />
                </Row>
                <Row gap="4px" justify="end">
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    {formatAmount(item?.est_gold, 2, true)} Gold
                  </ThemedText.TextSecondary>
                  <GoldIcon width="12px" height="12px" />
                </Row>
              </Column>
            </StyledTd>
          </StyledTh>
        ))
      ) : (
        <NoTokensStateM message={<Trans>No rankingList found</Trans>} />
      )}
    </Box>
  )
}
