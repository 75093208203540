import { Trans } from '@lingui/macro'
import { ClaimButton, Pending } from 'components/Button'
import Row from 'components/Row'
import { TableDataContainer } from 'components/Table'
import { ToastError } from 'components/Toast'
import { DEFAULT_VOTE_TOTAL } from 'constants/misc'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { useVoter } from 'hooks/useContract'
import { useAtom } from 'jotai'
import { useLiquidityLength } from 'pages/Liquidity/hooks'
import { StyledPagination } from 'pages/Liquidity/StyledPagination'
import { GridContainer, NoTokensState } from 'pages/Liquidity/Table'
import { useCallback, useRef, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { handlerError } from 'utils/formatError'

import { useVoteData } from './hooks'
import { VoteSortPage } from './state'
import { HeaderRow, LoadedRow, LoadingRow } from './VoteRow'

export const OptionsCard = styled(Row)`
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  height: 70px;
  width: 310px;
  padding: 8px;
  margin: 16px;
  background-color: ${({ theme }) => theme.optionsCardBg};
  border-radius: 32px;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.25);
  gap: 16px;

  .tabItem {
    flex: 1;
    height: 100%;
    border-radius: 18px;
    cursor: pointer;
  }

  .tabItemActive {
    background: ${({ theme }) => theme.rewardBtnBackground};
    border-radius: 36px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    bottom: 56px;
  }
`

export const StyledClaimButton = styled(ClaimButton)`
  min-height: 100%;
  width: 100%;
  border-radius: 36px;
`

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function Table({ NFTID, inputValue, handelChange }: any) {
  // const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)

  const [curr, setCurr] = useState<any>(0)

  const voteList = useVoteData()
  const loadingVotes = !voteList

  const voteParams = voteList?.map((item) => {
    return item.lpAddr
  })

  const voterContract = useVoter()
  const [attemptingTxn_reset, setAttemptingTxn_reset] = useState<boolean>(false)
  const [attemptingTxn_vote, setAttemptingTxn_vote] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const inputRef = useRef<HTMLInputElement>(null)

  const addTransaction = useTransactionAdder()
  const [liquiditySortPage, setVoteSortPage] = useAtom(VoteSortPage)
  const count = useLiquidityLength()
  const handleNextPage = (e: any, p: any) => {
    setVoteSortPage(p)
  }

  const handleReset = useCallback(async () => {
    if (!NFTID || !voterContract) return

    setAttemptingTxn_reset(true)

    try {
      const response = await voterContract?.reset(NFTID)
      setAttemptingTxn_reset(false)
      addTransaction(response, {
        type: TransactionType.RESET_VEROX,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn_reset(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('reset error', error)
    }
  }, [NFTID, addTransaction, voterContract])

  const handleVote = useCallback(async () => {
    if (!NFTID) {
      ToastError('You need to select a veROX to cast vote.')
    }
    const count = Object.keys(inputValue).reduce<number>((val, k) => {
      val = val + Number(inputValue[k]?.value)
      return val
    }, 0)
    if (!NFTID || !voteParams || !voterContract || count < DEFAULT_VOTE_TOTAL) return

    setAttemptingTxn_vote(true)

    const lpAddr = Object.keys(inputValue).reduce<string[]>((val, k) => {
      if (inputValue[k].value != 0) {
        val.push(inputValue[k].data.lpAddr)
      }
      return val
    }, [])
    const voteAmount = Object.keys(inputValue).reduce<string[]>((val, k) => {
      if (inputValue[k].value != 0) {
        val.push(inputValue[k].value)
      }
      return val
    }, [])
    try {
      const response = await voterContract?.vote(NFTID, lpAddr, voteAmount)
      setAttemptingTxn_vote(false)
      addTransaction(response, {
        type: TransactionType.VOTE_VEROX,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn_vote(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('vote error', error)
    }
  }, [NFTID, addTransaction, inputValue, voteParams, voterContract])

  /* loading and error state */
  if (loadingVotes && !voteList) {
    return <LoadingTokenTable rowCount={PAGE_SIZE} />
  } else if (!voteList) {
    return (
      <NoTokensState
        thRow={<HeaderRow />}
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading voteList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (voteList?.length === 0) {
    return <NoTokensState thRow={<HeaderRow />} message={<Trans>No voteList found</Trans>} />
  } else {
    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {voteList?.map((vote, index: any) => (
            <LoadedRow
              key={index}
              voteListIndex={index}
              voteListLength={voteList.length}
              vote={vote}
              onChange={handelChange}
              inputRef={inputRef}
              inputValue={inputValue}
            />
          ))}
        </TableDataContainer>
        <StyledPagination style={{ marginTop: '8px' }} count={count} onChange={handleNextPage} />
        <Row justify="center">
          <OptionsCard>
            <Row
              justify="center"
              className={`tabItem `}
              onClick={() => {
                setCurr(0), handleReset()
              }}
            >
              {curr == 0 ? (
                <StyledClaimButton disabled={attemptingTxn_reset}>
                  <ThemedText.BodySmall
                    fontSize={curr == 0 ? 16 : 14}
                    fontWeight={700}
                    color={curr == 0 ? 'optionsCardBg' : 'textSecondary'}
                    className=""
                  >
                    {attemptingTxn_reset ? <Pending /> : 'Reset'}
                  </ThemedText.BodySmall>
                </StyledClaimButton>
              ) : (
                <ThemedText.BodySmall
                  fontSize={curr == 0 ? 16 : 14}
                  fontWeight={700}
                  color={curr == 1 ? 'textSecondary' : 'optionsCardBg'}
                  className=""
                >
                  {attemptingTxn_reset ? <Pending /> : 'Reset'}
                </ThemedText.BodySmall>
              )}
            </Row>
            <Row
              justify="center"
              className={`tabItem `}
              onClick={() => {
                setCurr(1), handleVote()
              }}
            >
              {curr == 1 ? (
                <StyledClaimButton disabled={attemptingTxn_vote}>
                  <ThemedText.BodySmall
                    fontSize={curr == 1 ? 16 : 14}
                    fontWeight={700}
                    color={curr == 1 ? 'optionsCardBg' : 'textSecondary'}
                    className=""
                  >
                    {attemptingTxn_vote ? <Pending /> : 'Cast Votes'}
                  </ThemedText.BodySmall>
                </StyledClaimButton>
              ) : (
                <ThemedText.BodySmall
                  fontSize={curr == 1 ? 16 : 14}
                  fontWeight={700}
                  color={curr == 0 ? 'textSecondary' : 'optionsCardBg'}
                  className=""
                >
                  {attemptingTxn_vote ? <Pending /> : 'Cast Votes'}
                </ThemedText.BodySmall>
              )}
            </Row>
          </OptionsCard>
        </Row>
      </>
    )
  }
}
