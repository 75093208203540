import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { GridContainer, NoTokensStateM } from 'pages/Liquidity/Table'

import { LimitDataPrpos } from './hooks'
import { LoadedRow, LoadingRow } from './LimitRow-m'

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function TableM({ token01, resData }: { token01: boolean; resData: LimitDataPrpos[] | null }) {
  /* loading and error state */
  if (!resData) {
    return <LoadingTokenTable rowCount={5} />
  } else if (resData.length == 0) {
    return <NoTokensStateM message={<Trans>No limitList found</Trans>} />
  } else {
    return (
      <>
        <TableDataContainer>
          {resData.map((limit, index) => (
            <LoadedRow
              token01={token01}
              key={index}
              limitListIndex={index}
              limitListLength={resData.length}
              limit={limit}
            />
          ))}
        </TableDataContainer>
      </>
    )
  }
}
