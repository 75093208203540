import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import { ManagerButton } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { useCurrency } from 'hooks/Tokens'
import { useOrderBookContract } from 'hooks/useContract'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { useGetPositions, useTwapPrice } from 'pages/Trade/Hooks'
import EditLimitModal from 'pages/Trade/Modal/EditLimitModal'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useCallback, useMemo, useState } from 'react'
import { useTradeSWAPState } from 'state/positionSwap/hooks'
import { useTheme } from 'styled-components/macro'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromSqrt96Wei, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { EditBtn, LoadedRowProps, StyledBonus } from './LimitRow'
import { LimitSortMethod } from './state'

const EditBtnM = styled(EditBtn)`
  width: 100%;
`

/* Token Row: skeleton row component */
function LimitRow({
  orders,
  type,
  triggerPrice,
  markPrice,
  size,
  bonus,
  action,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  size: ReactNode
  bonus: ReactNode
  triggerPrice: ReactNode
  markPrice: ReactNode
  orders: ReactNode
  type: ReactNode
  action: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="pool-cell">{orders}</TextLeftCell>
        <TextRightCell data-testid="type-cell">{type}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="triggerPrice-cell">{triggerPrice}</TextLeftCell>
        <TextRightCell data-testid="vote-power-cell">{markPrice}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextRightCell data-testid="bonus-cell">{bonus}</TextRightCell>
        <TextRightCell data-testid="size-cell">{size}</TextRightCell>
      </RowBetween>
      <TextRightCell data-testid="action-cell">{action}</TextRightCell>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LimitRow
      $loading
      orders={
        <Row gap="sm">
          <IconLoadingBubble width="48px" height="48px" />
          <SmallLoadingBubble />
        </Row>
      }
      type={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LimitSortMethod.Type}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      triggerPrice={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LimitSortMethod.TriggerPrice}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      markPrice={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LimitSortMethod.MarkPrice}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      size={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LimitSortMethod.Size}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
          <SmallLoadingBubble />
        </Column>
      }
      bonus={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LimitSortMethod.Bonus}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      action={
        <Row justify="flex-end">
          <ManagerButton>Manager</ManagerButton>
        </Row>
      }
      {...props}
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { limitListIndex, limitListLength, limit } = props
  const [showEdit, setShowEdit] = useState(false)

  const theme = useTheme()
  const { isToken0 } = useTradeSWAPState()
  const currency0 = useCurrency(limit.token0)
  const currency1 = useCurrency(limit.token1)

  const twapPrice = useTwapPrice(limit.spotpool)
  const orderbookContract = useOrderBookContract()
  const allPostions = useGetPositions()

  const decimals = limit.long0 ? currency1?.decimals : currency0?.decimals
  const feesymbol = limit.long0 ? currency0?.symbol : currency1?.symbol
  const feesdecimals = limit.long0 ? currency0?.decimals : currency1?.decimals
  const sizeymbol = limit.long0 ? currency1?.symbol : currency0?.symbol

  const lever = useMemo(() => {
    if (!allPostions) return '1'
    const key = limit.perpPool + limit.long0
    const len = allPostions.filter((x) => {
      return x.pool + x.long0 === key
    })
    if (len.length > 0) {
      const data = BN(len[0].size.toString()).div(len[0].collateral.toString()).toFixed(2)
      return data
    }

    return '1.2'
  }, [allPostions, limit.long0, limit.perpPool])

  // const lever = useMemo(() => {
  //   const collateral = limit.collateralIn || limit.collateralDelta
  //   if (limit.long0) {
  //     return BN(
  //       BN(limit.sizeDelta.toString()).div(fromSqrt96Wei(limit.triggerPrice.toString()).toFixed()).toFixed()
  //     ).div(collateral.toString())
  //   } else {
  //     return BN(
  //       BN(limit.sizeDelta.toString()).multipliedBy(fromSqrt96Wei(limit.triggerPrice.toString()).toFixed()).toFixed()
  //     ).div(collateral.toString())
  //   }
  // }, [limit.collateralIn, limit.long0, limit.sizeDelta, limit.triggerPrice])

  const handleDismissEdit = () => {
    setShowEdit(false)
  }

  const closeOrder = useCallback(
    async (key: any) => {
      if (!orderbookContract) return
      await orderbookContract.cancelIncreaseOrder(key)
    },
    [orderbookContract]
  )

  const symbol = useMemo(() => {
    if (isToken0) {
      return ` ${currency0?.symbol}/${currency1?.symbol}`
    } else {
      return ` ${currency1?.symbol}/${currency0?.symbol}`
    }
  }, [currency0?.symbol, currency1?.symbol, isToken0])

  const triggerPrice = useMemo(() => {
    if (isToken0) {
      if (limit.long0) {
        return fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals).toFixed()
      } else {
        return BN(1)
          .div(fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals))
          .toFixed()
      }
    } else {
      if (limit.long0) {
        return BN(1)
          .div(fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals).toFixed())
          .toFixed()
      } else {
        return fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals).toFixed()
      }
    }
  }, [currency0?.decimals, currency1?.decimals, isToken0, limit.long0, limit.triggerPrice])

  const currenttwapPrice = useMemo(() => {
    if (isToken0) {
      return fromSqrt96Wei(twapPrice?.toString() || 0, currency0?.decimals, currency1?.decimals).toFixed()
    } else {
      return BN(1)
        .div(fromSqrt96Wei(twapPrice?.toString() || 0, currency0?.decimals, currency1?.decimals))
        .toFixed()
    }
  }, [currency0?.decimals, currency1?.decimals, isToken0, twapPrice])

  return (
    <>
      <div ref={ref} data-testid={`limit-table-row-${limit?.key}`}>
        <LimitRow
          orders={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LimitSortMethod.Orders}</Trans>
              </ThemedText.TextSecondary>
              <Row>
                <DoubleCurrencyLogo
                  size={30}
                  margin
                  currency0={isToken0 ? currency1 ?? undefined : currency0 ?? undefined}
                  currency1={!isToken0 ? currency1 ?? undefined : currency0 ?? undefined}
                />
                <Column gap="xs" align="flex-start" ml="8px">
                  <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                    {symbol}
                  </ThemedText.TextPrimary>
                  <Row gap="xs">
                    <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                      {lever}x
                    </ThemedText.TextSecondary>
                    {isToken0 ? limit.long0 ? <LongIcon /> : <ShortIcon /> : limit.long0 ? <ShortIcon /> : <LongIcon />}
                  </Row>
                </Column>
              </Row>
            </Column>
          }
          type={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LimitSortMethod.Type}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {limit.type}
              </ThemedText.TextPrimary>
            </Column>
          }
          triggerPrice={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LimitSortMethod.TriggerPrice}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {limit.triggerAboveThreshold ? '>' : '<'} {countZeros(triggerPrice)}
              </ThemedText.TextPrimary>
            </Column>
          }
          markPrice={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LimitSortMethod.MarkPrice}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {countZeros(currenttwapPrice)}
              </ThemedText.TextPrimary>
            </Column>
          }
          size={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LimitSortMethod.Size}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary>
                {countZeros(fromWei(limit.sizeDelta.toString(), decimals).toFixed())} {sizeymbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          bonus={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LimitSortMethod.Bonus}</Trans>
              </ThemedText.TextSecondary>
              <StyledBonus className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}>
                {countZeros(fromWei(limit.executionFee.toString(), feesdecimals).toFixed())} {feesymbol}
              </StyledBonus>
            </Column>
          }
          action={
            <Row align="center" gap="sm">
              <EditBtnM onClick={() => closeOrder(limit.key)}>
                <Trans>Close</Trans>
              </EditBtnM>
              <EditBtnM onClick={() => setShowEdit(true)}>
                <Trans>Edit</Trans>
              </EditBtnM>
            </Row>
          }
          first={limitListIndex === 0}
          last={limitListIndex === limitListLength - 1}
        />
      </div>

      <EditLimitModal isOpen={showEdit} onDismiss={handleDismissEdit} limit={limit} />
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
