import { useMediaQuery } from '@mui/material'
import { BREAKPOINTS } from 'theme'

import Table from './Table'
import TableM from './Table-m'

export function LiquidityTable() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  return <>{isSmallScreen ? <TableM /> : <Table />}</>
}
