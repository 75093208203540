import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import Column from 'components/Column'
import { useFetchVoter } from 'pages/Vote/hooks'
import { VoteDataProps } from 'pages/Vote/types'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { formatAmount } from 'utils/formatAmout'

import SearchBar from './SearchBar'

const LiquidityContentBox = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.primaryBorder};
  .liquidityInfo {
    padding-top: 24px;
    padding-bottom: 40px;
  }
  .searchBox {
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`

export default function ContentWrapper() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isMobileScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`)

  const leaderboardList = useFetchVoter()

  const totalRewardUSD = useMemo(() => {
    if (!leaderboardList || !leaderboardList.voteList) return 0
    if (leaderboardList.voteList.length == 0) return 0
    let totalReward = 0
    leaderboardList.voteList.map((leaderboard: VoteDataProps) => {
      let totalETH = 0
      leaderboard?.swapMinningrewardsLists?.map((item) => {
        totalETH +=
          (Number(item.tokenInfo?.derivedETH) * Number(item.rewardAmount)) /
          Math.pow(10, Number(item.tokenInfo.decimals))
      })
      totalReward += totalETH * Number(leaderboard?.ethPrice)
    })
    return totalReward
  }, [leaderboardList])

  const totalVolumeUSD = useMemo(() => {
    if (leaderboardList?.voteList?.length == 0) return 0
    let totalVolume = 0
    leaderboardList?.voteList?.map((leaderboard: VoteDataProps) => {
      const ETHAmount =
        (Number(leaderboard.token0?.derivedETH) * Number(leaderboard.totalVolume)) /
        Math.pow(10, Number(leaderboard.token0.decimals))
      totalVolume += ETHAmount * Number(leaderboard?.ethPrice)
    })
    return totalVolume
  }, [leaderboardList.voteList])

  const totalTraders = useMemo(() => {
    if (leaderboardList?.voteList?.length == 0) return 0
    let totalTraders = 0
    leaderboardList?.voteList?.map((leaderboard: VoteDataProps) => {
      if (leaderboard.swapMinningInfo?.swapedUser && leaderboard.swapMinningInfo?.swapedUser.length > 0) {
        totalTraders += leaderboard.swapMinningInfo?.swapedUser.length
      }
    })
    return totalTraders
  }, [leaderboardList.voteList])

  return (
    <LiquidityContentBox>
      <Box
        display="flex"
        flexDirection={isMobileScreen ? 'column' : 'row'}
        justifyContent="space-between"
        gap={isMobileScreen ? '20px' : '8px'}
        className="liquidityInfo"
      >
        <Column gap="xs" align="center">
          <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
            <Trans>Volume</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700}>
            ${formatAmount(totalVolumeUSD, 2, true)}
          </ThemedText.TextPrimary>
        </Column>
        <Column gap="xs" align="center">
          <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
            <Trans>Total Traders</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700}>
            {formatAmount(totalTraders, 2, true)}
          </ThemedText.TextPrimary>
        </Column>
        <Column gap="xs" align="center">
          <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
            <Trans>Total Reward</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700}>
            ${formatAmount(totalRewardUSD, 2, true)}
          </ThemedText.TextPrimary>
        </Column>
      </Box>
      <div className="searchBox">
        <SearchBar />
      </div>
    </LiquidityContentBox>
  )
}
