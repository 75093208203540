import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { NumberType } from '@uniswap/conedison/format'
import { Currency, CurrencyAmount, Fraction, Percent, Token } from '@uniswap/sdk-core'
import { NonfungiblePositionManager, Position } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import { ButtonPrimary, StyledPendingIcon } from 'components/Button'
import { FarmPoolCard, LightCard, LiquidityCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { getPriceOrderingFromPositionForUI } from 'components/PositionListItem'
import Row, { RowBetween, RowFixed } from 'components/Row'
import { ToastError } from 'components/Toast'
import Toggle from 'components/Toggle'
import {
  ConfirmationModalContent2222,
  TransactionConfirmationModal22222,
} from 'components/TransactionConfirmationModal'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useToken } from 'hooks/Tokens'
import { useRoxUtils, useV3Factory, useV3NFTPositionManagerContract } from 'hooks/useContract'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { usePool } from 'hooks/usePools'
import useStablecoinPrice from 'hooks/useStablecoinPrice'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { usePerpInfoValue } from 'pages/AddLiquidity/hooks'
import PerpInfo from 'pages/AddLiquidity/PerpInfo'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Bound } from 'state/mint/v3/actions'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { compareAddress } from 'utils'
import { BN } from 'utils/bn'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { currencyId } from 'utils/currencyId'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { handlerError } from 'utils/formatError'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

import {
  ActionButtonResponsiveRow,
  BadgeText,
  CollectButton,
  DoubleArrow,
  getRatio,
  IncreaseButton,
  Label,
  LinkedCurrency,
  PositionPageUnsupportedContent,
  PriceCard,
  ResponsiveButtonConfirmed,
  ResponsiveRow,
  StyledCardTitle,
  StyledUnclaimedCard,
  useInverter,
} from '../StyledPosition'
import { Dots } from '../styleds'
import CurrentPriceCard from './CurrentPriceCard'
import PositionNavList from './PositionNavList'

interface PositionManageProps {
  isOpen: boolean
  onDismiss: () => void
  tokenIdFromUrl?: string
}

// eslint-disable-next-line import/no-unused-modules
export default function PositionManageModal({ isOpen, onDismiss, tokenIdFromUrl }: PositionManageProps) {
  const [curr, setCurr] = useState<any>('')
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const handleDismissConfirmation = () => {
    onDismiss && onDismiss()
    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash('')
      setTxError('')
    }, 300)
  }

  const [curr2, setCurr2] = useState<any>(0)
  const [utilizationValue, setUtilizationValue] = useState<any>(50)
  const [thresholdValue, setThresholdValue] = useState<any>(70)
  const [thresholdRemoveValue, setThresholdRemoveValue] = useState<any>(80)
  const [leverageValue, setLeverageValue] = useState<any>(51)
  const [timeValue_TWAP, setTimeValue_TWAP] = useState<any>(30)
  const [timeValue_close, setTimeValue_close] = useState<any>(10)

  const { chainId, account, provider } = useActiveChainId()
  const theme = useTheme()

  const parsedTokenId = tokenIdFromUrl ? BigNumber.from(tokenIdFromUrl) : undefined
  const { loading, position: positionDetails } = useV3PositionFromTokenId(parsedTokenId)

  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    tokenId,
  } = positionDetails || {}

  const removed = liquidity?.eq(0)

  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  // flag for receiving WETH
  const [receiveWETH, setReceiveWETH] = useState(false)
  const nativeCurrency = useNativeCurrency(chainId)
  const nativeWrappedSymbol = nativeCurrency.wrapped.symbol

  // construct Position from details returned
  const [, pool] = usePool(token0 ?? undefined, token1 ?? undefined, feeAmount)
  const position = useMemo(() => {
    if (pool && liquidity && typeof tickLower === 'number' && typeof tickUpper === 'number') {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])
  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  const pricesFromPosition = getPriceOrderingFromPositionForUI(position)
  const [manuallyInverted, setManuallyInverted] = useState(false)

  // handle manual inversion
  const { priceLower, priceUpper, base } = useInverter({
    priceLower: pricesFromPosition.priceLower,
    priceUpper: pricesFromPosition.priceUpper,
    quote: pricesFromPosition.quote,
    base: pricesFromPosition.base,
    invert: manuallyInverted,
  })
  const poolfactoryContract = useV3Factory()
  const { result: spotPoolAddress } = useSingleCallResult(poolfactoryContract, 'getPool', [
    token0Address ?? undefined,
    token1Address ?? undefined,
    feeAmount ?? undefined,
  ])
  const { result: OwnerAddress } = useSingleCallResult(poolfactoryContract, 'spotOwner', [
    spotPoolAddress?.toString() ?? undefined,
  ])
  const perpInfo: any = usePerpInfoValue(spotPoolAddress?.toString())
  useEffect(() => {
    if (perpInfo) {
      setUtilizationValue(BN(perpInfo.perpThres).div(10).toFixed(0))
      setThresholdValue(BN(perpInfo.setlThres).div(10).toFixed(0))
      setThresholdRemoveValue(BN(perpInfo.spotThres).div(10).toFixed(0))
      setTimeValue_TWAP(BN(perpInfo.twapTime).toFixed(0))
      setTimeValue_close(BN(perpInfo.countMin).toFixed(0))
      setLeverageValue(BN(perpInfo.maxLeverage).toFixed(0))
    }
  }, [perpInfo])

  const inverted = token1 ? base?.equals(token1) : undefined
  const currencyQuote = inverted ? currency0 : currency1
  const currencyBase = inverted ? currency1 : currency0

  const ratio = useMemo(() => {
    return priceLower && pool && priceUpper
      ? getRatio(
          inverted ? priceUpper.invert() : priceLower,
          pool.token0Price,
          inverted ? priceLower.invert() : priceUpper
        )
      : undefined
  }, [inverted, pool, priceLower, priceUpper])

  // fees
  const [feeValue0, feeValue1, spotfee0, spotfee1, perp0, perp1] = useV3PositionFees(
    pool ?? undefined,
    positionDetails,
    receiveWETH
  )

  const [collecting] = useState<boolean>(false)
  const [collectMigrationHash] = useState<string | null>(null)
  const isCollectPending = useIsTransactionPending(collectMigrationHash ?? undefined)
  const [showConfirm, setShowConfirm] = useState(false)

  // usdc prices always in terms of tokens
  const price0 = useStablecoinPrice(token0 ?? undefined)
  const price1 = useStablecoinPrice(token1 ?? undefined)

  const fiatValueOfFees: CurrencyAmount<Currency> | null = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null

    const amount0 = price0.quote(feeValue0Wrapped)
    const amount1 = price1.quote(feeValue1Wrapped)
    return amount0.add(amount1)
  }, [price0, price1, feeValue0, feeValue1])

  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  const positionManager = useV3NFTPositionManagerContract()
  const owner = useSingleCallResult(tokenId ? positionManager : null, 'ownerOf', [tokenId]).result?.[0]
  const ownsNFT = owner === account || positionDetails?.operator === account

  const feeValueUpper = inverted ? feeValue0 : feeValue1
  const feeValueLower = inverted ? feeValue1 : feeValue0

  const infoFees = useMemo(() => {
    let feeValueUpper_spot: any = BN(0)
    let feeValueUpper_perp: any = BN(0)
    let feeValueLower_spot: any = BN(0)
    let feeValueLower_perp: any = BN(0)
    if (feeValueUpper?.currency && feeValueLower?.currency && positionDetails?.token0) {
      const upperIsToken0 = compareAddress(positionDetails?.token0, feeValueUpper?.currency.wrapped.address)
      if (upperIsToken0) {
        feeValueUpper_spot = spotfee0?.toExact()
        feeValueUpper_perp = perp0?.toExact()
        feeValueLower_spot = spotfee1?.toExact()
        feeValueLower_perp = perp1?.toExact()
      } else {
        feeValueUpper_spot = spotfee1?.toExact()
        feeValueUpper_perp = perp1?.toExact()
        feeValueLower_spot = spotfee0?.toExact()
        feeValueLower_perp = perp0?.toExact()
      }
    }
    return {
      feeValueLower_spot,
      feeValueLower_perp,
      feeValueUpper_spot,
      feeValueUpper_perp,
    }
  }, [feeValueUpper?.currency, feeValueLower?.currency, positionDetails?.token0, spotfee0, perp0, spotfee1, perp1])

  const currency0ForFeeCollectionPurposes = pool ? (receiveWETH ? pool.token0 : unwrappedToken(pool.token0)) : undefined
  const currency1ForFeeCollectionPurposes = pool ? (receiveWETH ? pool.token1 : unwrappedToken(pool.token1)) : undefined

  const showCollectAsWeth = Boolean(
    ownsNFT && currency0 && currency1 && (currency0.isNative || currency1.isNative) && !collectMigrationHash
  )
  const [IncreasepoolNode, setIncreasepoolNode] = useState<any>(undefined)

  const addTransaction = useTransactionAdder()

  function modalHeader() {
    return (
      <AutoColumn gap="md" style={{ marginTop: '20px' }}>
        <LightCard padding="12px 16px">
          <AutoColumn gap="md">
            <RowBetween>
              <RowFixed>
                <CurrencyLogo currency={feeValueUpper?.currency} size="30px" style={{ marginRight: '0.5rem' }} />
                <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                  {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}
                </ThemedText.TextPrimary>
              </RowFixed>
              <ThemedText.TextSecondary fontWeight={700}>{feeValueUpper?.currency?.symbol}</ThemedText.TextSecondary>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <CurrencyLogo currency={feeValueLower?.currency} size="30px" style={{ marginRight: '0.5rem' }} />
                <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                  {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}
                </ThemedText.TextPrimary>
              </RowFixed>
              <ThemedText.TextSecondary fontWeight={700}>{feeValueLower?.currency?.symbol}</ThemedText.TextSecondary>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <ThemedText.DeprecatedItalic>
          <Trans>Collecting fees will withdraw currently available fees for you.</Trans>
        </ThemedText.DeprecatedItalic>
        <ButtonPrimary data-testid="modal-collect-fees-button" onClick={collect}>
          <Trans>Collect</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }

  const collect = useCallback(() => {
    if (
      !currency0ForFeeCollectionPurposes ||
      !currency1ForFeeCollectionPurposes ||
      !chainId ||
      !positionManager ||
      !account ||
      !tokenId ||
      !provider
    )
      return

    setAttemptingTxn(true)

    // we fall back to expecting 0 fees in case the fetch fails, which is safe in the
    // vast majority of cases
    const { calldata, value } = NonfungiblePositionManager.collectCallParameters({
      tokenId: tokenId.toString(),
      expectedCurrencyOwed0: feeValue0 ?? CurrencyAmount.fromRawAmount(currency0ForFeeCollectionPurposes, 0),
      expectedCurrencyOwed1: feeValue1 ?? CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0),
      recipient: account,
    })

    const txn = {
      to: positionManager.address,
      data: calldata,
      value,
    }

    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        }

        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            // setCollectMigrationHash(response.hash)
            // setCollecting(false)

            setTxHash(response.hash)

            addTransaction(response, {
              type: TransactionType.COLLECT_FEES,
              currencyId0: currencyId(currency0ForFeeCollectionPurposes),
              currencyId1: currencyId(currency1ForFeeCollectionPurposes),
              expectedCurrencyOwed0:
                feeValue0?.quotient.toString() ??
                CurrencyAmount.fromRawAmount(currency0ForFeeCollectionPurposes, 0).toExact(),
              expectedCurrencyOwed1:
                feeValue1?.quotient.toString() ??
                CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0).toExact(),
            })
          })
      })
      .catch((error) => {
        // setCollecting(false)
        setAttemptingTxn(false)
        setTxError(handlerError(error))
        console.error('error', error)
      })
  }, [
    currency0ForFeeCollectionPurposes,
    currency1ForFeeCollectionPurposes,
    chainId,
    positionManager,
    account,
    tokenId,
    provider,
    feeValue0,
    feeValue1,
    addTransaction,
  ])
  const roxUtilsContrct = useRoxUtils()

  const handleUpdateInfo = useCallback(async () => {
    if (!roxUtilsContrct || !spotPoolAddress || !account) return
    setAttemptingTxn(true)
    try {
      const tx = await roxUtilsContrct.modifyPoolSetting(
        spotPoolAddress.toString(),
        leverageValue,
        BN(thresholdRemoveValue).multipliedBy(10).toString(),
        BN(utilizationValue).multipliedBy(10).toString(),
        BN(thresholdValue).multipliedBy(10).toString(),
        '6000',
        timeValue_TWAP,
        timeValue_close,
        false
      )

      setAttemptingTxn(false)
      addTransaction(tx, {
        type: TransactionType.UPDATE,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(tx.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('UPDATE error', error)
    }
  }, [
    roxUtilsContrct,
    spotPoolAddress,
    account,
    leverageValue,
    thresholdRemoveValue,
    utilizationValue,
    thresholdValue,
    timeValue_TWAP,
    timeValue_close,
    addTransaction,
  ])

  if (!positionDetails && !loading) {
    return <PositionPageUnsupportedContent />
  }

  const showIncreasepoolNode = (data: any) => {
    setIncreasepoolNode(data)
  }

  const showFEES = () => {
    showIncreasepoolNode(modalHeader())
  }

  const Increasepool = (
    <Column gap="sm">
      <ResponsiveRow>
        <RowFixed>
          <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={40} margin={true} />
          <ThemedText.TextPrimary fontSize="36px" fontWeight={800} mr="12px">
            &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
          </ThemedText.TextPrimary>
          <Badge>
            <BadgeText className="active-gradient-text">
              <Trans>{feeAmount ? new Percent(feeAmount, 1_000_000).toSignificant() : '--'}%</Trans>
            </BadgeText>
          </Badge>
        </RowFixed>
        <CurrentPriceCard
          inverted={inverted}
          pool={pool}
          currencyQuote={currencyQuote}
          currencyBase={currencyBase}
          manuallyInverted={manuallyInverted}
          setManuallyInverted={setManuallyInverted}
        />
      </ResponsiveRow>
      <Row mt="16px">
        <PriceCard>
          <ThemedText.TextSecondary fontSize={14}>
            <Trans>Min price</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextSecondary fontWeight={700}>
            {formatTickPrice({
              price: priceLower,
              atLimit: tickAtLimit,
              direction: Bound.LOWER,
              numberType: NumberType.TokenTx,
            })}{' '}
            <Trans>
              {currencyQuote?.symbol} per {currencyBase?.symbol}
            </Trans>
          </ThemedText.TextSecondary>
          <DoubleArrow>————</DoubleArrow>
          <ThemedText.TextSecondary fontSize={14}>
            <Trans>Max price</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextSecondary fontWeight={700}>
            {formatTickPrice({
              price: priceUpper,
              atLimit: tickAtLimit,
              direction: Bound.UPPER,
              numberType: NumberType.TokenTx,
            })}{' '}
            <Trans>
              {currencyQuote?.symbol} per {currencyBase?.symbol}
            </Trans>
          </ThemedText.TextSecondary>
        </PriceCard>
      </Row>
      <Row mt="8px">
        <ResponsiveRow align="flex-start">
          <RowBetween align="stretch" style={{ width: '100%', height: '100%' }}>
            <Column gap="sm" style={{ flex: 1 }}>
              <FarmPoolCard>
                <Column gap="xmd">
                  <StyledCardTitle>
                    <Label>
                      <Trans>Liquidity</Trans>
                    </Label>
                    {fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100)) ? (
                      <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                        <Trans>${fiatValueOfLiquidity.toFixed(2, { groupSeparator: ',' })}</Trans>
                      </ThemedText.TextPrimary>
                    ) : (
                      <ThemedText.TextPrimary fontSize={24} fontWeight={700} color={theme.textPrimary}>
                        <Trans>$-</Trans>
                      </ThemedText.TextPrimary>
                    )}
                  </StyledCardTitle>
                  <LiquidityCard>
                    <AutoColumn gap="xmd">
                      <RowBetween>
                        <LinkedCurrency chainId={chainId} currency={currencyQuote} />
                        <RowFixed>
                          <ThemedText.DeprecatedMain>
                            {inverted ? position?.amount0.toSignificant(4) : position?.amount1.toSignificant(4)}
                          </ThemedText.DeprecatedMain>
                          {typeof ratio === 'number' && !removed ? (
                            <Badge style={{ marginLeft: '10px' }}>
                              <BadgeText className="active-gradient-text">{inverted ? ratio : 100 - ratio}%</BadgeText>
                            </Badge>
                          ) : null}
                        </RowFixed>
                      </RowBetween>
                      <RowBetween>
                        <LinkedCurrency chainId={chainId} currency={currencyBase} />
                        <RowFixed>
                          <ThemedText.DeprecatedMain>
                            {inverted ? position?.amount1.toSignificant(4) : position?.amount0.toSignificant(4)}
                          </ThemedText.DeprecatedMain>
                          {typeof ratio === 'number' && !removed ? (
                            <Badge style={{ marginLeft: '10px' }}>
                              <BadgeText className="active-gradient-text">{inverted ? 100 - ratio : ratio}%</BadgeText>
                            </Badge>
                          ) : null}
                        </RowFixed>
                      </RowBetween>
                    </AutoColumn>
                  </LiquidityCard>
                  {ownsNFT && !curr && (
                    <ActionButtonResponsiveRow>
                      {currency0 && currency1 && feeAmount && tokenId ? (
                        <IncreaseButton
                          onClick={() => {
                            setCurr(1)
                          }}
                          width="fit-content"
                        >
                          <Trans>+ Increase</Trans>
                        </IncreaseButton>
                      ) : null}
                      {tokenId && !removed ? (
                        <IncreaseButton
                          onClick={() => {
                            setCurr(2)
                          }}
                          width="fit-content"
                        >
                          <Trans>- Remove</Trans>
                        </IncreaseButton>
                      ) : null}
                    </ActionButtonResponsiveRow>
                  )}
                </Column>
              </FarmPoolCard>
            </Column>
            <Column gap="sm" style={{ flex: 1 }}>
              <Box ml="16px" width="100%" style={{ maxWidth: '-webkit-fill-available' }}>
                <StyledUnclaimedCard>
                  <AutoColumn gap="xmd">
                    <StyledCardTitle>
                      <Label>
                        <Trans>Unclaimed fees</Trans>
                      </Label>
                      {fiatValueOfFees?.greaterThan(new Fraction(1, 100)) ? (
                        <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                          <Trans>
                            <div className="active-gradient-text">
                              ${fiatValueOfFees.toFixed(2, { groupSeparator: ',' })}
                            </div>
                          </Trans>
                        </ThemedText.TextPrimary>
                      ) : (
                        <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                          <Trans>
                            <div className="active-gradient-text">$-</div>
                          </Trans>
                        </ThemedText.TextPrimary>
                      )}
                    </StyledCardTitle>
                    {ownsNFT && !!collectMigrationHash ? (
                      <ResponsiveButtonConfirmed
                        data-testid="collect-fees-button"
                        // disabled={collecting || !!collectMigrationHash}
                        // confirmed={!!collectMigrationHash && !isCollectPending}
                        width="fit-content"
                        style={{ borderRadius: '12px' }}
                        padding="4px 8px"
                      >
                        {!!collectMigrationHash && !isCollectPending ? (
                          <ThemedText.DeprecatedMain color={theme.textPrimary}>
                            <Trans> Collected</Trans>
                          </ThemedText.DeprecatedMain>
                        ) : isCollectPending || collecting ? (
                          <ThemedText.DeprecatedMain color={theme.textPrimary}>
                            <Dots>
                              <Trans>Collecting</Trans>
                              <StyledPendingIcon />
                            </Dots>
                          </ThemedText.DeprecatedMain>
                        ) : (
                          <>
                            <ThemedText.DeprecatedMain color={theme.white}>
                              <Trans>Collect fees</Trans>
                            </ThemedText.DeprecatedMain>
                          </>
                        )}
                      </ResponsiveButtonConfirmed>
                    ) : null}
                    <LiquidityCard onClick={showFEES}>
                      <AutoColumn gap="xmd">
                        <RowBetween align="flex-start">
                          <RowFixed>
                            <CurrencyLogo currency={feeValueUpper?.currency} size="30px" />
                          </RowFixed>
                          <Column align="flex-end" gap="4px">
                            <RowFixed gap="6px">
                              <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                                {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}
                              </ThemedText.TextPrimary>
                              <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                                {feeValueUpper?.currency?.symbol}
                              </ThemedText.TextPrimary>
                              <ThemedText.TextSecondary>()</ThemedText.TextSecondary>
                            </RowFixed>
                            <ThemedText.TextTertiary fontSize={12} mt={2}>
                              Spot/Perp
                            </ThemedText.TextTertiary>
                            <ThemedText.TextSecondary fontSize={15}>
                              {formatAmount(infoFees?.feeValueUpper_spot, 2, true)} +{' '}
                              {formatAmount(infoFees?.feeValueUpper_perp, 2, true)}
                            </ThemedText.TextSecondary>
                          </Column>
                        </RowBetween>
                        <RowBetween align="flex-start">
                          <RowFixed>
                            <CurrencyLogo currency={feeValueLower?.currency} size="30px" />
                          </RowFixed>
                          <Column align="flex-end" gap="4px">
                            <RowFixed gap="6px">
                              <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                                {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}
                              </ThemedText.TextPrimary>
                              <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                                {feeValueLower?.currency?.symbol}
                              </ThemedText.TextPrimary>
                              <ThemedText.TextSecondary>()</ThemedText.TextSecondary>
                            </RowFixed>
                            <ThemedText.TextTertiary fontSize={12} mt={2}>
                              Spot/Perp
                            </ThemedText.TextTertiary>
                            <ThemedText.TextSecondary fontSize={15}>
                              {formatAmount(infoFees?.feeValueLower_spot, 2, true)} +{' '}
                              {formatAmount(infoFees?.feeValueLower_perp, 2, true)}
                            </ThemedText.TextSecondary>
                          </Column>
                        </RowBetween>
                      </AutoColumn>
                    </LiquidityCard>
                    <AutoColumn gap="md">
                      <RowBetween>
                        {!showCollectAsWeth && <div></div>}
                        {showCollectAsWeth && (
                          <Row gap="sm">
                            <ThemedText.DeprecatedMain>
                              <Trans>Collect as {nativeWrappedSymbol}</Trans>
                            </ThemedText.DeprecatedMain>
                            <Toggle
                              id="receive-as-weth"
                              isActive={receiveWETH}
                              toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
                            />
                          </Row>
                        )}
                        <CollectButton onClick={showFEES}>Collect fees</CollectButton>
                      </RowBetween>
                    </AutoColumn>
                  </AutoColumn>
                </StyledUnclaimedCard>
              </Box>
            </Column>
          </RowBetween>
        </ResponsiveRow>
      </Row>

      {curr && (
        <PositionNavList
          curr={curr}
          setCurr={setCurr}
          setshowIncreasePool={showIncreasepoolNode}
          setshowRemovePool={showIncreasepoolNode}
          currencyIdA={currency0 ? currencyId(currency0) : ''}
          currencyIdB={currency1 ? currencyId(currency1) : ''}
          feeAmountFromUrl={feeAmount ? feeAmount.toString() : ''}
          setAttemptingTxn={setAttemptingTxn}
          setTxHash={setTxHash}
          setTxError={setTxError}
          tokenId={Number(tokenId)}
        />
      )}
      {OwnerAddress && account && OwnerAddress.toString().toLocaleLowerCase() == account.toLocaleLowerCase() && (
        <PerpInfo
          showUpDate={true}
          upFunc={handleUpdateInfo}
          isPoolOwner={false}
          setTimeValue_close={setTimeValue_close}
          timeValue_close={timeValue_close}
          setTimeValue_TWAP={setTimeValue_TWAP}
          timeValue_TWAP={timeValue_TWAP}
          setThresholdRemoveValue={setThresholdRemoveValue}
          thresholdRemoveValue={thresholdRemoveValue}
          curr={curr2}
          setCurr={setCurr2}
          utilizationValue={utilizationValue}
          setUtilizationValue={setUtilizationValue}
          thresholdValue={thresholdValue}
          setThresholdValue={setThresholdValue}
          leverageValue={leverageValue}
          setLeverageValue={setLeverageValue}
        />
      )}
    </Column>
  )

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      width={IncreasepoolNode ? '500px' : '800px'}
      reviewContent={
        <ConfirmationModalContent2222
          title={
            <Trans>
              {currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol} Farm Pool
            </Trans>
          }
          onDismiss={handleDismissConfirmation}
          topContent={IncreasepoolNode ? IncreasepoolNode : Increasepool}
        />
      }
      pendingText={pendingText}
    />
  )
}
