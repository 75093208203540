import { Box } from '@mui/material'
import LockTableList from 'pages/Lock/LockTableList'
import styled from 'styled-components/macro'

const VeROXBox = styled(Box)`
  margin-top: 24px;
`

export default function VeROX() {
  return (
    <VeROXBox>
      <LockTableList />
    </VeROXBox>
  )
}
