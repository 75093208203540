import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ReactComponent as BlastIcon } from 'assets/imgs/airdrop/ranking/blast.svg'
import { ReactComponent as GoldIcon } from 'assets/imgs/airdrop/ranking/gold.svg'
import dayjs from 'dayjs'
import { useState } from 'react'
// import { ReactComponent as EndIcon } from 'assets/imgs/airdrop/ranking/end.svg'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { formatAmount } from 'utils/formatAmout'

import RoundEpoch from './RoundEpoch'

const StyledActive = styled(Box)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: rgba(4, 174, 62, 0.2);
  border-radius: 4px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 8px;
  }
`

const StyledEnded = styled(Box)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: ${({ theme }) => theme.progressPrimary};
  border-radius: 4px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 8px;
  }
`

export default function RoundInfo({ setEpoch, epoch, roundList, roundGold, roundPoint }: any) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? 'column' : 'row'}
      justifyContent="space-between"
      gap="24px"
      mb="12px"
      mt="8px"
    >
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        gap={isVerySmallScreen ? '8px' : isSmallScreen ? '24px' : '40px'}
      >
        <RoundEpoch isOpen={isOpen} setIsOpen={setIsOpen} epoch={epoch} setEpoch={setEpoch} roundList={roundList} />
        {epoch > 0 && (
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'row' : 'column'}
            justifyContent={isSmallScreen ? 'space-between' : 'flex-start'}
            alignItems={isSmallScreen ? 'center' : 'flex-start'}
            gap="4px"
          >
            <ThemedText.TextSecondary fontSize={12} fontWeight={700} className="">
              <Trans>Time</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontSize={16} fontWeight={400} className="">
              {roundList?.length > 0 ? dayjs(roundList[epoch].startTime * 1000).format('YYYY/MM/DD') : '--'} -{' '}
              {roundList?.length > 0
                ? roundList[epoch].endTime
                  ? dayjs(roundList[epoch].endTime * 1000).format('YYYY/MM/DD')
                  : ' '
                : '--'}
            </ThemedText.TextPrimary>
          </Box>
        )}
        {epoch > 0 && (
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'row' : 'column'}
            justifyContent={isSmallScreen ? 'space-between' : 'flex-start'}
            alignItems={isSmallScreen ? 'center' : 'flex-start'}
            gap="4px"
          >
            <ThemedText.TextSecondary fontSize={12} fontWeight={700} className="">
              <Trans>Total Gold</Trans>
            </ThemedText.TextSecondary>
            <Box display="flex" justifyContent="flex-end" alignItems="center" gap="4px">
              <ThemedText.TextPrimary fontSize={16} fontWeight={400} className="">
                {formatAmount(roundGold, 2, true)}
              </ThemedText.TextPrimary>
              <GoldIcon width="12px" height="12px" />
            </Box>
          </Box>
        )}
        {epoch > 0 && (
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'row' : 'column'}
            justifyContent={isSmallScreen ? 'space-between' : 'flex-start'}
            alignItems={isSmallScreen ? 'center' : 'flex-start'}
            gap="4px"
          >
            <ThemedText.TextSecondary fontSize={12} fontWeight={700} className="">
              <Trans>Total Point</Trans>
            </ThemedText.TextSecondary>
            <Box display="flex" justifyContent="flex-end" alignItems="center" gap="4px">
              <ThemedText.TextPrimary fontSize={16} fontWeight={400} className="">
                {formatAmount(roundPoint, 0, true)}
              </ThemedText.TextPrimary>
              <BlastIcon width="12px" height="12px" />
            </Box>
          </Box>
        )}
      </Box>
      {/* <StyledActive>
        <ActiveIcon />
        <ThemedText.TextPrimary
          fontSize={14}
          fontWeight={700}
          // style={{ color: 'rgba(255, 255, 255, 0.87)' }}
          className=""
        >
          <Trans>Active</Trans>
        </ThemedText.TextPrimary>
      </StyledActive> */}
      {/* <StyledEnded>
        <EndIcon />
        <ThemedText.TextPrimary
          fontSize={14}
          fontWeight={700}
          // style={{ color: 'rgba(255, 255, 255, 0.87)' }}
          className=""
        >
          <Trans>End</Trans>
        </ThemedText.TextPrimary>
      </StyledEnded> */}
    </Box>
  )
}
