import { ThemedText } from 'theme'
import { countZeros } from 'utils/countZeros'

export function PositionMarkPrice({ currentSqrtRatioX96 }: { currentSqrtRatioX96?: string }) {
  // const [showHover_MarkPrice, setShowHover_MarkPrice] = useState(false)
  // const theme = useTheme()
  return (
    // <Tooltip
    //   width="300px"
    //   show={showHover_MarkPrice}
    //   text={
    //     <TooltipBox>
    //       <div className="tooltipItem">
    //         <ThemedText.TextSecondary fontSize={14}>
    //           Click on a row to select the position's market, then use the swap box to increase your position size if
    //           needed.
    //         </ThemedText.TextSecondary>
    //       </div>
    //       <div className="tooltipItem">
    //         <ThemedText.TextSecondary fontSize={14}>
    //           Use the "Close" button to reduce your position size, or to set stop-loss / take-profit orders.
    //         </ThemedText.TextSecondary>
    //       </div>
    //     </TooltipBox>
    //   }
    // >
    //   <TooltipRow onMouseEnter={() => setShowHover_MarkPrice(true)} onMouseLeave={() => setShowHover_MarkPrice(false)}>
    //     <ThemedText.TextPrimary
    //       style={{ borderBottom: ` 1px dashed ${theme.textSecondary}` }}
    //       fontWeight={700}
    //       fontSize={12}
    //     >
    //       {countZeros(currentSqrtRatioX96)}
    //     </ThemedText.TextPrimary>
    //   </TooltipRow>
    // </Tooltip>
    <ThemedText.TextPrimary fontWeight={700} fontSize={12}>
      {countZeros(currentSqrtRatioX96)}
    </ThemedText.TextPrimary>
  )
}
