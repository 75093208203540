import { Trans } from '@lingui/macro'
import { Box, Dialog, DialogContent } from '@mui/material'
import { ClaimButton } from 'components/Button'
import { StyledCloseIcon } from 'components/Icons/StyledIcon'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as AttentionIcon } from '../../assets/imgs/attention.svg'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '420px',
    maxWidth: '100%',
    padding: '16px',
  },
  '& .MuiDialog-paper': {
    border: '1px solid #CA8A0D',
    borderRadius: '24px',
    backgroundColor: theme.darkMode ? 'rgba(40, 40, 40, 0.5)' : '#ffffff',
  },
}))

// const StyledDialog = styled(StyledDialogBox)`
//   background: ${({ theme }) => theme.referralSpins};
// `

const AttentionBox = styled.div`
  padding: 16px;
  line-height: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.attention_bg};
`

export default function TradeTipsDialog({ isShow, handleDismiss, handleClose, count, setCount }: any) {
  const [isDisabled, setSetIsDisabled] = useState(true)
  useEffect(() => {
    const tick = setInterval(() => {
      count - 1 == 0 ? setSetIsDisabled(false) : setCount(count - 1)
    }, 1000)

    return () => clearInterval(tick)
  }, [count, setCount])

  return (
    <StyledDialog
      open={isShow}
      onClose={handleClose}
      aria-labelledby="Attention"
      style={{ background: 'rgba(40, 40, 40, 0.5)' }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" p="16px 16px 0 16px">
        <Box display="flex" alignItems="center">
          <AttentionIcon />
          <ThemedText.TextPrimary fontSize={14} fontWeight={700} color="removeBackground" ml="8px">
            <Trans>Attention</Trans>
          </ThemedText.TextPrimary>
        </Box>
        <StyledCloseIcon onClick={() => handleClose()} />
      </Box>
      <DialogContent>
        <AttentionBox>
          <ThemedText.TextPrimary fontSize={12}>
            <Trans>
              Please be aware that RogueX is distinct from typical perpetual trading platforms such as GMX. The RogueX
              team does not control the metrics or settings for trading pairs. We encourage you to thoroughly examine
              the pairs you plan to trade, ensuring they are in line with your trading strategy. Before you execute your
              trade, it is important to be mindful of the applicable fees and spreads.
              <br />
              <br />
              Unlike GMX, which relies on external price oracles, RogueX utilizes a price spread mechanism to mitigate
              the risk of price manipulation. The spread is influenced by a variety of factors, such as the liquidity of
              the trading pair, asset utilization, and the recent trends in open interest, among other considerations.
              Being fully informed of these variables is essential for trading effectively on the RogueX platform.
            </Trans>
          </ThemedText.TextPrimary>
        </AttentionBox>
        <Box mt="16px">
          {isDisabled ? (
            <ClaimButton width="100%" height="40px" disabled={isDisabled}>
              {count} s
            </ClaimButton>
          ) : (
            <ClaimButton width="100%" height="40px" disabled={isDisabled} onClick={() => handleDismiss()}>
              I got it
            </ClaimButton>
          )}
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}
