import { Box } from '@mui/material'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { ReactComponent as ChartIcon } from 'assets/imgs/chart.svg'
import { ReactComponent as ExitIcon } from 'assets/imgs/exit.svg'
import { ReactComponent as NftIcon } from 'assets/imgs/nft.svg'
import { StyledCopyIcon } from 'components/Icons/StyledIcon'
import { ToastSuccess } from 'components/Toast'
import { useActiveChainId } from 'connection/useActiveChainId'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { useCallback } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ExternalLink, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { useAccount, useDisconnect } from 'wagmi'

const StyledInfo = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 20px 16px;
  gap: 16px;
  border-radius: 32px;
  background: ${({ theme }) => theme.rewardBtnBackground};
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`

const StyledIconBox = styled(Box)`
  height: 36px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.iconBorder};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.hoverBg};
  }
`

const StyledAccount = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 10px;
  gap: 12px;
  border-radius: 32px;
  background: rgba(0, 0, 0, 0.16);
`

export function AccountInfo() {
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()

  const { chainId } = useActiveChainId()

  const [, setCopied] = useCopyClipboard()
  const copy = useCallback(() => {
    setCopied(address ?? '')
    ToastSuccess('Copied to clipboard')
  }, [address, setCopied])

  return (
    <>
      {isConnected && (
        <StyledInfo>
          <StyledAccount>
            {/* <ConnectButton accountStatus="avatar" showBalance={false} /> */}
            <NftIcon />
            <ConnectButton.Custom>
              {({ account }) => {
                return (
                  <div>
                    <ThemedText.BodySmall fontSize={24} fontWeight={700} color="textAddButton">
                      {shortenAddress(account?.address)}
                    </ThemedText.BodySmall>
                  </div>
                )
              }}
            </ConnectButton.Custom>
            <div style={{ cursor: 'pointer', marginLeft: '4px' }} onClick={copy}>
              <StyledCopyIcon />
            </div>
          </StyledAccount>
          <Box display="flex" alignItems="center" gap="16px">
            <ExternalLink href={getExplorerLink(chainId, address ?? '', ExplorerDataType.ADDRESS)}>
              <StyledIconBox>
                <ChartIcon />
              </StyledIconBox>
            </ExternalLink>
            <StyledIconBox onClick={() => disconnect()}>
              <ExitIcon />
            </StyledIconBox>
          </Box>
        </StyledInfo>
      )}
    </>
  )
}
