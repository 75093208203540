import '@reach/dialog/styles.css'
import 'inter-ui'
import 'polyfills'
import 'tracing'
import 'animate.css'

import { ApolloProvider } from '@apollo/client'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { useInitrtarList } from 'components/SearchModal2/state'
import { chain, wagmiConfig } from 'connection/wagmiConfig'
import { FeatureFlagsProvider } from 'featureFlags'
import { apolloClient } from 'graphql/thegraph/apollo'
import { BlockNumberProvider } from 'lib/hooks/useBlockNumber'
import { MulticallUpdater } from 'lib/state/multicall'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import AppUpdater from 'state/application/updater'
import HttpUpdater from 'state/http/updater'
import RadialGradientByChainUpdater from 'theme/components/RadialGradientByChainUpdater'
import { SystemThemeUpdater } from 'theme/components/ThemeToggle'
import { WagmiConfig } from 'wagmi'

import { LanguageProvider } from './i18n'
import App from './pages/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './state'
import ListsUpdater from './state/lists/updater'
import TransactionUpdater from './state/transactions/updater'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  useInitrtarList()
  return (
    <>
      <MulticallUpdater />
      <AppUpdater />
      <ListsUpdater />
      <RadialGradientByChainUpdater />
      <SystemThemeUpdater />
      {/* <ApplicationUpdater /> */}
      <TransactionUpdater />
      <HttpUpdater />
      {/* <LogsUpdater /> */}
    </>
  )
}

const queryClient = new QueryClient()

const container = document.getElementById('root') as HTMLElement
// console.log = function () {};
createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <FeatureFlagsProvider>
          <HashRouter>
            <ApolloProvider client={apolloClient}>
              <WagmiConfig config={wagmiConfig}>
                <RainbowKitProvider modalSize="compact" chains={chain}>
                  <BlockNumberProvider>
                    <LanguageProvider>
                      <ThemeProvider>
                        <ThemedGlobalStyle />
                        <Updaters />

                        <App />
                      </ThemeProvider>
                    </LanguageProvider>
                  </BlockNumberProvider>
                </RainbowKitProvider>
              </WagmiConfig>
            </ApolloProvider>
          </HashRouter>
        </FeatureFlagsProvider>
      </QueryClientProvider>
    </Provider>
  </StrictMode>
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}
