import Row, { RowBetween } from 'components/Row'
import Tooltip from 'components/Tooltip'
import { BigNumber } from 'ethers/lib'
import { TooltipBox, TooltipRow } from 'pages/Liquidity/LiquidityRow'
import { useState } from 'react'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formFeeWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

export function FeesDetails({
  fees,
  token,
  ExecutionFees,
  positionFee,
  allFees,
}: {
  fees?: string
  ExecutionFees?: string
  token?: string
  positionFee?: BigNumber
  allFees?: string
}) {
  const [showHover_Collateral, setShowHover_Collateral] = useState(false)

  const theme = useTheme()
  return (
    <Tooltip
      width="310px"
      show={showHover_Collateral}
      text={
        <TooltipBox>
          <RowBetween mt="8px" className="tooltipItem">
            <ThemedText.TextSecondary fontSize={14}>PositionFee</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={700}>
                {countZeros(fees)}&nbsp;{token}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12}>
                (
                {formFeeWei(positionFee || BigNumber.from(0))
                  .times(100)
                  .toFixed(2)}
                %)
              </ThemedText.TextSecondary>
            </Row>
          </RowBetween>
          <RowBetween alignItems="flex-start" className="tooltipItem">
            <ThemedText.TextSecondary fontSize={14}>Execution Fees</ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700}>
              {countZeros(ExecutionFees)}&nbsp;{token}
            </ThemedText.TextPrimary>
          </RowBetween>

          {/* <div className="tooltipItem">
            <ThemedText.TextSecondary fontSize={14}>
              Use the "Edit" button to deposit or withdraw collateral.
            </ThemedText.TextSecondary>
          </div> */}
          {/* <RowBetween alignItems="flex-start" className="tooltipItem">
            <ThemedText.TextPrimary fontWeight={700}>
              <Trans>Borrow Fee / Day</Trans>
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={14}>0 {token}</ThemedText.TextSecondary>
          </RowBetween> */}
        </TooltipBox>
      }
    >
      <TooltipRow
        onMouseEnter={() => setShowHover_Collateral(true)}
        onMouseLeave={() => setShowHover_Collateral(false)}
      >
        <ThemedText.TextPrimary
          style={{ borderBottom: ` 1px dashed ${theme.textSecondary}` }}
          fontWeight={700}
          fontSize={14}
        >
          {countZeros(allFees)}&nbsp;{token}
        </ThemedText.TextPrimary>
      </TooltipRow>
    </Tooltip>
  )
}
