import BigNumber from 'bignumber.js'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useEthPriceUSD, useGraphTokenListMAP } from 'hooks/useQueryLiquidity'
import { useLiquidityData } from 'pages/Liquidity/hooks'
import { useGetLockTokenIds } from 'pages/Lock/hooks'
import { useMemo } from 'react'
import { useVoteCurrentId } from 'state/http/hooks'
import { BN, fromWei } from 'utils/bn'
import { DURATION } from 'utils/CurrRound'
import { useContractReads } from 'wagmi'

const liquidityabi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_account',
        type: 'address',
      },
    ],
    name: 'earned',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

const voteAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_account',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'round',
        type: 'uint256',
      },
    ],
    name: 'earned',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]
// eslint-disable-next-line import/no-unused-modules
export const useLiquidityTotalUsd = () => {
  const liquidityList = useLiquidityData()
  const { account } = useActiveChainId()
  const tokenMap = useGraphTokenListMAP()
  const ethPrice = useEthPriceUSD()
  const contracts = useMemo(() => {
    if (!liquidityList || !tokenMap) return
    const addr: any = []
    const price: any = []
    liquidityList.forEach((x) => {
      x.LiquidityrewardsLists?.forEach((xx) => {
        price.push(xx.tokenInfo)

        addr.push({
          address: x.masterChefAddress,
          abi: liquidityabi,
          functionName: 'earned',
          args: [xx.tokenInfo.id, account],
        })
      })
    })

    return { contracts: addr, prices: price }
  }, [account, liquidityList, tokenMap])
  const { data, isLoading, isError } = useContractReads({
    contracts: contracts?.contracts,
  })
  return useMemo(() => {
    if (!data || !contracts || isLoading || !ethPrice) return
    return data.reduce((pre: BigNumber, curr, index) => {
      if (curr.result == undefined) return pre
      const price = contracts.prices[index]
      const num = curr.result.toString()
      const current = fromWei(num, price.decimals)
        .times(price.derivedETH || 0)
        .times(ethPrice)
      if (current.isNaN()) {
        return pre
      }
      return pre.plus(current)
    }, BN(0))
  }, [contracts, data, ethPrice, isLoading])
}
//TODO: earnedByTokenIds
export const useVoteTotalUsd = () => {
  const liquidityList = useLiquidityData()
  const { account } = useActiveChainId()
  const tokenMap = useGraphTokenListMAP()
  const ethPrice = useEthPriceUSD()

  const { tokenIds } = useGetLockTokenIds()

  const selectEpoch = useVoteCurrentId()
  const selcetTimeStamp = selectEpoch * DURATION

  const contracts = useMemo(() => {
    if (!liquidityList || !tokenMap || !tokenIds) return
    const addr: any = []
    const price: any = []
    liquidityList.forEach((x) => {
      x.rewardsList?.forEach((xx) => {
        price.push(xx.tokenInfo)

        addr.push({
          address: x.votingRewardAddress,
          abi: voteAbi,
          functionName: 'earned',
          args: [xx.tokenInfo.id, account, selcetTimeStamp],
        })
      })
    })

    return { contracts: addr, prices: price }
  }, [account, liquidityList, selcetTimeStamp, tokenIds, tokenMap])
  const { data, isLoading } = useContractReads({
    contracts: contracts?.contracts,
  })
  return useMemo(() => {
    if (!data || !contracts || isLoading || !ethPrice) return
    return data.reduce((pre: BigNumber, curr, index) => {
      if (curr.result == undefined) return pre
      const price = contracts.prices[index]
      const num = curr.result.toString()
      const current = fromWei(num, price.decimals)
        .times(price.derivedETH || 0)
        .times(ethPrice)
      if (current.isNaN()) {
        return pre
      }
      return pre.plus(current)
    }, BN(0))
  }, [contracts, data, ethPrice, isLoading])
}
