import { useALLToken } from 'hooks/Tokens'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'

export const useListTokens = (brigeContract?: any) => {
  const { result } = useSingleCallResult(brigeContract, 'getRewardList')

  const useALLTokens = useALLToken(useMemo(() => result?.[0], [result]))

  return useMemo(() => useALLTokens, [useALLTokens])
}
