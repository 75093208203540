import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import Column from 'components/Column'
import { useTotalDepositedUSDAmount, useTotalFeesUSD } from 'hooks/useQueryLiquidity'
import { useFetchVoter } from 'pages/Vote/hooks'
import { VoteDataProps } from 'pages/Vote/types'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { formatAmount } from 'utils/formatAmout'

import SearchBar from './SearchBar'

const LiquidityContentBox = styled.div`
  margin-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.primaryBorder};
  .liquidityInfo {
    padding-top: 24px;
    padding-bottom: 50px;
  }
  .searchBox {
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    margin-top: 16px;
    .liquidityInfo {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .searchBox {
      padding-bottom: 16px;
    }
  }
`

const LiquidityInfoBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: column;
    align-items: center;
  }
  .infoVal {
    word-break: break-all;
    text-align: center;
  }
  .flex-1 {
    flex: 1;
  }
`

export default function ContentWrapper() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const tvl = useTotalDepositedUSDAmount()
  const fees = useTotalFeesUSD()

  const leaderboardList = useFetchVoter()

  const totalVolumeUSD = useMemo(() => {
    if (leaderboardList?.voteList?.length == 0) return 0
    let totalVolume = 0
    leaderboardList?.voteList?.map((leaderboard: VoteDataProps) => {
      const ETHAmount =
        (Number(leaderboard.token0?.derivedETH) * Number(leaderboard.totalVolume)) /
        Math.pow(10, Number(leaderboard.token0.decimals))
      totalVolume += ETHAmount * Number(leaderboard?.ethPrice)
    })
    return totalVolume
  }, [leaderboardList.voteList])
  return (
    <LiquidityContentBox>
      <LiquidityInfoBox className="liquidityInfo">
        <Column align="center" className="flex-1">
          <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
            <Trans>ALM TVL</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700} mt="4px" className="infoVal">
            ${formatAmount(tvl, 2, true) || '$0'}
          </ThemedText.TextPrimary>
        </Column>
        <Column align="center" className="flex-1">
          <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
            <Trans>Weekly Volume</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700} mt="4px" className="infoVal">
            ${formatAmount(totalVolumeUSD, 2, true) || '$0'}
          </ThemedText.TextPrimary>
        </Column>
        <Column align="center" className="flex-1">
          <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
            <Trans>Weekly Fees</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700} mt="4px" className="infoVal">
            ${formatAmount(fees, 2, true) || '$0'}
          </ThemedText.TextPrimary>
        </Column>
      </LiquidityInfoBox>
      <div className="searchBox">
        <SearchBar />
      </div>
    </LiquidityContentBox>
  )
}
