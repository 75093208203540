import { t, Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { Token } from '@uniswap/sdk-core'
import Column from 'components/Column'
import { SearchInput } from 'components/SearchModal/styleds'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useDefaultActiveTokens } from 'hooks/Tokens'
import useDebounce from 'hooks/useDebounce'
import { useGraphTokenList } from 'hooks/useQueryLiquidity'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import PoolList from 'pages/Pool/PoolList'
import { ChangeEvent, RefObject, useCallback, useMemo, useRef, useState } from 'react'
import { useHttpEthPrice } from 'state/http/hooks'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { compareAddress, isAddress } from 'utils'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'

import Tab from '../Tab'
import TokenTable from './Tokens/Table'
import TokenTableM from './Tokens/Table-m'
import { LiquidityTable } from 'pages/Liquidity/LiquidityTable'

const AssetBox = styled(Box)`
  margin-top: 24px;
`

export default function Assets() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isSmallScreen2 = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)
  const [curr, setCurr] = useState<any>(0)
  const tabList = ['Token', 'Pool', 'Staked']

  const { chainId } = useActiveChainId()
  const native = useNativeCurrency(chainId)
  const defaultTokens = useDefaultActiveTokens(chainId)
  const filteredTokensArr: Token[] = useMemo(() => {
    return Object.values(defaultTokens)
  }, [defaultTokens])
  const natives = [native]
  const assetList = [...natives, ...filteredTokensArr]
  const filteredAssetList = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()
    const searchCurrencies = assetList.filter(
      (n) => n.symbol?.toLowerCase()?.indexOf(s) !== -1 || n.name?.toLowerCase()?.indexOf(s) !== -1
    )
    return searchCurrencies
    // return assetList.filter(getTokenFilter(debouncedQuery))
  }, [assetList, debouncedQuery])

  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
  }, [])

  const tokenList: any = useGraphTokenList()

  const { account } = useActiveChainId()
  const ethPrice = useHttpEthPrice()

  const balancesList = useCurrencyBalances(account ?? undefined, assetList)

  const totalValue = useMemo(() => {
    const sum = filteredAssetList.reduce((pre: any, curr: any, i: number) => {
      const currTokenArr = tokenList?.filter((token: any) => compareAddress(token.id, curr?.address))
      const currToken = currTokenArr?.length > 0 ? currTokenArr[0] : {}
      pre = pre.plus(
        BN(ethPrice || 0)?.times(
          BN(currToken.derivedETH ? currToken.derivedETH : 1)?.times(BN(balancesList[i]?.toSignificant() || 0))
        )
      )
      return pre
    }, BN(0))
    return sum
  }, [filteredAssetList, balancesList, ethPrice, tokenList])

  return (
    <AssetBox>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Column align="flex-start" gap="sm">
          <ThemedText.TextSecondary fontSize={14} fontWeight={300}>
            <Trans>Total Value</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={24} fontWeight={800}>
            ${formatAmount(totalValue, 4, true)}
          </ThemedText.TextPrimary>
        </Column>
        <Box
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          alignItems="center"
          gap={isSmallScreen ? '16px' : '32px'}
          width={isSmallScreen ? ' 100%' : 'auto'}
        >
          <SearchInput
            className="flex-1"
            width={isSmallScreen ? ' 100%' : 'min(300px, 100%)'}
            data-cy="assets-search-input"
            type="search"
            placeholder={t`Search`}
            id="search-vote"
            autoComplete="off"
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
          />
          <Tab tabList={tabList} curr={curr} setCurr={setCurr} />
        </Box>
      </Box>
      {curr == 0 && (
        <Box mt="24px">
          {isSmallScreen2 ? (
            <TokenTableM assetList={filteredAssetList} />
          ) : (
            <TokenTable assetList={filteredAssetList} />
          )}
        </Box>
      )}
      {curr == 1 && (
        <Box mt="24px">
          <PoolList />
          {/* {isSmallScreen2 ? <PoolTableM assetList={assetList} /> : <PoolTable assetList={assetList} />} */}
        </Box>
      )}
      {curr == 2 && (<Box mt="24px">
        <LiquidityTable />
        {/* {isSmallScreen2 ? <PoolTableM assetList={assetList} /> : <PoolTable assetList={assetList} />} */}
      </Box>)}
    </AssetBox>
  )
}
