import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Percent } from '@uniswap/sdk-core'
import { ClaimButton } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { StyledTableRow } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useToken } from 'hooks/Tokens'
import { TextLeftCell, TextRightCell } from 'pages/Lock/LockRow'
import { useFetchVoter } from 'pages/Vote/hooks'
import { TotalRewardsItem, TotalVoteEarnsItem } from 'pages/Vote/VoteRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount, formatAmount2 } from 'utils/formatAmout'
import { unwrappedToken } from 'utils/unwrappedToken'

import { TokenSortMethod } from './state'

const StyledRewardRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  grid-template-columns: 200px 1fr 1fr 1fr 1fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
`
const StyledHeaderRow = styled(StyledRewardRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 0;

  &:hover {
    background-color: transparent;
  }
`

/* Token Row: skeleton row component */
function RewardRow({
  header,
  pools,
  totalRewards,
  totalVotes,
  my_vote,
  earn,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  pools: ReactNode
  totalRewards: ReactNode
  totalVotes: ReactNode
  my_vote: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="pools-cell">{pools}</TextLeftCell>
      <TextRightCell data-testid="rewards-cell">{totalRewards}</TextRightCell>
      <TextRightCell data-testid="rewards-cell">{totalVotes}</TextRightCell>
      <TextRightCell data-testid="my-vote-cell">{my_vote}</TextRightCell>
      <TextRightCell data-testid="earn-cell">{earn}</TextRightCell>
    </>
  )
  const rowTrCells = (
    <>
      <TextLeftCell data-testid="pools-cell">
        <Box p="16px 0" width="100%" height="100%">
          {pools}
        </Box>
      </TextLeftCell>
      <TextRightCell data-testid="rewards-cell">
        <Box p="16px 0" width="100%" height="100%">
          {totalRewards}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="votes-cell">
        <Box p="16px 0" width="100%" height="100%">
          {totalVotes}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="my-vote-cell">
        <Box display="flex" justifyContent="flex-end" alignItems="flex-start" width="100%" height="100%" p="16px 0">
          {my_vote}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="earn-cell">
        <Box display="flex" justifyContent="flex-end" alignItems="flex-start" width="100%" height="100%" p="16px 0">
          {earn}
        </Box>
      </TextRightCell>
    </>
  )

  if (header) return <StyledHeaderRow data-testid="reward-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledRewardRow {...rest}>{rowTrCells}</StyledRewardRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <RewardRow
      header={true}
      pools={<Trans>{TokenSortMethod.POOLS}</Trans>}
      totalRewards={<Trans>{TokenSortMethod.TOTAL_REWARDS}</Trans>}
      totalVotes={<Trans>{TokenSortMethod.TOTAL_VOTES}</Trans>}
      my_vote={<Trans>{TokenSortMethod.MY_VOTE}</Trans>}
      earn={<Trans>{TokenSortMethod.EARN}</Trans>}
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <RewardRow
      header={false}
      $loading
      key={props?.first + '' + props?.last}
      pools={
        <Row gap="xmd">
          <IconLoadingBubble width="48px" height="48px" />
          <Column gap="xs" align="flex-start" className="flex-1" width="100%">
            <MediumLoadingBubble />
            <MediumLoadingBubble />
          </Column>
        </Row>
      }
      totalRewards={
        <Column width="100%" align="flex-end" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      totalVotes={
        <Column width="100%" align="flex-end" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      my_vote={
        <Column width="100%" align="flex-end" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      earn={
        <Column width="100%" align="flex-end" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <Row mt="4px" justify="end">
            <ClaimButton>Claim</ClaimButton>
          </Row>
        </Column>
      }
    />
  )
}

export interface LoadedVoteRowProps {
  rewardListIndex: number
  rewardListLength: number
  reward: any
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedVoteRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { reward, rewardListIndex, rewardListLength } = props

  const { voteList } = useFetchVoter()
  const arr = voteList?.filter((vote) => vote.lpAddr == reward.votedPool.lpAddr)
  const rewardsList = arr && arr.length > 0 ? arr[0].rewardsList : []
  const votingRewardAddress = arr && arr.length > 0 ? arr[0].votingRewardAddress : ''
  const votetotalRewardsUSD = arr && arr.length > 0 ? arr[0].votetotalRewardsUSD : ''
  const weeklyVotedTotal = arr && arr.length > 0 ? arr[0].weeklyVotedTotal : ''
  const poolWeightRatio = arr && arr.length > 0 ? arr[0].poolWeightRatio : BN(0)
  const epochVote = arr && arr.length > 0 ? arr[0].epochVote : 0
  const epochVoteTotal = arr && arr.length > 0 ? arr[0].epochVoteTotal : 0

  const token0 = useToken(reward.votedPool.token0.id)
  const token1 = useToken(reward.votedPool.token1.id)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  return (
    <>
      <div ref={ref} data-testid={`reward-table-row-${reward?.tokenId}`}>
        <RewardRow
          header={false}
          pools={
            <Row gap="xmd">
              <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={40} margin />
              <Column gap="xs" align="flex-start">
                <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                  {reward.votedPool.token0.symbol}-{reward.votedPool.token1.symbol}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={14}>
                  {new Percent(reward.votedPool.poolFee, 1_000_000).toSignificant()}%
                </ThemedText.TextSecondary>
              </Column>
            </Row>
          }
          totalRewards={
            <Column width="100%" align="flex-end" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(votetotalRewardsUSD, 2, true)}
              </ThemedText.TextPrimary>
              {rewardsList && rewardsList.length > 0 ? (
                rewardsList?.map((item, index: number) => {
                  return <TotalRewardsItem key={'totalRewardItem' + index} rewardAddr={item} />
                })
              ) : (
                <ThemedText.TextSecondary fontSize={14}>--</ThemedText.TextSecondary>
              )}
            </Column>
          }
          totalVotes={
            <Column width="100%" align="flex-end" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(Number(weeklyVotedTotal) / Math.pow(10, 18), 2, true)} veROX
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>{poolWeightRatio?.toFixed(2, 1)}%</ThemedText.TextSecondary>
            </Column>
          }
          my_vote={
            <Column width="100%" align="flex-end" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount2(reward.weight, 18, 2, true)} veROX
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {Number(epochVoteTotal) == 0 || Number(epochVote) == 0
                  ? 0
                  : ((Number(epochVote) / Number(epochVoteTotal)) * 100).toFixed(2)}
                %
              </ThemedText.TextSecondary>
            </Column>
          }
          earn={
            <Column width="100%" align="flex-end" gap="xs">
              {rewardsList && rewardsList.length > 0 ? (
                rewardsList?.map((item, index: number) => {
                  return <TotalVoteEarnsItem key={'earn' + index} rewardAddr={item} poolAddr={votingRewardAddress} />
                })
              ) : (
                <ThemedText.TextRewards>--</ThemedText.TextRewards>
              )}
              <Row mt="4px" justify="end">
                <ClaimButton>Claim</ClaimButton>
              </Row>
            </Column>
          }
          first={rewardListIndex === 0}
          last={rewardListIndex === rewardListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
