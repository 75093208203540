export enum TokenSortMethod {
  Position = 'Position',
  Size = 'Size',
  NetValue = 'Net Value',
  Collateral = 'Collateral',
  MarkPrice = 'Mark Price',
  EntryPrice = 'Entry Price',
  LiqPrice = 'Liq.Price',
  Operation = 'Operation',
}
