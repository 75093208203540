import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ReactComponent as BlastIcon } from 'assets/imgs/airdrop/ranking/blast.svg'
import { ReactComponent as GoldIcon } from 'assets/imgs/airdrop/ranking/gold.svg'
import { ReactComponent as PointIcon } from 'assets/imgs/airdrop/ranking/point.svg'
import { ReactComponent as UserIcon } from 'assets/imgs/airdrop/ranking/user.svg'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'

import { GoldProgressBar, PointProgressBar } from '../ProgressBar'

const StyledBaseInfo = styled(Box)`
  display: flex;
  gap: 40px;
  padding: 20px 18px 24px 32px;
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-radius: 16px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 16px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    flex-direction: column;
    padding: 8px;
    gap: 24px;
  }
`

export default function InfoBox({ goldPoint, totalUser, totalPoint }: any) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? 'column' : 'row'}
      gap={isVerySmallScreen ? '16px' : isSmallScreen ? '24px' : '40px'}
      width={isVerySmallScreen ? '100%' : 'auto'}
    >
      <Box display="flex" alignItems="center" gap={isVerySmallScreen ? '16px' : isSmallScreen ? '24px' : '40px'}>
        <Box display="flex" flexDirection="column" gap="4px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700} className="" ml="32px">
            <Trans>Total User</Trans>
          </ThemedText.TextSecondary>
          <Box display="flex" alignItems="center" gap="8px">
            <UserIcon />
            <ThemedText.TextPrimary fontSize={18} fontWeight={800} className="">
              {formatAmount(totalUser, 0, true)}
            </ThemedText.TextPrimary>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="4px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700} className="" ml="32px">
            <Trans>RogueX Point</Trans>
          </ThemedText.TextSecondary>
          <Box display="flex" alignItems="center" gap="8px">
            <PointIcon />
            <ThemedText.TextPrimary fontSize={18} fontWeight={800} className="">
              {formatAmount(totalPoint, 0, true)}
            </ThemedText.TextPrimary>
          </Box>
        </Box>
      </Box>
      <StyledBaseInfo>
        <Box display="flex" flexDirection="column" gap="4px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700} className="" ml="32px">
            <Trans>Blast Gold</Trans>
          </ThemedText.TextSecondary>
          <Box display="flex" gap="8px">
            <GoldIcon />
            <Box display="flex" alignItems="center">
              <ThemedText.TextPrimary fontSize={18} fontWeight={800} className="">
                {formatAmount(BN(goldPoint.allGold).minus(BN(goldPoint.availableGold)), 0, true)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={18} fontWeight={800} className="">
                / {formatAmount(goldPoint.allGold, 0, true)}
              </ThemedText.TextSecondary>
            </Box>
          </Box>
          <Box ml="32px">
            <GoldProgressBar
              value={BN(goldPoint.allGold)
                .minus(BN(goldPoint.availableGold))
                .div(BN(goldPoint.allGold))
                .multipliedBy(BN(100))}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="4px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700} className="" ml="32px">
            <Trans>Blast Point</Trans>
          </ThemedText.TextSecondary>
          <Box display="flex" gap="4px">
            <BlastIcon />
            <Box display="flex" alignItems="center">
              <ThemedText.TextPrimary fontSize={18} fontWeight={800} className="">
                {formatAmount(BN(goldPoint.allPoint).minus(BN(goldPoint.availablePoint)), 0, true)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={18} fontWeight={800} className="">
                / {formatAmount(goldPoint.allPoint, 0, true)}
              </ThemedText.TextSecondary>
            </Box>
          </Box>
          <Box ml="32px">
            <PointProgressBar
              value={BN(goldPoint.allPoint)
                .minus(BN(goldPoint.availablePoint))
                .div(BN(goldPoint.allPoint))
                .multipliedBy(BN(100))}
            />
          </Box>
        </Box>
      </StyledBaseInfo>
    </Box>
  )
}
