import { createReducer } from '@reduxjs/toolkit'
import { getWeeklyId } from 'utils/CurrRound'

import {
  setEthPrice,
  setLeaderboardCurrentId,
  setLeaderboardinnerCurrentId,
  setQueryList,
  setRoxPrice,
  setVoteCurrentId,
} from './actions'
import { HttpState } from './types'

const CurrentId = getWeeklyId()

const initialState: HttpState = {
  poolList: undefined,
  ethPrice: undefined,
  roxPrice: undefined,
  VoteCurrentId: CurrentId,
  LeaderboardId: CurrentId,
  LeaderboardInnerId: CurrentId,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setQueryList, (state, { payload: { list } }) => {
      if (!list) return
      state.poolList = list
    })
    .addCase(setEthPrice, (state, { payload: { price } }) => {
      if (!price) return
      state.ethPrice = price
    })
    .addCase(setRoxPrice, (state, { payload: { price } }) => {
      if (!price) return
      state.roxPrice = price
    })
    .addCase(setVoteCurrentId, (state, { payload: { id } }) => {
      state.VoteCurrentId = id
    })
    .addCase(setLeaderboardCurrentId, (state, { payload: { id } }) => {
      state.LeaderboardId = id
    })
    .addCase(setLeaderboardinnerCurrentId, (state, { payload: { id } }) => {
      state.LeaderboardInnerId = id
    })
)
