import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ReactComponent as SelectedIcon } from 'assets/imgs/selected.svg'
import BigNumber from 'bignumber.js'
import { ButtonGray } from 'components/Button'
import Column from 'components/Column'
import { StyledDropDown } from 'components/Icons/StyledIcon'
import { NavDropdown } from 'components/NavBar/NavDropdown'
import Row, { RowBetween } from 'components/Row'
import { scrollbarStyle } from 'components/SearchModal/CurrencyList/index.css'
import { DEFAULT_VOTE_TOTAL } from 'constants/misc'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useLockStakeList } from 'pages/Lock/hooks'
import { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { useVotedData } from './hooks'
import SearchBar from './SearchBar'
import Table from './Table'
import TableM from './Table-m'
import { VoteDataProps } from './types'
import VotedWrapper from './VotedWrapper'

export const VeRoxSelect = styled(ButtonGray)<{
  disabled?: boolean
}>`
  align-items: center;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.sectionBorder};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  color: ${({ theme }) => theme.textPrimary};
  cursor: pointer;
  width: auto;
  min-width: 200px;
  height: 36px;
  min-height: 36px;
  padding: 8px 16px;
  border-radius: 8px;
  outline: none;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
  justify-content: space-between;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.backgroundInteractive};
  }

  &:before {
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover:before {
    background-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:active:before {
    background-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`

const VoteVeRoxSelect = styled(VeRoxSelect)<{
  disabled?: boolean
}>`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    width: 100%;
  }
`

const VotedInfo = styled(Row)`
  width: auto;
  margin-left: 8px;
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  border-radius: 8px;
  gap: 8px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    margin-left: 0;
    width: 100%;
  }
`

export const VotedList = styled(Column)`
  padding: 0 16px;
  min-width: 378px;
  height: 300px;
  overflow: auto;
  .voteItem {
    width: 100%;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
    }
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    min-width: 300px;
  }
`
export const VotedItem = styled(RowBetween)`
  padding-top: 16px;
  padding-bottom: 12px;
  gap: 16px;
`
const StyledInfo = styled(Row)`
  /* border-bottom: 1px solid ${({ theme }) => theme.primaryBorder}; */
`

export default function HeaderWrapper() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const [nftId, setNFTId] = useState<string | undefined>()
  const [isOpen, setIsOpen] = useState(false)
  const handleChange = (id: string) => {
    setNFTId(id)
  }
  const { tokenIdDetails: lockList } = useLockStakeList()

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const voteNode = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(voteNode, isOpen ? toggleOpen : undefined)

  const voteDtial = useVotedData(nftId)

  const [inputValue, setInputValue] = useState<{
    [key: string]: {
      value: string
      data: VoteDataProps
      bn: BigNumber
    }
  }>({})
  const inputValueArr = Object.values(inputValue).filter((x) => {
    return Number(x.value) > 0
  })

  const handelChange = (value: any, data: VoteDataProps) => {
    let newBn = new BigNumber(isNaN(Number(value)) ? 0 : Number(value))
    let newValue = Number(value)
    const key = data.lpAddr
    const count = Object.keys(inputValue).reduce<number>((val, k) => {
      if (k !== key) {
        return val + Number(inputValue[k]?.value)
      }
      return val
    }, 0)
    if (count + newValue > DEFAULT_VOTE_TOTAL) {
      newValue = BN(DEFAULT_VOTE_TOTAL).minus(count).toNumber()
    }

    newBn = newBn.lt(0) ? new BigNumber(0) : newBn
    newBn = newBn.gt(DEFAULT_VOTE_TOTAL) ? new BigNumber(DEFAULT_VOTE_TOTAL) : newBn

    if (!isNaN(newValue)) {
      newValue = newValue < 0 ? 0 : newValue
      newValue = newValue > DEFAULT_VOTE_TOTAL ? DEFAULT_VOTE_TOTAL : newValue
      newValue = Number(parseInt(String(newValue)))
    }

    setInputValue((prev: any) => ({
      ...prev,
      [key]: {
        value: isNaN(newValue) ? value : newValue.toString(),
        bn: newBn,
        data,
      },
    }))
  }

  return (
    <Box>
      <StyledInfo>
        <Box
          width="100%"
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="center"
          m="24px 0"
          gap="8px"
        >
          <SearchBar />
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'center' : 'flex-end'}
            gap="8px"
            width={isSmallScreen ? '100%' : 'auto'}
            ref={voteNode}
          >
            <div style={{ position: 'relative', width: isSmallScreen ? '100%' : 'auto' }}>
              <VoteVeRoxSelect
                onClick={() => {
                  if (lockList && lockList?.length > 0) toggleOpen()
                }}
              >
                <ThemedText.TextSecondary fontSize={14}>
                  <Trans>{nftId ? nftId : 'Select Your veROX'}</Trans>
                </ThemedText.TextSecondary>
                <StyledDropDown />
              </VoteVeRoxSelect>
              {isOpen && (
                <NavDropdown right="0">
                  <VotedList className={scrollbarStyle}>
                    {lockList?.map((item) => (
                      <div
                        className="voteItem"
                        key={item.tokenId}
                        onClick={() => {
                          handleChange(item.tokenId), toggleOpen()
                        }}
                      >
                        <VotedItem>
                          <Column gap="sm" align="flex-start">
                            <Box display="flex" gap="4px">
                              <ThemedText.TextPrimary fontSize={16}>
                                <Trans>NFT ID:</Trans>
                              </ThemedText.TextPrimary>
                              <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                                <Trans>#{item.tokenId}</Trans>
                              </ThemedText.TextPrimary>
                              {item.isVoted == true && (
                                <Box
                                  marginLeft="20px"
                                  padding="2px 8px"
                                  style={{
                                    borderRadius: '2px',
                                    background: 'var(--light-alert, #CA8A0D)',
                                  }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <ThemedText.TextSecondary fontSize={12}>
                                    <Trans>Voted</Trans>
                                  </ThemedText.TextSecondary>
                                </Box>
                              )}
                            </Box>
                            <Box display="flex" gap="16px">
                              <ThemedText.TextSecondary fontSize={12}>
                                <Trans>Voting Power:</Trans> {formatCurrencyAmount(item.voteAmount, 4)}{' '}
                                {item.voteAmount?.currency.symbol}
                              </ThemedText.TextSecondary>
                            </Box>
                          </Column>
                          {nftId == item.tokenId && <SelectedIcon />}
                        </VotedItem>
                      </div>
                    ))}
                  </VotedList>
                </NavDropdown>
              )}
            </div>
            <VotedInfo>
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Voted:</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {voteDtial?.isVoted ? formatAmount(voteDtial?.nftBalance.toSignificant(), 2, true) : '0'}
              </ThemedText.TextPrimary>
              <ThemedText.BodySmall color="textTitle">
                <Trans>｜</Trans>
              </ThemedText.BodySmall>
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Balance:</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {!voteDtial?.isVoted ? formatAmount(voteDtial?.nftBalance.toSignificant(), 2, true) : '0'}
              </ThemedText.TextPrimary>
            </VotedInfo>
          </Box>
        </Box>
      </StyledInfo>
      <VotedWrapper inputValueArr={inputValueArr} clearVoted={handelChange} />
      {isSmallScreen ? (
        <TableM NFTID={nftId} inputValue={inputValue} handelChange={handelChange} />
      ) : (
        <Table NFTID={nftId} inputValue={inputValue} handelChange={handelChange} />
      )}
    </Box>
  )
}
