import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { GridContainer, NoTokensStateM } from 'pages/Liquidity/Table'
import { AlertTriangle } from 'react-feather'

import { LoadedRow, LoadingRow } from './TableRow-m'

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function PositionTableM({ positions, closePosition, editPosition }: any) {
  const loadingVotes = true
  const positionList = positions
  /* loading and error state */
  if (loadingVotes && !positionList) {
    return <LoadingTokenTable rowCount={4} />
  } else if (!positionList) {
    return (
      <NoTokensStateM
        message={
          <>
            <AlertTriangle size={4} />
            <Trans>An error occurred loading positionList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (positionList?.length === 0) {
    return <NoTokensStateM message={<Trans>No positionList found</Trans>} />
  } else {
    return (
      <>
        <TableDataContainer>
          {positionList?.map((position: any, index: any) => (
            <LoadedRow
              key={index}
              positionListIndex={index}
              positionListLength={positionList.length}
              position={position}
              closePosition={closePosition}
              editPosition={editPosition}
              showLiqPirce={false}
            />
          ))}
        </TableDataContainer>
      </>
    )
  }
}
