import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { formatCurrencyAmount, NumberType } from '@uniswap/conedison/format'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { ButtonConfirmed } from 'components/Button'
import { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { LoadingDataBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { TabPRPS, TradeTypes } from 'components/swap/SwapDetails'
import { EDITPROPS } from 'components/swap/types'
import {
  ConfirmationModalContent2222,
  TransactionConfirmationModal22222,
} from 'components/TransactionConfirmationModal'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import { useTradePool, useTradeRouter } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { SwapSection } from 'pages/Swap/Styled'
import { useCallback, useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN, fromSqrt96Wei, fromWei, toFromBN } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { handlerError } from 'utils/formatError'

import { useEstimateDecrease, useEstimateIncrease, usePositonDetail } from '../Hooks'
import { TradePositionProps } from '../types'

interface EditPositionModalProps {
  isOpen: boolean
  onDismiss: () => void
  position?: TradePositionProps
}

const EditWrapper = styled.div`
  width: 364px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
  }
`

const EditInfo = styled.div`
  flex: 1;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 16px;
  .infoItem {
    padding: 8px 0;
  }
  .border-bottom {
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.swapSectionBorder};
  }
  .border-solid {
    border-bottom-style: solid;
  }
  .border-dashed {
    border-bottom-style: dashed;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
  }
`

export const EditSwapSection = styled(SwapSection)`
  border-radius: 12px;
  margin-top: 8px;
`

const DATA: undefined | TabPRPS[] = [
  { label: 'Deposit', value: EDITPROPS.Deposit },
  { label: 'Withdraw', value: EDITPROPS.Withdraw },
]
export default function EditPositionModal({ isOpen, onDismiss, position }: EditPositionModalProps) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const tradePoolContract = useTradeRouter()

  const { account } = useActiveChainId()
  const types = EDITPROPS.Deposit
  const [tabPeriod, setTabPeriod] = useState<EDITPROPS>(types)

  const isDeposit = tabPeriod == EDITPROPS.Deposit

  const onModalDismiss = useCallback(() => {
    onDismiss()
    setTxHash('')
    setTxError('')
    setAttemptingTxn(false)
  }, [onDismiss])

  const currencyA = useCurrency(position?.token0)
  const currencyB = useCurrency(position?.token1)
  const currentToken = isDeposit ? (position?.long0 ? currencyB : currencyA) : position?.long0 ? currencyB : currencyA
  const inputcurrency = isDeposit ? (position?.long0 ? currencyA : currencyB) : position?.long0 ? currencyB : currencyA
  const decimals = position?.long0 ? currencyB?.decimals : currencyA?.decimals

  const { inputAmount, handleUserInput, isInputValid, inputError } = useInputAmount(inputcurrency)
  const addTransaction = useTransactionAdder()

  const initialCollateral = useMemo(() => {
    if (!position || !currentToken) return
    return fromWei(position.collateral.toString(), currentToken?.decimals).toString()
  }, [currentToken, position])
  const tempCol = useMemo(() => {
    if (!position) return
    return BN(position.collateral.toString()).toFixed(0)
  }, [position])
  const currentAmount = useMemo(() => {
    if (!position || !inputcurrency) return
    return CurrencyAmount.fromRawAmount(inputcurrency, position.collateral.toString())
  }, [inputcurrency, position])

  const handleMaxTab = (value: string) => {
    if (!currentAmount || !inputcurrency) return
    if (isDeposit) {
      handleUserInput(value)
    } else {
      const amount = BN(currentAmount.toSignificant()).multipliedBy(value).toFixed(inputcurrency.decimals, 1)
      handleUserInput(amount)
    }
  }

  const switchTab = (type: any) => {
    setTabPeriod(type)
    handleUserInput('')
  }

  const {
    token0,
    token1,
    currentSqrtRatioX96,
    tradePool,
    positonsymbol: symbol,
    isToken0,
    tokenRatio,
    slippageTolerance,
  } = usePositonDetail(position)

  const markPrice = useMemo(() => {
    if (!position || !currentSqrtRatioX96) return
    const cur = fromSqrt96Wei(currentSqrtRatioX96.toString(), token0?.decimals, token1?.decimals)
    if (position.long0) {
      return cur.toFixed()
    } else {
      return BN(1).div(cur).toFixed()
    }
    // if (!isToken0) {
    //   return BN(1).div(cur).toFixed()
    // }
    // return cur.toFixed()
  }, [currentSqrtRatioX96, position, token0?.decimals, token1?.decimals])

  // const marketPrice = useMemo(() => {
  //   if (!position || !currentSqrtRatioX96) return
  //   const cur = fromSqrt96Wei(currentSqrtRatioX96.toString())

  //   if (!isToken0) {
  //     return BN(1).div(cur).toFixed()
  //   }
  //   return cur.toFixed()
  // }, [currentSqrtRatioX96, isToken0, position])
  const EntryPrice = useMemo(() => {
    if (!position) return
    const cur = fromSqrt96Wei(position.sqrtPriceX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [isToken0, position, token0?.decimals, token1?.decimals])

  const estimateIncreaseParamAmount = useMemo(() => {
    if (!isDeposit) return
    const INPUT = inputAmount.amount?.numerator.toString()
    if (!INPUT || INPUT == '0') return
    return INPUT
  }, [inputAmount.amount?.numerator, isDeposit])

  const { result: estimateIncrease, loading: increaseLoading } = useEstimateIncrease(
    position?.long0,
    tradePool,
    estimateIncreaseParamAmount ?? undefined,
    currentSqrtRatioX96,
    true,
    token0?.decimals,
    token1?.decimals
  )

  const token = position?.long0 ? token0 : token1
  const calctoken = position?.long0 ? token1 : token0

  const decreaseParmsCollateral = useMemo(() => {
    if (isDeposit || !position) return
    const INPUT = inputAmount.amount?.numerator.toString()
    if (!INPUT || INPUT == '0') return
    return BN(INPUT).toFixed(0)
  }, [inputAmount.amount?.numerator, isDeposit, position])

  const { result: estimateDecrease, loading: estimateDecreaseLoading } = useEstimateDecrease(
    position?.long0,
    position?.pool,
    '0',
    decreaseParmsCollateral
  )

  const Receive = useMemo(() => {
    const amount = inputAmount.amount?.numerator.toString()
    if (!position || !amount || amount == '' || !token0 || !token1 || !estimateDecrease || isDeposit || !tokenRatio)
      return

    const decimals = position.long0 ? token0.decimals : token1.decimals

    const calcReceive = fromWei(estimateDecrease?.payBack.toString(), decimals)

    const currentReceive = position.long0
      ? calcReceive.div(tokenRatio.invert().toSignificant())
      : calcReceive.div(tokenRatio.toSignificant())

    return {
      calcReceive: calcReceive.toFixed(4),
      currentReceive: currentReceive.toFixed(4),
    }
  }, [estimateDecrease, inputAmount.amount?.numerator, isDeposit, position, token0, token1, tokenRatio])

  const LiqPrice = useMemo(() => {
    if (!position) return
    const cur = fromSqrt96Wei(position.liqSqrtPriceX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [isToken0, position, token0?.decimals, token1?.decimals])
  const tradePoolContractc = useTradePool(tradePool)

  const netCollateral = useMemo(() => {
    const INPUT = inputAmount.amount?.numerator.toString()
    if (!INPUT || !position || !markPrice || INPUT == '0' || !tempCol || !token0 || !token1) return
    if (isDeposit) {
      if (position.long0) {
        return fromWei(
          BN(INPUT)
            .multipliedBy(markPrice)
            .div(BN(10).pow(token0.decimals))
            .multipliedBy(BN(10).pow(token1.decimals))
            .plus(tempCol)
            .toFixed(2),
          currentToken?.decimals
        ).toString()
      } else {
        return fromWei(
          BN(INPUT)
            .multipliedBy(markPrice)
            .div(BN(10).pow(token1.decimals))
            .multipliedBy(BN(10).pow(token0.decimals))
            .plus(tempCol)
            .toFixed(2),
          currentToken?.decimals
        ).toString()
      }
    } else {
      if (BN(INPUT).gte(tempCol)) return '0'
      if (!estimateDecrease) return
      return fromWei(estimateDecrease.collateral.toString(), decimals).toString()
    }
  }, [
    token0,
    token1,
    inputAmount.amount?.numerator,
    position,
    markPrice,
    tempCol,
    isDeposit,
    currentToken?.decimals,
    estimateDecrease,
    decimals,
  ])
  const netLeverage = useMemo(() => {
    const INPUT = inputAmount.amount?.numerator.toString()
    if (!INPUT || !position || !markPrice || INPUT == '0' || !tempCol || !token0 || !token1 || !netCollateral) return
    if (isDeposit) {
      return toFromBN(position.size, currentToken?.decimals).div(netCollateral).toFixed(2)
    } else {
      if (BN(INPUT).gte(tempCol)) return '0'
      if (!estimateDecrease) return
      return BN(position.size.toString()).div(estimateDecrease.collateral.toString()).toFixed(2)
    }
  }, [inputAmount.amount?.numerator, position, markPrice, isDeposit, tempCol, netCollateral, estimateDecrease])

  const NextLiqPrice = useMemo(() => {
    if (!estimateDecrease) return
    const cur = fromSqrt96Wei(estimateDecrease.decLiqPrice.toString(), token0?.decimals, token1?.decimals)
    if (estimateDecrease.decLiqPrice.toString() == '0') return
    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [estimateDecrease, isToken0, token0?.decimals, token1?.decimals])

  const DepositPosition = useCallback(async () => {
    if (
      !tradePoolContract ||
      !account ||
      !tradePool ||
      !tradePoolContractc ||
      !token0 ||
      !token1 ||
      !position ||
      !currentSqrtRatioX96
    )
      return
    const INPUT = inputAmount.amount?.numerator.toString()
    if (!INPUT) return
    setAttemptingTxn(true)
    try {
      const colAmount = INPUT
      const istoken0 = position.long0
      const response = await tradePoolContract.increasePosition(
        account,
        tradePool,
        colAmount,
        0,
        istoken0,
        // currentsqrtRatioX96T
        0
      )
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
        token0Address: token0.wrapped.address,
        token1Address: token1.wrapped.address,
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('increasePosition error', error)
    }
  }, [
    account,
    addTransaction,
    currentSqrtRatioX96,
    inputAmount.amount?.numerator,
    position,
    slippageTolerance,
    token0,
    token1,
    tradePool,
    tradePoolContract,
    tradePoolContractc,
  ])

  const WithdrawPosition = useCallback(async () => {
    let amount = inputAmount.amount?.numerator.toString()
    if (!tradePoolContract || !tradePool || !amount || !position || !currentSqrtRatioX96 || !account) return
    setAttemptingTxn(true)
    if (BN(amount).gt(position.collateral.toString())) {
      amount = position.collateral.toString()
    }
    try {
      const response = await tradePoolContract.decreasePosition(
        account,
        tradePool,
        amount,
        0,
        position.long0,
        false, //TODO: eth
        0
        // toSqrt96(scurrentsqrtRatioX96).toFixed(0)
      )
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.TRADE,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('error', error)
    }
  }, [
    account,
    addTransaction,
    currentSqrtRatioX96,
    inputAmount.amount?.numerator,
    position,
    tradePool,
    tradePoolContract,
  ])

  const slippageValue = useMemo(() => {
    if (!estimateDecrease) return
    return (estimateDecrease.closeSpread / 1000000) * 100
  }, [estimateDecrease])

  const handeledit = useCallback(() => {
    if (isDeposit) {
      DepositPosition()
    } else {
      WithdrawPosition()
    }
  }, [DepositPosition, WithdrawPosition, isDeposit])

  if (!position) return <></>

  const { liqSqrtPriceX96, size, collateral } = position

  const leverage = BN(size.toString()).div(collateral.toString()).toFixed(2)

  const modalContent = (
    <ConfirmationModalContent2222
      onDismiss={onModalDismiss}
      title={<Trans>Edit </Trans>}
      topContent={
        <Box
          width="100%"
          display="flex"
          alignItems="flex-start"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          gap={isSmallScreen ? '16px' : '24px'}
          mt="16px"
        >
          <EditWrapper>
            <AutoColumn gap="sm">
              <Row width="auto">
                <TradeTypes tabPeriod={tabPeriod} setTabPeriod={setTabPeriod} data={DATA} onChange={switchTab} />
              </Row>
              <EditSwapSection>
                <SwapCurrencyInputPanel
                  label={<Trans>Edit</Trans>}
                  disabled={false}
                  currency={inputcurrency}
                  value={inputAmount.value}
                  showMaxButton={false}
                  id="close-input"
                  onUserInput={handleUserInput}
                  loading={false}
                  showMaxTab
                  onMaxTab={handleMaxTab}
                  renderBalance={
                    isDeposit
                      ? undefined
                      : () => {
                          return <Trans>Balance: {formatCurrencyAmount(currentAmount, NumberType.TokenNonTx)}</Trans>
                        }
                  }
                />
              </EditSwapSection>
              {/* <RowBetween mt="8px">
                <ThemedText.TextSecondary fontSize={14}>Leverage</ThemedText.TextSecondary>
                <ThemedText.TextPrimary fontWeight={500}>
                  {leverage}x → {netLeverage}
                </ThemedText.TextPrimary>
              </RowBetween> */}
              {!isDeposit && (
                <RowBetween mt="8px">
                  <ThemedText.TextSecondary fontSize={14}>Receive</ThemedText.TextSecondary>
                  {estimateDecreaseLoading ? (
                    <LoadingDataBubble />
                  ) : (
                    <>
                      <Row justify="flex-end" flex={1} gap="4px">
                        <ThemedText.TextPrimary fontWeight={500}>
                          {Receive?.calcReceive || 0} {token?.symbol}
                        </ThemedText.TextPrimary>
                        <ThemedText.TextSecondary fontSize={12}>
                          ({Receive?.currentReceive || 0} {calctoken?.symbol})
                        </ThemedText.TextSecondary>
                      </Row>
                    </>
                  )}
                </RowBetween>
              )}
              <ButtonConfirmed
                data-testid="confirm-trade-button"
                id="confirm-trade-button"
                mt="8px"
                disabled={!isInputValid}
                onClick={handeledit}
              >
                <ThemedText.HeadlineSmall color="accentTextLightPrimary">
                  {inputError ? inputError : <Trans>Submit</Trans>}
                </ThemedText.HeadlineSmall>
              </ButtonConfirmed>
            </AutoColumn>
          </EditWrapper>
          <EditInfo>
            <RowBetween className="infoItem border-bottom border-dashed">
              <ThemedText.TextSecondary fontSize={14}>Size</ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={500}>
                  {countZeros(toFromBN(position.size, currentToken?.decimals).toFixed())}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>
                  {position?.long0 ? token1?.symbol : token0?.symbol}
                </ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            <RowBetween className="infoItem border-bottom border-solid">
              <ThemedText.TextSecondary fontSize={14}>Collateral</ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={500}>
                  {netCollateral ? (
                    <Row>
                      <ThemedText.TextSecondary fontSize={12}>{countZeros(initialCollateral)}</ThemedText.TextSecondary>
                      &nbsp;→ {countZeros(netCollateral)}
                    </Row>
                  ) : (
                    countZeros(initialCollateral)
                  )}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>
                  {position?.long0 ? token1?.symbol : token0?.symbol}
                </ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            <RowBetween className="infoItem border-bottom border-dashed" mt="8px">
              <ThemedText.TextSecondary fontSize={14}>Leverage</ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={500}>
                  {netLeverage ? (
                    <Row>
                      <ThemedText.TextSecondary fontSize={12}>{leverage}</ThemedText.TextSecondary>
                      &nbsp;→ {countZeros(netLeverage)}
                    </Row>
                  ) : (
                    leverage
                  )}{' '}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>X</ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            <RowBetween className="infoItem border-bottom border-dashed">
              <ThemedText.TextSecondary fontSize={14}>Entry Price</ThemedText.TextSecondary>

              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={500}>{countZeros(EntryPrice)}</ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            <RowBetween className="infoItem border-bottom border-dashed">
              <ThemedText.TextSecondary fontSize={14}>Liq.Price</ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={500}>
                  {isDeposit ? (
                    estimateIncrease && estimateIncrease.prevLiqPrice && estimateIncrease.prevLiqPrice != '0' ? (
                      <Row>
                        <ThemedText.TextSecondary fontSize={12}>
                          {countZeros(estimateIncrease.prevLiqPrice)}
                        </ThemedText.TextSecondary>
                        &nbsp;→ {countZeros(estimateIncrease.liqPrice)}
                      </Row>
                    ) : (
                      countZeros(estimateIncrease?.liqPrice || LiqPrice)
                    )
                  ) : estimateDecreaseLoading ? (
                    <LoadingDataBubble />
                  ) : NextLiqPrice && NextLiqPrice != '0' ? (
                    <Row>
                      <ThemedText.TextSecondary fontSize={12}>{countZeros(LiqPrice)}</ThemedText.TextSecondary>
                      &nbsp;→ {countZeros(NextLiqPrice)}
                    </Row>
                  ) : (
                    countZeros(LiqPrice)
                  )}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            {/* <RowBetween className="infoItem border-bottom border-dashed">
              <ThemedText.TextSecondary fontSize={14}>Slippage</ThemedText.TextSecondary>

              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={700}>
                  {isDeposit ? (
                    increaseLoading ? (
                      <LoadingDataBubble />
                    ) : (
                      `${countZeros(estimateIncrease?.spread || '0')}%`
                    )
                  ) : estimateDecreaseLoading ? (
                    <LoadingDataBubble />
                  ) : (
                    `${countZeros(slippageValue)}%`
                  )}
                </ThemedText.TextPrimary>
              </Row>
            </RowBetween> */}
          </EditInfo>
        </Box>
      }
    />
  )
  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={onModalDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      pendingText={pendingText}
      width="800px"
      reviewContent={modalContent}
    />
  )
}
