import { Trans } from '@lingui/macro'
import Row from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const StyledSelectBox = styled(Row)`
  justify-content: center;
  height: 48px;
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
`

export default function SelectWrapper() {
  return (
    <StyledSelectBox>
      <ThemedText.TextSecondary fontSize={12}>
        <Trans>Select Your veROX NFT</Trans>
      </ThemedText.TextSecondary>
    </StyledSelectBox>
  )
}
