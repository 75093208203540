import { t } from '@lingui/macro'
import { SearchInput } from 'components/SearchModal/styleds'
import useDebounce from 'hooks/useDebounce'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useEffect, useState } from 'react'

import { LeaderboardfilterStringAtom } from './state'

export default function SearchBar() {
  const currentString = useAtomValue(LeaderboardfilterStringAtom)

  const [searchQuery, setSearchQuery] = useState(currentString)
  const setFilterString = useUpdateAtom(LeaderboardfilterStringAtom)

  const debouncedLocalFilterString = useDebounce(searchQuery, 300)

  useEffect(() => {
    setSearchQuery(currentString)
  }, [currentString])

  useEffect(() => {
    setFilterString(debouncedLocalFilterString)
  }, [debouncedLocalFilterString, setFilterString])

  return (
    <SearchInput
      data-cy="liquidity-search-input"
      type="search"
      placeholder={t`Search`}
      id="search-liquidity"
      autoComplete="off"
      value={searchQuery}
      onChange={({ target: { value } }) => setSearchQuery(value)}
    />
  )
}
