import { Box } from '@mui/material'
import styled from 'styled-components/macro'

const BaseWrapper = styled.div<{ bg?: string }>`
  width: 5px;
  height: 12px;
  background: ${({ bg }) => (bg ? bg : 'rgba(0, 0, 0, 0.05)')};
`
export function SpreadRangeUp() {
  return (
    <Box display="flex" gap="2px">
      <BaseWrapper />
      <BaseWrapper />
      <BaseWrapper bg="#04AE3E" />
    </Box>
  )
}

export function SpreadRangeMiddle() {
  return (
    <Box display="flex" gap="2px">
      <BaseWrapper />
      <BaseWrapper bg="#CA8A0D" />
      <BaseWrapper bg="#CA8A0D" />
    </Box>
  )
}

export function SpreadRangeDown() {
  return (
    <Box display="flex" gap="2px">
      <BaseWrapper bg="#FF3D00" />
      <BaseWrapper bg="#FF3D00" />
      <BaseWrapper bg="#FF3D00" />
    </Box>
  )
}
