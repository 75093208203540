import { Box, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'

import Tab from '../Tab'
import Table from './Table'
import TableM from './Table-m'

const HistoryBox = styled(Box)`
  margin-top: 24px;
`

export default function History() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const [curr, setCurr] = useState<any>(0)
  const tabList = ['Invited', 'ROX Point']
  return (
    <HistoryBox>
      <Tab tabList={tabList} curr={curr} setCurr={setCurr} />
      <Box mt="24px">{isSmallScreen ? <TableM /> : <Table />}</Box>
    </HistoryBox>
  )
}
