import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import Column from 'components/Column'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'

import { ReactComponent as Roguex } from '../../assets/imgs/tokens/roguex.svg'
import { useRoxAPR } from './hooks'

const StyledRebaseBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 560px;
  max-width: 100%;
  height: 100px;
  border-radius: 50px;
  padding: 10px 24px 10px 18px;
  background: ${({ theme }) => theme.backgroundInteractive};
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 100%;
  }
`

export default function RebaseAPRWrapper() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const apr = useRoxAPR()
  return (
    <StyledRebaseBox>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap="16px" width="100%">
        <Box display="flex" alignItems="center">
          <Roguex width={isSmallScreen ? 36 : 72} height={isSmallScreen ? 36 : 72} />
          <Box display="flex" flexDirection="column" alignItems="flex-start" ml={isSmallScreen ? '12px' : '24px'}>
            <ThemedText.TextPrimary fontSize={24} fontWeight={800}>
              ROX
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>RougeX Token</Trans>
            </ThemedText.TextSecondary>
          </Box>
        </Box>
        <Box display="flex">
          <Column align="center">
            <ThemedText.TextSecondary>
              <Trans>Rebase APR</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
              {apr?.toFixed(2)}%
            </ThemedText.TextPrimary>
          </Column>
        </Box>
      </Box>
    </StyledRebaseBox>
  )
}
