import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent } from '@uniswap/sdk-core'
import SWAPMINGABI from 'abis/swapMinning.json'
import { BribesButton, ClaimButton, Pending } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { ToastError } from 'components/Toast'
import { useActiveChainId, useEthersSigner } from 'connection/useActiveChainId'
import { useCurrency, useToken } from 'hooks/Tokens'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import AddBribesModal from 'pages/Vote/AddBribesModal'
/* Token Row: skeleton row component */
import { TotalRewardsItem } from 'pages/Vote/VoteRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useVoteCurrentId } from 'state/http/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { ThemedText } from 'theme'
import { getContract } from 'utils'
import { DURATION, getWeeklyId } from 'utils/CurrRound'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount, NumberType } from 'utils/formatCurrency'
import { handlerError } from 'utils/formatError'
import { unwrappedToken } from 'utils/unwrappedToken'

import { ReactComponent as ToIcon } from '../../assets/imgs/to2.svg'
import { LoadedRowProps, TotalLeaderboardEarnsItem } from './LeaderboardRow'
import { LeaderboardSortMethod } from './state'
function LeaderboardRow({
  pools,
  tvl,
  volume,
  totalRewards,
  myVolume,
  earn,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  totalRewards: ReactNode
  tvl: ReactNode
  volume: ReactNode
  pools: ReactNode
  myVolume: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <TextLeftCell data-testid="pools-cell">{pools}</TextLeftCell>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="tvl-cell">{tvl}</TextLeftCell>
        <TextRightCell data-testid="total-rewards-cell">{totalRewards}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="volume-cell">{volume}</TextLeftCell>
        <TextRightCell data-testid="my-volume-cell" className=" ">
          {myVolume}
        </TextRightCell>
      </RowBetween>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="earn-cell" className=" ">
          {earn}
        </TextLeftCell>
      </RowBetween>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LeaderboardRow
      $loading
      pools={
        <Row gap="sm">
          <IconLoadingBubble width="40px" height="40px" />
          <SmallLoadingBubble />
          <ToIcon />
        </Row>
      }
      tvl={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LeaderboardSortMethod.TVL}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
          <SmallLoadingBubble />
          <SmallLoadingBubble />
        </Column>
      }
      volume={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LeaderboardSortMethod.VOLUME}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      totalRewards={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LeaderboardSortMethod.INCENTIVIZATION}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
          <SmallLoadingBubble />
        </Column>
      }
      myVolume={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LeaderboardSortMethod.MY_VOLUME}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
          <SmallLoadingBubble />
        </Column>
      }
      earn={
        <RowBetween gap="xs">
          <Column align="flex-start" width="100%" gap="xs">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>{LeaderboardSortMethod.EARN}</Trans>
            </ThemedText.TextSecondary>
            <SmallLoadingBubble />
            <SmallLoadingBubble />
          </Column>
          <ClaimButton>Claim</ClaimButton>
        </RowBetween>
      }
      {...props}
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { leaderboardListIndex, leaderboardListLength, leaderboard } = props
  const [timeCount, setTimeCount] = useState(10)
  const [showAddBribes, setShowAddBribes] = useState(false)
  const handleDismissAddBribes = () => {
    setShowAddBribes(false)
  }
  const handleOpen = (e: any) => {
    e.preventDefault()
    setShowAddBribes(true)
    setTimeCount(10)
  }
  const token0 = useCurrency(leaderboard.token0.id)
  const provder = useEthersSigner()
  const { account } = useActiveChainId()
  const epochNum = useVoteCurrentId()

  // const currRound = getCurrRound()
  const totalVolume = useMemo(() => {
    if (!token0 || !leaderboard.totalVolume) return
    return CurrencyAmount.fromRawAmount(token0, leaderboard.totalVolume.toString())
  }, [leaderboard.totalVolume, token0])

  const myVolume = useMemo(() => {
    if (!token0 || !leaderboard.myVolumes) return
    return CurrencyAmount.fromRawAmount(token0, leaderboard.myVolumes.toString())
  }, [leaderboard.myVolumes, token0])

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const onClaim = async (e: any, data: any) => {
    setAttemptingTxn(true)
    e.preventDefault()
    try {
      const swapMIng = getContract(data.swapMinningAddress, SWAPMINGABI.abi, provder)
      if (!swapMIng || !account) return
      const response = await swapMIng.getReward(account, epochNum * DURATION)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('claimRewards error', error)
    }
  }

  const currentEpochId = getWeeklyId()
  const isCurrEpoch = useMemo(() => {
    return currentEpochId === epochNum
  }, [currentEpochId, epochNum])

  const leaderboardToken0 = useToken(leaderboard.token0.id)
  const leaderboardToken1 = useToken(leaderboard.token1.id)
  const currency0 = leaderboardToken0 ? unwrappedToken(leaderboardToken0) : undefined
  const currency1 = leaderboardToken1 ? unwrappedToken(leaderboardToken1) : undefined

  return (
    <NavLink to={`/leaderboardDetail/${leaderboard.lpAddr}`} style={{ textDecoration: 'none' }}>
      <div ref={ref} data-testid={`leaderboard-table-row-${leaderboard.lpAddr}`}>
        <LeaderboardRow
          pools={
            <RowBetween gap="sm">
              <Row gap="sm">
                <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={40} margin />
                <Column align="flex-start">
                  <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                    {leaderboard.token0.symbol}-{leaderboard.token1.symbol}
                  </ThemedText.TextPrimary>
                  <ThemedText.TextSecondary fontSize={14}>
                    {new Percent(leaderboard.poolFee, 1_000_000).toSignificant()}%
                  </ThemedText.TextSecondary>
                </Column>
              </Row>
              <ToIcon />
            </RowBetween>
          }
          tvl={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LeaderboardSortMethod.TVL}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(leaderboard.tvl, 2, true)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(leaderboard.liqvidityToken0, NumberType.FiatTokenStats2)}{' '}
                {leaderboard.token0.symbol}
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(leaderboard.liqvidityToken1, NumberType.FiatTokenStats2)}{' '}
                {leaderboard.token1.symbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          volume={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LeaderboardSortMethod.VOLUME}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                $
                {formatAmount(
                  Number(totalVolume?.toSignificant()) *
                    Number(leaderboard.token0.derivedETH) *
                    Number(leaderboard.ethPrice) || 0,
                  2,
                  true
                )}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(totalVolume, NumberType.FiatTokenStats2)} {totalVolume?.currency.symbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          totalRewards={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LeaderboardSortMethod.INCENTIVIZATION}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(leaderboard.swapMinningrewardUSD, 2, true)}
              </ThemedText.TextPrimary>
              {leaderboard?.swapMinningrewardsLists?.map((item, index: number) => {
                return <TotalRewardsItem key={'leaderboard-rewardItem' + index} rewardAddr={item} />
              })}
              {isCurrEpoch && (
                <BribesButton
                  onClick={(e) => {
                    handleOpen(e)
                  }}
                >
                  + Bribes
                </BribesButton>
              )}
            </Column>
          }
          myVolume={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LeaderboardSortMethod.MY_VOLUME}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                $
                {formatAmount(
                  Number(myVolume?.toSignificant()) *
                    Number(leaderboard.token0.derivedETH) *
                    Number(leaderboard.ethPrice),
                  2,
                  true
                )}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(myVolume, NumberType.FiatTokenStats2)} {myVolume?.currency.symbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          earn={
            <RowBetween gap="xs">
              <Column align="flex-start" width="100%" gap="xs" style={{ flex: 1 }}>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>{LeaderboardSortMethod.EARN}</Trans>
                </ThemedText.TextSecondary>
                {leaderboard.swapMinningrewardsLists && leaderboard.swapMinningrewardsLists?.length > 0 ? (
                  leaderboard.swapMinningrewardsLists?.map((item: any, index: number) => {
                    return (
                      <TotalLeaderboardEarnsItem
                        key={'earn-leaderboard' + index}
                        rewardAddr={item}
                        swapMing={leaderboard.swapMinningAddress}
                      />
                    )
                  })
                ) : (
                  <ThemedText.TextRewards>--</ThemedText.TextRewards>
                )}
              </Column>
              <ClaimButton disabled={attemptingTxn} onClick={(e) => onClaim(e, leaderboard)}>
                {attemptingTxn ? <Pending /> : 'Claim'}
              </ClaimButton>
            </RowBetween>
          }
          first={leaderboardListIndex === 0}
          last={leaderboardListIndex === leaderboardListLength - 1}
        />
      </div>
      <AddBribesModal
        brigeType="Trade"
        isOpen={showAddBribes}
        details={leaderboard}
        count={timeCount}
        setCount={setTimeCount}
        onDismiss={handleDismissAddBribes}
      />
    </NavLink>
  )
})

LoadedRow.displayName = 'LoadedRow'
