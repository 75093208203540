import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { GridContainer, NoTokensStateM } from 'pages/Liquidity/Table'
import { useOrderList } from 'pages/Trade/Hooks'
import { AlertTriangle } from 'react-feather'

import { LoadedRow, LoadingRow } from './LimitRow-m'

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function TableM() {
  const limitList = useOrderList()
  const loadingLimits = false

  /* loading and error state */
  if (loadingLimits && !limitList) {
    return <LoadingTokenTable rowCount={PAGE_SIZE} />
  } else if (!limitList) {
    return (
      <NoTokensStateM
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading limitList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (limitList?.length === 0) {
    return <NoTokensStateM message={<Trans>No limitList found</Trans>} />
  } else {
    return (
      <>
        <TableDataContainer>
          {limitList.map((limit, index) => (
            <LoadedRow key={index} limitListIndex={index} limitListLength={limitList.length} limit={limit} />
          ))}
        </TableDataContainer>
      </>
    )
  }
}
