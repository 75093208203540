import { Box, useMediaQuery } from '@mui/material'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { ClaimButton, Pending } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { StyledBackIcon } from 'components/Icons/StyledIcon'
import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { ToastError } from 'components/Toast'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useToken } from 'hooks/Tokens'
import { useSwapMinning } from 'hooks/useContract'
import SearchBar from 'pages/Liquidity/SearchBar'
import { useFetchVoter } from 'pages/Vote/hooks'
import { TotalRewardsItem } from 'pages/Vote/VoteRow'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useVoteCurrentId } from 'state/http/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { DURATION, getCurrRound } from 'utils/CurrRound'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'
import { unwrappedToken } from 'utils/unwrappedToken'

import EpochDropdown from '../EpochDropdown'
import { TotalLeaderboardEarnsItem } from '../LeaderboardRow'
import Table from './Table'
import TableM from './Table-m'

const StyledHeaderRow = styled(Row)`
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  padding-bottom: 50px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-bottom: 36px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-bottom: 20px;
  }
`

const StyledSearchBox = styled(Box)`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
`

const StyledInfoRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px 0 16px;
  .volumeInfo {
    min-width: 500px;
    padding: 16px 16px 22px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
    .myVolume {
      padding-left: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
    }
    .earned {
      padding-left: 16px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: column;
    gap: 16px;
    .volumeInfo {
      min-width: 100%;
      .myVolume {
        padding-left: 0;
      }
      .earned {
        padding-left: 0;
      }
    }
  }
`

export default function LeaderboardDetail() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isSmallScreen2 = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const navigate = useNavigate()
  const handlerBack = () => {
    navigate(-1)
  }
  const { account, chainId } = useActiveChainId()

  const { pool } = useParams()
  const currRound = getCurrRound()
  const { voteMAP } = useFetchVoter()

  const epochNum = useVoteCurrentId()

  const currentSwapMinngInfo = useMemo(() => (pool && voteMAP ? voteMAP[pool] : undefined), [pool, voteMAP])

  const leaderboardToken0 = useToken(currentSwapMinngInfo?.token0.id)
  const leaderboardToken1 = useToken(currentSwapMinngInfo?.token1.id)
  const currency0 = leaderboardToken0 ? unwrappedToken(leaderboardToken0) : undefined
  const currency1 = leaderboardToken1 ? unwrappedToken(leaderboardToken1) : undefined

  const swapminngContract = useSwapMinning(currentSwapMinngInfo?.swapMinningAddress)

  const token0Currenty = useMemo(() => {
    if (!currentSwapMinngInfo || !chainId) return
    const { id, symbol, name } = currentSwapMinngInfo.token0
    return new Token(chainId, id, Number(currentSwapMinngInfo.token0.decimals), symbol, name)
  }, [chainId, currentSwapMinngInfo])

  const totalValume = useMemo(() => {
    if (!currentSwapMinngInfo || !chainId || !token0Currenty) return
    return CurrencyAmount.fromRawAmount(token0Currenty, currentSwapMinngInfo.swapMinningInfo?.totalValume || 0)
  }, [chainId, currentSwapMinngInfo, token0Currenty])

  const MyVlume = useMemo(() => {
    if (!currentSwapMinngInfo || !chainId || !token0Currenty) return
    return CurrencyAmount.fromRawAmount(token0Currenty, currentSwapMinngInfo.myVolumes?.toString() || 0)
  }, [chainId, currentSwapMinngInfo, token0Currenty])

  const totalRewardsUSD = () => {
    let totalETH = 0
    currentSwapMinngInfo?.swapMinningrewardsLists?.map((item) => {
      totalETH +=
        (Number(item.tokenInfo?.derivedETH) * Number(item.rewardAmount)) / Math.pow(10, Number(item.tokenInfo.decimals))
    })
    return totalETH * Number(currentSwapMinngInfo?.ethPrice)
  }

  const onClaim = async () => {
    if (!swapminngContract || !account) return
    setAttemptingTxn(true)
    try {
      const response = await swapminngContract.getReward(account, DURATION * epochNum)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('claimRewards error', error)
    }
  }

  return (
    <PageWrapper data-testid="leaderBoardDetail-page">
      <StyledHeaderRow>
        <Row width="auto" align="center" gap="xmd">
          <StyledBackIcon onClick={handlerBack} />
          <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={40} margin />
          <ThemedText.LargeHeader fontSize={isSmallScreen2 ? 24 : 48}>
            {currentSwapMinngInfo?.token0.symbol}/{currentSwapMinngInfo?.token1.symbol}-
            {new Percent(currentSwapMinngInfo?.poolFee || 0, 1_000_000).toSignificant()}%
          </ThemedText.LargeHeader>
        </Row>
        <Box
          display="flex"
          flexDirection={isSmallScreen2 ? 'row' : 'column'}
          alignItems={isSmallScreen2 ? 'center' : 'flex-end'}
          justifyContent={isSmallScreen2 ? 'space-between' : 'flex-end'}
          width={isSmallScreen2 ? '100%' : 'auto'}
          gap="16px"
          className=""
        >
          <EpochDropdown />
          {/* <Row width="auto">
            <ThemedText.TextSecondary fontWeight={700} fontSize={15}>
              Countdown:
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={15}>
              6d
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary ml="4px" mr="4px" fontSize={15}>
              /
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={15}>
              6h
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary ml="4px" mr="4px" fontSize={15}>
              /
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={15}>
              6s
            </ThemedText.TextPrimary>
          </Row> */}
        </Box>
      </StyledHeaderRow>
      <StyledInfoRow>
        <Column justify="space-between" align="flex-start" gap="16px">
          <Column gap="xs" align="flex-start">
            <ThemedText.TextSecondary fontSize={isVerySmallScreen ? 12 : 16}>Total Rewards</ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={isVerySmallScreen ? 16 : 24}>
              ${formatAmount(totalRewardsUSD(), 2, true)}
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={isVerySmallScreen ? 12 : 14}>
              {currentSwapMinngInfo?.swapMinningrewardsLists?.map((item, index: number) => {
                return <TotalRewardsItem key={'leaderboard' + index} rewardAddr={item} />
              })}
            </ThemedText.TextSecondary>
          </Column>
          <Row width="auto" gap="64px" align="flex-start">
            <Column gap="xs" align="flex-start">
              <ThemedText.TextSecondary fontSize={isVerySmallScreen ? 12 : 16}>Trading Volume</ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700} fontSize={isVerySmallScreen ? 16 : 24}>
                $
                {formatAmount(
                  Number(totalValume?.toSignificant()) *
                    Number(currentSwapMinngInfo?.token0.derivedETH) *
                    Number(currentSwapMinngInfo?.ethPrice) || 0,
                  2,
                  true
                )}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={isVerySmallScreen ? 12 : 14}>
                {formatAmount(totalValume?.toSignificant(), 2, true)} {token0Currenty?.symbol}
              </ThemedText.TextSecondary>
            </Column>
            <Column gap="xs" align="flex-start">
              <ThemedText.TextSecondary fontSize={isVerySmallScreen ? 12 : 16}>Traders</ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700} fontSize={isVerySmallScreen ? 16 : 24}>
                {formatAmount(currentSwapMinngInfo?.swapMinningInfo?.swapedUser.length, 2, true)}
              </ThemedText.TextPrimary>
            </Column>
          </Row>
        </Column>
        <div className="volumeInfo">
          <Column align="flex-start" className="myVolume">
            <ThemedText.TextSecondary>My Volume</ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={24}>
              {formatAmount(MyVlume?.toSignificant(), 2, true)} {token0Currenty?.symbol}
            </ThemedText.TextPrimary>
          </Column>
          <Row justify="space-between" align="center" mt={12} className="earned">
            <Column align="flex-start">
              <ThemedText.TextSecondary>Earned</ThemedText.TextSecondary>
              {currentSwapMinngInfo?.swapMinningrewardsLists?.map((item, index) => {
                return (
                  <TotalLeaderboardEarnsItem
                    rewardAddr={item}
                    swapMing={currentSwapMinngInfo?.swapMinningAddress}
                    key={'Earned' + index}
                  />
                )
              })}
            </Column>
            <ClaimButton onClick={onClaim} disabled={attemptingTxn}>
              {attemptingTxn ? <Pending /> : 'Claim'}
            </ClaimButton>
          </Row>
        </div>
      </StyledInfoRow>
      <StyledSearchBox className="searchBox">
        <SearchBar />
      </StyledSearchBox>
      {isSmallScreen ? (
        <TableM tableInfo={currentSwapMinngInfo?.swapMinningInfo?.swapedUser} token={token0Currenty} />
      ) : (
        <Table tableInfo={currentSwapMinngInfo?.swapMinningInfo?.swapedUser} token={token0Currenty} />
      )}
    </PageWrapper>
  )
}
