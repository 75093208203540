import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useHypervisor } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ReactNode, useMemo } from 'react'
import { Field } from 'state/swap/actions'
import { SwapState } from 'state/swap/reducer'
import { BN, toFromBN } from 'utils/bn'

export const useAddPoolLiquidity = (
  state: SwapState,
  hypervisorAddr?: string,
  token0?: Currency | null | undefined,
  token1?: Currency | null | undefined
) => {
  const { account } = useActiveChainId()

  const { independentField, typedValue } = state

  const inputCurrency = token0
  const outputCurrency = token1

  const isExactIn: boolean = independentField === Field.INPUT

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [inputCurrency ?? undefined, outputCurrency ?? undefined], [inputCurrency, outputCurrency])
  )

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  )

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  )

  const parsedAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  )

  const hypervisorContract = useHypervisor(hypervisorAddr)

  const { result: AmountRatio } = useSingleCallResult(hypervisorContract, 'getDepositAmountRatio')
  const outAmount = useMemo(() => {
    if (!AmountRatio || !parsedAmount || !inputCurrency || !outputCurrency) return

    const add = toFromBN(AmountRatio[0], 36)
      .times(parsedAmount.toSignificant())
      .times(BN(10).pow(inputCurrency.decimals))
      .toFixed(0)

    const cdd = BN(parsedAmount.toSignificant())
      .div(toFromBN(AmountRatio[0], 36))
      .times(BN(10).pow(outputCurrency.decimals))
      .toFixed(0)
    if (AmountRatio[0].toString() == '0') return CurrencyAmount.fromRawAmount(inputCurrency, 0)
    const amountOut = CurrencyAmount.fromRawAmount(outputCurrency, add)

    const amountINs = CurrencyAmount.fromRawAmount(inputCurrency, cdd)

    return isExactIn ? amountOut : amountINs
  }, [AmountRatio, inputCurrency, isExactIn, outputCurrency, parsedAmount])

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined

    if (!account) {
      inputError = <Trans>Connect Wallet</Trans>
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>
    }
    if (isExactIn && parsedAmount && currencyBalances[Field.INPUT]?.lessThan(parsedAmount)) {
      inputError = <Trans>Insufficient {currencyBalances?.[Field.INPUT]?.currency.symbol} balance</Trans>
    }

    if (!isExactIn && parsedAmount && currencyBalances[Field.OUTPUT]?.lessThan(parsedAmount)) {
      inputError = <Trans>Insufficient {currencyBalances?.[Field.INPUT]?.currency.symbol} balance</Trans>
    }

    if (isExactIn && outAmount && currencyBalances[Field.OUTPUT]?.lessThan(outAmount)) {
      inputError = <Trans>Insufficient {currencyBalances?.[Field.OUTPUT]?.currency.symbol} balance</Trans>
    }

    if (!isExactIn && outAmount && currencyBalances[Field.INPUT]?.lessThan(outAmount)) {
      inputError = <Trans>Insufficient {currencyBalances?.[Field.INPUT]?.currency.symbol} balance</Trans>
    }

    return inputError
  }, [account, currencies, parsedAmount, isExactIn, currencyBalances, outAmount])

  return {
    inputError,
    outAmount,
    parsedAmount,
    currencyBalances,
  }
}

// eslint-disable-next-line import/no-unused-modules
export const useSharsAmount = (hypervisorAddr?: string, token0?: Currency, token1?: Currency) => {
  const { account } = useActiveChainId()
  const hypervisorContract = useHypervisor(hypervisorAddr)
  const { result: balance } = useSingleCallResult(hypervisorContract, 'getUserAmounts', [account])

  return useMemo(() => {
    if (!balance || !token0 || !token1) return undefined

    return {
      amount0: CurrencyAmount.fromRawAmount(token0, balance.amount0.toString()),
      amount1: CurrencyAmount.fromRawAmount(token1, balance.amount1.toString()),
    }
  }, [balance, token0, token1])
}
