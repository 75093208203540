import { CurrencyAmount } from '@uniswap/sdk-core'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useRoguxCurrency } from 'constants/tokens'
import { BigNumber } from 'ethers/lib/ethers'
import { useLockStakeToken, useLockVeToken } from 'hooks/TokensCommon'
import { useLockContract, useMinter, useVoter } from 'hooks/useContract'
import { useTotalSupply } from 'hooks/useTotalSupply'
import { useAtomValue } from 'jotai/utils'
import { CallStateResult, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import { useMemo } from 'react'
import { useVoteCurrentId } from 'state/http/hooks'
import { BN } from 'utils/bn'
import { DURATION } from 'utils/CurrRound'

import { LockSortMethod, sortAscendingAtom, sortMethodAtom } from './state'
import { LockStakeListProps } from './types'

export function useLockStakeList() {
  const { tokenIds } = useGetLockTokenIds()
  return useLockDetailTokenIds(tokenIds)
}

export function useGetLockTokenIds() {
  const { account } = useActiveChainId()
  const lockContract = useLockContract()
  const { result: NftbalanceOf, loading } = useSingleCallResult(lockContract, 'balanceOf', [account])
  const listPams = useMemo(() => {
    if (!NftbalanceOf) return [[undefined]]
    return Array.from({ length: NftbalanceOf[0].toString() }, (item, index) => [account, index])
  }, [NftbalanceOf, account])

  const tokenIdResults = useSingleContractMultipleData(lockContract, 'tokenOfOwnerByIndex', listPams)
  const tokensLoading = tokenIdResults.some((x) => x.loading)
  const tokenIds = useMemo(() => {
    return tokenIdResults
      .map(({ result }) => result)
      .filter((result): result is CallStateResult => !!result)
      .map((result) => BigNumber.from(result[0]))
  }, [tokenIdResults])

  return {
    tokenIds,
    loading: loading || tokensLoading,
  }
}

export function useWithdrawInfo(tokenId: string) {
  const lockContract = useLockContract()
  const { result: lockedInfo } = useSingleCallResult(lockContract, 'locked', [tokenId])
  const { result: create_lock_time } = useSingleCallResult(lockContract, 'create_lock_time', [tokenId])
  const roxCurrency = useRoguxCurrency()
  return useMemo(() => {
    if (!lockedInfo || !create_lock_time || !roxCurrency) return
    const lockAmount = CurrencyAmount.fromRawAmount(roxCurrency, lockedInfo.amount)
    return {
      lockAmount,
      endTime: lockedInfo.endTime,
      create_lock_time: create_lock_time[0],
    }
  }, [create_lock_time, lockedInfo, roxCurrency])
}

function useLockDetailTokenIds(tokenIds: BigNumber[] | undefined): {
  loading: boolean
  tokenIdDetails?: LockStakeListProps[]
} {
  const lockContract = useLockContract()
  const voteContract = useVoter()
  const velock = useLockVeToken()
  const stakeLockToken = useLockStakeToken()
  const currId = useVoteCurrentId()
  const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])

  const parmas = useMemo(() => {
    if (!inputs) return [[undefined]]
    return inputs.map((x) => {
      if (!x) return [undefined]
      return [x.toString(), currId * DURATION]
    })
  }, [inputs, currId])

  const lockeds = useSingleContractMultipleData(lockContract, 'locked', inputs)

  const balanceOfNFTs = useSingleContractMultipleData(lockContract, 'balanceOfNFT', inputs)

  const isVoteds = useSingleContractMultipleData(voteContract, 'isVoted', parmas)

  const isVotedsloading = useMemo(() => isVoteds.some(({ loading }) => loading), [isVoteds])
  const lockedsloading = useMemo(() => lockeds.some(({ loading }) => loading), [lockeds])
  const balanceOfNFTsloading = useMemo(() => balanceOfNFTs.some(({ loading }) => loading), [balanceOfNFTs])

  return useMemo(() => {
    if (lockedsloading || balanceOfNFTsloading || isVotedsloading) return { loading: true, tokenIdDetails: undefined }

    const data: LockStakeListProps[] = inputs.reduce((pre: any, curr, index) => {
      const { result: locked } = lockeds[index]
      const { result: balanceOfNFT } = balanceOfNFTs[index]
      const { result: isVoted } = isVoteds[index]
      if (!locked || !balanceOfNFT || !velock || !stakeLockToken || !isVoted) return pre
      const voteAmount = CurrencyAmount.fromRawAmount(velock, balanceOfNFT[0])
      const lockAmount = CurrencyAmount.fromRawAmount(stakeLockToken, locked.amount)
      pre.push({
        tokenId: curr.toString(),
        lockAmount,
        voteAmount,
        endTime: locked.end.toString(),
        isVoted: isVoted[0] as boolean,
      })
      return pre
    }, [])

    return {
      loading: false,
      tokenIdDetails: data,
    }
  }, [
    balanceOfNFTs,
    balanceOfNFTsloading,
    inputs,
    isVoteds,
    isVotedsloading,
    lockeds,
    lockedsloading,
    stakeLockToken,
    velock,
  ])
}

export function useLockAmount() {
  const rox = useRoguxCurrency()
  const lock = useLockContract()

  return useTokenBalance(lock?.address, rox)
}

export function useCirLocked() {
  const rox = useRoguxCurrency()
  const totalSupply = useTotalSupply(rox)
  const amount = useLockAmount()
  if (!totalSupply || !amount || totalSupply?.toSignificant() == '0') return 
  return BN(amount?.toSignificant()).div(BN(totalSupply?.toSignificant())).multipliedBy(100)
}

// eslint-disable-next-line import/no-unused-modules
export function useAvgtime() {
  const lockContract = useLockContract()
  const velock = useLockVeToken()
  const amount = useLockAmount()
  const { result: totalSupply } = useSingleCallResult(lockContract, 'totalSupply', [])
  if (!velock || !totalSupply) return null
  const totalSupplyAmouht = CurrencyAmount.fromRawAmount(velock, totalSupply[0])
  if (!totalSupply || !amount) return null
  if (amount?.toSignificant() == '0') return null
  return BN(totalSupplyAmouht?.toSignificant()).div(BN(amount?.toSignificant())).multipliedBy(4)
}

// eslint-disable-next-line import/no-unused-modules
export function useRoxAPR() {
  const rox = useRoguxCurrency()
  const lockContract = useLockContract()
  const { result: totalSupply } = useSingleCallResult(lockContract, 'totalSupply', [])
  const minterContract = useMinter()
  const { result: weekly_emission } = useSingleCallResult(minterContract, 'weekly_emission')
  let pararm: any = '0'
  if (weekly_emission) pararm = weekly_emission[0]
  const { result: growth } = useSingleCallResult(minterContract, 'calculate_growth', [pararm])
  return useMemo(() => {
    if (!rox || !growth || !totalSupply) return null
    if (totalSupply[0] == 0) return 0
    const apr = (((growth[0] * 365) / 7 / totalSupply[0]) * 100) / 2
    return apr
  }, [growth, rox, totalSupply])
}

export function useTVP() {
  const rox = useRoguxCurrency()
  const lockContract = useLockContract()
  const { result: totalSupply } = useSingleCallResult(lockContract, 'totalSupply', [])
  return useMemo(() => {
    if (!rox || !totalSupply) return null
    const totalSupplyAmouht = CurrencyAmount.fromRawAmount(rox, totalSupply[0])
    return totalSupplyAmouht
  }, [rox, totalSupply])
}

export function useLockData() {
  const { tokenIdDetails: lockList } = useLockStakeList()

  const sortMethod = useAtomValue(sortMethodAtom)
  const sortAscending = useAtomValue(sortAscendingAtom)

  return useMemo(() => {
    if (!lockList) return
    let voteArray = lockList
    switch (sortMethod) {
      case LockSortMethod.NFT_ID:
        voteArray = voteArray.sort((a, b) => Number(b.tokenId) - Number(a.tokenId))
        break
      case LockSortMethod.LOCK_AMOUNT:
        voteArray = voteArray.sort(
          (a, b) => Number(b.lockAmount.toSignificant()) - Number(a.lockAmount.toSignificant())
        )
        break
      case LockSortMethod.VOTING_POWER:
        voteArray = voteArray.sort(
          (a, b) => Number(b.voteAmount.toSignificant()) - Number(a.voteAmount.toSignificant())
        )
        break
    }
    return sortAscending ? voteArray.reverse() : voteArray
  }, [lockList, sortAscending, sortMethod])
}
