import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import { ManagerButton, Pending } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { ToastError } from 'components/Toast'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import { useOrderBookContract } from 'hooks/useContract'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useCallback, useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { fromSqrt96Wei, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { handlerError } from 'utils/formatError'

import { ExecuteBtn, LoadedRowProps, StyledBonus } from './LimitRow'
import { LimitSortMethod } from './state'

/* Token Row: skeleton row component */
function LimitRow({
  orders,
  triggerPrice,
  markPrice,
  size,
  bonus,
  action,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  size: ReactNode
  bonus: ReactNode
  triggerPrice: ReactNode
  markPrice: ReactNode
  orders: ReactNode
  action: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="pool-cell">{orders}</TextLeftCell>
        <TextRightCell data-testid="size-cell">{size}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="triggerPrice-cell">{triggerPrice}</TextLeftCell>
        <TextRightCell data-testid="vote-power-cell">{markPrice}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextRightCell data-testid="bonus-cell">{bonus}</TextRightCell>
        <TextRightCell data-testid="action-cell">{action}</TextRightCell>
      </RowBetween>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LimitRow
      $loading
      orders={
        <Row gap="sm">
          <IconLoadingBubble width="48px" height="48px" />
          <SmallLoadingBubble />
        </Row>
      }
      triggerPrice={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LimitSortMethod.TriggerPrice}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      markPrice={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LimitSortMethod.MarkPrice}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      size={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LimitSortMethod.Size}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
          <SmallLoadingBubble />
        </Column>
      }
      bonus={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LimitSortMethod.Bonus}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      action={
        <Row justify="flex-end">
          <ManagerButton>Manager</ManagerButton>
        </Row>
      }
      {...props}
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { limitListIndex, limitListLength, limit, token01 } = props

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [, setTxHash] = useState<string>('')
  const [, setTxError] = useState<string>('')

  const theme = useTheme()
  const currency0 = useCurrency(limit.token0.id)
  const currency1 = useCurrency(limit.token1.id)
  const twapPrice = fromSqrt96Wei(limit.twapPrice.toString(), currency0?.decimals, currency1?.decimals).toNumber()
  const openPrice = fromSqrt96Wei(limit.triggerPrice?.toString(), currency0?.decimals, currency1?.decimals).toNumber()
  const { account } = useActiveChainId()
  const orderbookContract = useOrderBookContract()

  const decimals = limit.long0 ? currency1?.decimals : currency0?.decimals
  const feesymbol = limit.long0 ? currency0?.symbol : currency1?.symbol
  const feesdecimals = limit.long0 ? currency0?.decimals : currency1?.decimals
  const sizeymbol = limit.long0 ? currency1?.symbol : currency0?.symbol

  const addTransaction = useTransactionAdder()

  const handlerIncrease = useCallback(async () => {
    if (!account || !limit.key || !orderbookContract) return
    setAttemptingTxn(true)
    try {
      const response = await orderbookContract.executeIncreaseOrder(limit.key, account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.EXECUTE_ORDERLIST,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('Execute error', error)
    }
  }, [account, addTransaction, limit, orderbookContract])

  const handlerDecrease = useCallback(async () => {
    if (!account || !limit.key || !orderbookContract) return
    setAttemptingTxn(true)

    try {
      const response = await orderbookContract.executeDecreaseOrder(limit.key, account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.EXECUTE_ORDERLIST,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('Execute error', error)
    }
  }, [account, addTransaction, limit.key, orderbookContract])

  const handlerExecute = useCallback(() => {
    limit.__typename == 'IncreaseOrder' ? handlerIncrease() : handlerDecrease()
  }, [handlerDecrease, handlerIncrease, limit.__typename])

  const canExec = useMemo(() => {
    if (!twapPrice) {
      return false
    }
    if (token01) {
      if (limit.triggerAboveThreshold == true) {
        return twapPrice >= openPrice
      } else {
        return twapPrice <= openPrice
      }
    } else {
      if (limit.triggerAboveThreshold == false) {
        return 1 / twapPrice >= 1 / openPrice
      } else {
        return 1 / twapPrice <= 1 / openPrice
      }
    }
  }, [limit.triggerAboveThreshold, openPrice, token01, twapPrice])
  return (
    <>
      <div ref={ref} data-testid={`limit-table-row-${limit?.key}`}>
        <LimitRow
          orders={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LimitSortMethod.Orders}</Trans>
              </ThemedText.TextSecondary>
              <Row>
                <DoubleCurrencyLogo
                  size={40}
                  margin
                  currency0={token01 ? currency1 ?? undefined : currency0 ?? undefined}
                  currency1={token01 ? currency0 ?? undefined : currency1 ?? undefined}
                />
                <Column gap="xs" align="flex-start" ml="8px">
                  <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                    {token01
                      ? `${currency0?.symbol}/${currency1?.symbol}`
                      : `${currency1?.symbol}/${currency0?.symbol}`}
                  </ThemedText.TextPrimary>
                  <Row gap="xs">
                    {token01 ? limit.long0 ? <LongIcon /> : <ShortIcon /> : !limit.long0 ? <LongIcon /> : <ShortIcon />}
                  </Row>
                </Column>
              </Row>
            </Column>
          }
          triggerPrice={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LimitSortMethod.TriggerPrice}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {token01
                  ? limit.triggerAboveThreshold == true
                    ? ' >'
                    : '<'
                  : !limit.triggerAboveThreshold == true
                  ? ' >'
                  : '<'}
                {token01
                  ? countZeros(fromSqrt96Wei(limit.triggerPrice?.toString()).toNumber())
                  : countZeros(1 / fromSqrt96Wei(limit.triggerPrice?.toString()).toNumber())}
              </ThemedText.TextPrimary>
            </Column>
          }
          markPrice={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LimitSortMethod.MarkPrice}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {countZeros(token01 ? twapPrice : 1 / twapPrice)}
              </ThemedText.TextPrimary>
            </Column>
          }
          size={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                {limit.__typename == 'IncreaseOrder' ? 'Increase' : 'Decrease'}
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary>
                {limit.__typename == 'IncreaseOrder' ? '+' : '-'}{' '}
                {countZeros(fromWei(limit.sizeDelta.toString(), decimals).toFixed())} {sizeymbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          bonus={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LimitSortMethod.Bonus}</Trans>
              </ThemedText.TextSecondary>
              <StyledBonus className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}>
                {countZeros(fromWei(limit.executionFee.toString(), feesdecimals).toFixed())} {feesymbol}
              </StyledBonus>
            </Column>
          }
          action={
            <Row justify="end">
              <ExecuteBtn onClick={handlerExecute} disabled={!canExec}>
                <Trans>{attemptingTxn ? <Pending /> : canExec ? 'Execute' : 'Not available'}</Trans>
              </ExecuteBtn>
            </Row>
          }
          first={limitListIndex === 0}
          last={limitListIndex === limitListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
