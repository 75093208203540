import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { useQueryProfileData } from 'hooks/useQueryProfile'
import { GridContainer, NoTokensStateM } from 'pages/Liquidity/Table'
import { AlertTriangle } from 'react-feather'

import { LoadedRow, LoadingRow } from './RewardRow-m'

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function TableM() {
  const profileData = useQueryProfileData()
  const loadingRewards = profileData?.voteRewards.isLoading
  const rewardList = profileData?.voteRewards.data.userVoteds

  /* loading and error state */
  if (loadingRewards && !rewardList) {
    return <LoadingTokenTable rowCount={5} />
  } else if (!rewardList) {
    return (
      <NoTokensStateM
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading rewardList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (rewardList?.length === 0) {
    return <NoTokensStateM message={<Trans>No rewardList found</Trans>} />
  } else {
    return (
      <>
        <TableDataContainer>
          {rewardList.map((reward: any, index: number) => (
            <LoadedRow key={index} rewardListIndex={index} rewardListLength={rewardList.length} reward={reward} />
          ))}
        </TableDataContainer>
      </>
    )
  }
}
