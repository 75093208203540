import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as GagueIcon } from 'assets/imgs/gague.svg'
import Column from 'components/Column'
import LearnMore from 'components/LearnMore'
import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const GagueBox = styled(Box)`
  margin-top: 46px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  .gague-item {
    padding: 60px 16px 54px 16px;
    border-radius: 34px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin-top: 32px;
    grid-template-columns: repeat(1, 1fr);
    .gague-item {
      padding: 32px 16px 24px 16px;
      border-radius: 16px;
    }
  }
`

export default function Gague() {
  return (
    <PageWrapper data-testid="gague-page">
      <TitleRow>
        <ThemedText.LargeHeader>
          <Trans>Gauges</Trans>
        </ThemedText.LargeHeader>
      </TitleRow>
      <TitleDesc>
        <ThemedText.HeaderDesc lineHeight="16px">
          <Trans>Create a new Gauge or add a bribe on existing gauge.</Trans>
        </ThemedText.HeaderDesc>
      </TitleDesc>
      <Row mt="16px">
        <LearnMore />
      </Row>
      <GagueBox>
        <Column className="gague-item">
          <GagueIcon />
          <ThemedText.LargeHeader fontSize={24} mt="50px">
            <Trans>Add Gauges</Trans>
          </ThemedText.LargeHeader>
          <ThemedText.BodySmall color="textTitle" mt="50px">
            <Trans>Create a new gauge which can be used for staking and voting.</Trans>
          </ThemedText.BodySmall>
        </Column>
        <Column className="gague-item">
          <GagueIcon />
          <ThemedText.LargeHeader fontSize={24} mt="50px">
            <Trans>Add Bribes</Trans>
          </ThemedText.LargeHeader>
          <ThemedText.BodySmall color="textTitle" mt="50px">
            <Trans>Add a bribe reward for an existing gauge to incentivize votes on it.</Trans>
          </ThemedText.BodySmall>
        </Column>
      </GagueBox>
    </PageWrapper>
  )
}
