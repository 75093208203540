import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme/components/text'

const BoxCard = styled(Box)<{ isDisabled?: boolean }>`
  background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.backgroundInteractive : 'transparent')};
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  padding: 16px 8px 8px;
  border-radius: 16px;
`

const AmountBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
`
type DATPROS = {
  value: string
  amount?: CurrencyAmount<Currency>
}
interface LockAmountProps {
  isDisabled?: boolean
  tokens?: Token[]
  handleUserInput: (_value: string) => void
  inputAmount: DATPROS
  setInputAmount: (data: DATPROS) => void
}
export default function LockAmount({
  isDisabled,
  tokens,
  inputAmount,
  handleUserInput,
  setInputAmount,
}: LockAmountProps) {
  const handleInputSelect = (currency: Currency) => {
    const amount = CurrencyAmount.fromRawAmount(currency, 0)
    setInputAmount({ value: '', amount })
  }
  return (
    <AmountBox>
      <ThemedText.HeadNav054>
        <Trans>Amounts</Trans>
      </ThemedText.HeadNav054>
      <BoxCard isDisabled={isDisabled}>
        <Box p=" 0 8px" pb="12px">
          <SwapCurrencyInputPanel
            label={<Trans>From</Trans>}
            disabled={isDisabled}
            value={inputAmount.value}
            showMaxButton={false}
            currency={inputAmount?.amount?.currency ?? null}
            id="LockAmount"
            onUserInput={handleUserInput}
            includedTokens={tokens}
            hideBalance={false}
            hideSearchInput={tokens && tokens.length >= 4}
            // onMax={handleMaxInput}
            // fiatValue={{ data: Number(0), isLoading: false }}
            onCurrencySelect={handleInputSelect}
            loading={false}
            onMaxTab={handleUserInput}
            showMaxTab={true}
            isShowBribe={true}
          />
        </Box>
      </BoxCard>
    </AmountBox>
  )
}
