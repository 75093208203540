import { Trans } from '@lingui/macro'
import { formatPrice, NumberType } from '@uniswap/conedison/format'
import { Currency, Price, Token } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { ButtonConfirmed, ButtonGray, ButtonPrimary } from 'components/Button'
import { FarmPoolCard } from 'components/Card'
import Column from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RateToggle2 } from 'components/RateToggle'
import Row, { RowBetween, RowFixed } from 'components/Row'
import { CHAIN_IDS_TO_NAMES, ChainId } from 'constants/chains'
import { isGqlSupportedChain } from 'graphql/data/util'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ExternalLink, ThemedText } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

export const ResponsiveRow = styled(RowBetween)`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%;
  }
`

export const ActionButtonResponsiveRow = styled(ResponsiveRow)`
  width: 100%;
  justify-content: space-between;
  gap: 8px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
    flex-direction: row;
    * {
      width: 100%;
    }
  }
`

export const IncreaseButton = styled(ButtonGray)`
  flex: 1;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  font-weight: 700;
  min-height: 36px;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.connectBtnBackground};
  &:hover {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.connectBtnBackground};
  }
`

export const IncreasePrimaryButton = styled(ButtonGray)<{ active?: boolean }>`
  flex: 1;
  color: ${({ theme, active }) => (active ? theme.white : theme.textSecondary)};
  font-size: 14px;
  font-weight: 700;
  min-height: 36px;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${({ theme, active }) => (active ? theme.increaseBtnBg : 'transparent')};
  border: none;
  &:hover {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.connectBtnBackground};
  }
`

export const CollectButton = styled(ButtonConfirmed)`
  min-height: 36px;
  margin-top: 6px;
  border-radius: 8px;
  padding: 4px 16px;
  width: fit-content;
  font-size: 14px;
  font-weight: 700;
  /* color: ${({ theme }) => theme.textSecondary}; */
`

export const PriceCard = styled(Row)`
  padding: 6px 10px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
`

export const DoubleArrow = styled.span`
  color: ${({ theme }) => theme.textTertiary};
  margin: 0 1rem;
`

export const ResponsiveButtonConfirmed = styled(ButtonConfirmed)`
  border-radius: 12px;
  padding: 6px 8px;
  width: fit-content;
  font-size: 16px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    width: fit-content;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: fit-content;
  }
`

// responsive text
// disable the warning because we don't use the end prop, we just want to filter it out
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Label = styled(({ end, ...props }) => <ThemedText.TextSecondary {...props} />)<{ end?: boolean }>`
  display: flex;
  justify-content: ${({ end }) => (end ? 'flex-end' : 'flex-start')};
  align-items: center;
`

export const BadgeText = styled.div`
  font-size: 16px;
  font-weight: 700;
`

export const PageWrapper = styled.div`
  padding: 68px 16px 16px 16px;

  min-width: 800px;
  max-width: 960px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    min-width: 100%;
    padding: 16px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    min-width: 100%;
    padding: 16px;
  }
`

export const StyledUnclaimedCard = styled(FarmPoolCard)`
  border: 1px solid ${({ theme }) => theme.activeBorder};
`

export const StyledCardTitle = styled(RowBetween)`
  gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
`

const PositionPageButtonPrimary = styled(ButtonPrimary)`
  width: 228px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 12px;
`

const getTokenLink = (chainId: ChainId, address: string) => {
  if (isGqlSupportedChain(chainId)) {
    const chainName = CHAIN_IDS_TO_NAMES[chainId]
    return `${window.location.origin}/#/tokens/${chainName}/${address}`
  } else {
    return getExplorerLink(chainId, address, ExplorerDataType.TOKEN)
  }
}

export function LinkedCurrency({ chainId, currency }: { chainId?: number; currency?: Currency }) {
  const address = (currency as Token)?.address

  if (typeof chainId === 'number' && address) {
    return (
      <ExternalLink href={getTokenLink(chainId, address)}>
        <RowFixed>
          <CurrencyLogo currency={currency} size="30px" style={{ marginRight: '0.5rem' }} />
          <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
            {currency?.symbol} ↗
          </ThemedText.TextPrimary>
        </RowFixed>
      </ExternalLink>
    )
  }

  return (
    <RowFixed>
      <CurrencyLogo currency={currency} size="30px" style={{ marginRight: '0.5rem' }} />
      <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
        {currency?.symbol}
      </ThemedText.TextPrimary>
    </RowFixed>
  )
}

export function getRatio(
  lower: Price<Currency, Currency>,
  current: Price<Currency, Currency>,
  upper: Price<Currency, Currency>
) {
  try {
    if (!current.greaterThan(lower)) {
      return 100
    } else if (!current.lessThan(upper)) {
      return 0
    }

    const a = Number.parseFloat(lower.toSignificant(15))
    const b = Number.parseFloat(upper.toSignificant(15))
    const c = Number.parseFloat(current.toSignificant(15))

    const ratio = Math.floor((1 / ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) * 100)

    if (ratio < 0 || ratio > 100) {
      throw Error('Out of range')
    }

    return ratio
  } catch {
    return undefined
  }
}

export function PositionPageUnsupportedContent() {
  return (
    <PageWrapper>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <ThemedText.HeadlineLarge style={{ marginBottom: '8px' }}>
          <Trans>Position unavailable</Trans>
        </ThemedText.HeadlineLarge>
        <ThemedText.BodyPrimary style={{ marginBottom: '32px' }}>
          <Trans>To view a position, you must be connected to the network it belongs to.</Trans>
        </ThemedText.BodyPrimary>
        <PositionPageButtonPrimary as={Link} to="/pools" width="fit-content">
          <Trans>Back to Pools</Trans>
        </PositionPageButtonPrimary>
      </div>
    </PageWrapper>
  )
}

export function CurrentPriceCard({
  inverted,
  pool,
  currencyQuote,
  currencyBase,
  manuallyInverted,
  setManuallyInverted,
}: {
  inverted?: boolean
  pool?: Pool | null
  currencyQuote?: Currency
  currencyBase?: Currency
  manuallyInverted?: boolean | null
  setManuallyInverted?: any
}) {
  if (!pool || !currencyQuote || !currencyBase) {
    return null
  }

  const tokenA = currencyBase?.wrapped
  const tokenB = currencyQuote?.wrapped
  const isSorted = tokenA && tokenB && tokenA.sortsBefore(tokenB)

  return (
    <Column>
      <Row gap="sm" justify="flex-end">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Price</Trans>
        </ThemedText.TextSecondary>
        <RateToggle2
          currencyA={currencyBase}
          currencyB={currencyQuote}
          handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
        />
      </Row>
      <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
        {formatPrice(inverted ? pool.token1Price : pool.token0Price, NumberType.TokenTx)}{' '}
        {isSorted ? currencyBase?.symbol : currencyQuote?.symbol}
      </ThemedText.TextPrimary>
    </Column>
  )
}

export const useInverter = ({
  priceLower,
  priceUpper,
  quote,
  base,
  invert,
}: {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
  invert?: boolean
}): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} => {
  return {
    priceUpper: invert ? priceLower?.invert() : priceUpper,
    priceLower: invert ? priceUpper?.invert() : priceLower,
    quote: invert ? base : quote,
    base: invert ? quote : base,
  }
}
