import { t } from '@lingui/macro'
import { useMediaQuery } from '@mui/material'
import { SearchInput } from 'components/SearchModal/styleds'
import useDebounce from 'hooks/useDebounce'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useEffect, useState } from 'react'
import { BREAKPOINTS } from 'theme'

import { filterStringAtom } from './state'

export default function SearchBar() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const currentString = useAtomValue(filterStringAtom)

  const [searchQuery, setSearchQuery] = useState(currentString)
  const setFilterString = useUpdateAtom(filterStringAtom)

  const debouncedLocalFilterString = useDebounce(searchQuery, 300)

  useEffect(() => {
    setSearchQuery(currentString)
  }, [currentString])

  useEffect(() => {
    setFilterString(debouncedLocalFilterString)
  }, [debouncedLocalFilterString, setFilterString])
  return (
    <SearchInput
      width={isSmallScreen ? '100%' : 'min(400px, 100%)'}
      data-cy="orderList-search-input"
      type="search"
      placeholder={t`Search`}
      id="search-orderList"
      autoComplete="off"
      value={searchQuery}
      onChange={({ target: { value } }) => setSearchQuery(value)}
    />
  )
}
