import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const TabBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  max-width: 100%;
  height: 40px;
  padding: 2px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  .tabItem {
    flex: 1;
    height: 100%;
    cursor: pointer;
  }
  .tabItemActive {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.tabActiveBg};
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: 100%;
  }
`

interface TabProps {
  tabList: any
  curr: number
  setCurr: any
}

export default function Tab({ tabList, curr, setCurr }: TabProps) {
  return (
    <TabBox>
      {tabList.map((item: any, index: number) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          key={index}
          className={`tabItem ${curr == index && 'tabItemActive'}`}
          onClick={() => setCurr(index)}
        >
          {curr == index ? (
            <ThemedText.TextPrimary fontWeight={700}>
              <Trans>{item}</Trans>
            </ThemedText.TextPrimary>
          ) : (
            <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
              <Trans>{item}</Trans>
            </ThemedText.TextSecondary>
          )}
        </Box>
      ))}
    </TabBox>
  )
}
