import { BigNumber } from 'ethers'

export type TradePositionProps = {
  account: string
  closeSqrtPriceX96: BigNumber
  colToken: BigNumber
  collateral: BigNumber
  delta: BigNumber
  entryFundingFee: BigNumber
  fee: number
  hasProfit: boolean
  liqSqrtPriceX96: BigNumber
  long0: boolean
  positionTime: number
  realisedPnl: BigNumber
  reserve: BigNumber
  size: BigNumber
  sqrtPriceX96: BigNumber
  stopLossRatio: number
  takeProfitRatio: number
  liqResv: BigNumber
  token0: string
  token1: string
  pool: string
  spotPool: string
  closeSpread: number
  fundingFee: BigNumber
}

export type feeProps = {
  close0SqrtPriceX96: BigNumber
  close1SqrtPriceX96: BigNumber
  executionFee: BigNumber
  fundingFee0: BigNumber
  fundingFee1: BigNumber
  liquidity0: BigNumber
  liquidity1: BigNumber
  open0SqrtPriceX96: BigNumber
  open1SqrtPriceX96: BigNumber
  positionFee: BigNumber
  premiumLong0perHour: BigNumber
  premiumLong1perHour: BigNumber
  reserve0: BigNumber
  reserve1: BigNumber
}

export type EstimateDecreaseProps = {
  closeSpread: number
  payBack: BigNumber
  closePrice: BigNumber
}

export enum OrederType {
  Limit = 'Limit',
  trigger = 'Trigger',
}
export type OrederListProps = {
  account: string
  rgPool: string
  token0: string
  token1: string
  collateralIn: BigNumber
  executionFee: BigNumber
  sizeDelta: BigNumber
  long0: boolean
  spotpool: string
  perpPool: string
  key: BigNumber
  triggerAboveThreshold: boolean
  shouldWarp: boolean
  triggerPrice: BigNumber
  collateralDelta: BigNumber
  type: OrederType
}
