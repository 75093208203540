import { useEthPriceUSD, useQueryAllData, useRoxPriceETH } from 'hooks/useQueryLiquidity'
import { useFetchVoter } from 'pages/Vote/hooks'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setEthPrice, setQueryList, setRoxPrice } from './actions'

export default function Updater() {
  const dispatch = useDispatch()

  const QueryData = useQueryAllData()

  const ethPrice = useEthPriceUSD()
  const roxPrice = useRoxPriceETH()

  useFetchVoter()

  useEffect(() => {
    dispatch(setQueryList({ list: QueryData?.data }))
  }, [QueryData?.data, dispatch])

  useEffect(() => {
    dispatch(setEthPrice({ price: ethPrice }))
  }, [dispatch, ethPrice])

  useEffect(() => {
    dispatch(setRoxPrice({ price: roxPrice }))
  }, [dispatch, roxPrice])
  return null
}
