import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { ManagerButton } from 'components/Button'
import Column from 'components/Column'
import { IconLoadingBubble, MediumLoadingBubble } from 'components/Loading'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row from 'components/Row'
import { StyledTableRow } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useGraphTokenList } from 'hooks/useQueryLiquidity'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { TextLeftCell, TextRightCell } from 'pages/Lock/LockRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'
import { useNavigate } from 'react-router'
import { Text } from 'rebass'
import { useHttpEthPrice } from 'state/http/hooks'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { compareAddress } from 'utils'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { TokenSortMethod } from './state'

const StyledAssetRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  grid-template-columns: 240px 1fr 1fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
`
const StyledHeaderRow = styled(StyledAssetRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 0;

  &:hover {
    background-color: transparent;
  }
`

/* Token Row: skeleton row component */
function AssetRow({
  header,
  asset,
  amount,
  manage,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  amount: ReactNode
  manage: ReactNode
  asset: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="asset-cell">{asset}</TextLeftCell>
      <TextLeftCell data-testid="amount-cell">{amount}</TextLeftCell>
      <TextRightCell data-testid="manage-cell">{manage}</TextRightCell>
    </>
  )
  const rowTrCells = (
    <>
      <TextLeftCell data-testid="asset-cell">
        <Box p="16px 0" width="100%" height="100%">
          {asset}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="amount-cell">
        <Box p="16px 0" width="100%" height="100%">
          {amount}
        </Box>
      </TextLeftCell>
      <TextRightCell data-testid="manage-cell">{manage}</TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="asset-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledAssetRow {...rest}>{rowTrCells}</StyledAssetRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <AssetRow
      header={true}
      asset={<Trans>{TokenSortMethod.ASSET}</Trans>}
      amount={<Trans>{TokenSortMethod.AMOUNT}</Trans>}
      manage={<></>}
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <AssetRow
      header={false}
      key={props?.first + '' + props?.last}
      $loading
      asset={
        <Row gap="xmd">
          <IconLoadingBubble width="48px" height="48px" />
          <MediumLoadingBubble />
        </Row>
      }
      amount={
        <Column align="flex-start" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      manage={
        <Row justify="end" gap="sm">
          <ManagerButton>Swap</ManagerButton>
          {/* <ManagerButton>Earn</ManagerButton> */}
        </Row>
      }
    />
  )
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textPrimary};
`

export function Balance({ balance, symbol }: { balance: CurrencyAmount<Currency>; symbol: string }) {
  return (
    <StyledBalanceText title={balance.toExact()}>
      {balance.toSignificant(4)} {symbol}
    </StyledBalanceText>
  )
}

export interface LoadedRowProps {
  assetListIndex: number
  assetListLength: number
  asset: any
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { asset, assetListIndex, assetListLength } = props

  const { account } = useActiveChainId()
  const balance = useCurrencyBalance(account ?? undefined, asset)

  const ethPrice = useHttpEthPrice()

  const tokenList: any = useGraphTokenList()
  const currTokenArr = tokenList?.filter((token: any) => compareAddress(token.id, asset.address))
  const currToken = currTokenArr?.length > 0 ? currTokenArr[0] : {}

  const navigate = useNavigate()
  const handlerSwap = () => {
    currToken.id ? navigate('/swap/' + currToken.id) : navigate('/swap')
  }

  return (
    <>
      <div ref={ref} data-testid={`asset-table-row-${asset.address}`}>
        <AssetRow
          header={false}
          asset={
            <Row gap="xmd">
              <CurrencyLogo currency={asset} size="48px" />
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                {asset.symbol}
              </ThemedText.TextPrimary>
            </Row>
          }
          amount={
            <Column align="flex-start" gap="xs">
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                {balance ? formatCurrencyAmount(balance, 4) : '--'} {asset.symbol}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontWeight={500} fontSize={13}>
                $
                {formatAmount(
                  BN(ethPrice || 0)?.times(
                    BN(currToken.derivedETH ? currToken.derivedETH : 1)?.times(BN(balance?.toSignificant() || 0))
                  ),
                  4,
                  true
                )}
              </ThemedText.TextSecondary>
            </Column>
          }
          manage={
            <Row justify="end" gap="sm">
              <ManagerButton onClick={handlerSwap}>Swap</ManagerButton>
              {/* <ManagerButton>Earn</ManagerButton> */}
            </Row>
          }
          first={assetListIndex === 0}
          last={assetListIndex === assetListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
