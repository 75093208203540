import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import Column from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import dayjs from 'dayjs'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

const StyledInfoBox = styled(Box)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  border-radius: 16px;
  cursor: pointer;
`

const StyledInfoHeader = styled(RowBetween)`
  align-items: center;
  height: 40px;
  padding-left: 14px;
  padding-right: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
`
const StyledInfoWrapper = styled(Row)`
  gap: 36px;
  padding: 12px 14px;
`

interface SelecetedProps {
  nftInfo: any
}
export default function SelectedInfo({ nftInfo }: SelecetedProps) {
  return (
    <StyledInfoBox>
      <StyledInfoHeader>
        <Box display="flex" gap="4px">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>NFT ID:</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>#{nftInfo.tokenId}</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" gap="4px">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>Expires:</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{dayjs(Number(nftInfo.endTime) * 1000).format('YYYY-MM-DD')}</Trans>
          </ThemedText.TextSecondary>
        </Box>
      </StyledInfoHeader>
      <StyledInfoWrapper>
        <Column gap="6px" className="" align="flex-start">
          <ThemedText.TextSecondary fontSize={14}>
            <Trans>Lock Amount</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={20} fontWeight={700}>
            <Trans>{formatCurrencyAmount(nftInfo.lockAmount, 4)} ROX</Trans>
          </ThemedText.TextPrimary>
        </Column>
        <Column gap="6px" className="" align="flex-start">
          <ThemedText.TextSecondary fontSize={14}>
            <Trans>Voting Power</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={20} fontWeight={700}>
            <Trans>{formatCurrencyAmount(nftInfo.voteAmount, 4)} veROX</Trans>
          </ThemedText.TextPrimary>
        </Column>
      </StyledInfoWrapper>
    </StyledInfoBox>
  )
}
