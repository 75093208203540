import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { GridContainer, NoTokensState } from 'pages/Liquidity/Table'

import { HeaderRow, LoadedRow, LoadingRow } from './AdlRow'

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function Table({ token01, resData }: { token01: boolean; resData: any }) {
  // const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)

  /* loading and error state */
  if (!resData) {
    return <LoadingTokenTable rowCount={PAGE_SIZE} />
  } else if (resData.length == 0) {
    return <NoTokensState thRow={<HeaderRow />} message={<Trans>No adlList found</Trans>} />
  } else {
    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {resData?.map((adl: any, index: any) => (
            <LoadedRow token01={token01} key={index} adlListIndex={index} adlListLength={resData.length} adl={adl} />
          ))}
        </TableDataContainer>
      </>
    )
  }
}
