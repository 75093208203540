import { atom, useAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { useCallback } from 'react'

export enum LiquiditySortMethod {
  POOLS = 'Pools',
  TVL = 'TVL',
  APR = 'APR',
  INCENTIVIZATION = 'Incentives',
  MY_STAKED = 'My Staked LPs',
  EARN = 'Earn',
  BRIBES = 'Bribes',
}

export const filterLiquidityStringAtom = atomWithReset<string>('')
export const LiquiditysortMethodAtom = atom<LiquiditySortMethod>(LiquiditySortMethod.TVL)
export const LiquiditysortAscendingAtom = atom<boolean>(false)
export const LiquiditySortPage = atom<number>(1)

export function useSetLiquiditySortMethod(newSortMethod: LiquiditySortMethod) {
  const [sortMethod, setSortMethod] = useAtom(LiquiditysortMethodAtom)
  const [sortAscending, setSortAscending] = useAtom(LiquiditysortAscendingAtom)

  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending(!sortAscending)
    } else {
      setSortMethod(newSortMethod)
      setSortAscending(false)
    }
  }, [sortMethod, setSortMethod, setSortAscending, sortAscending, newSortMethod])
}
