import { useMediaQuery } from '@mui/material'
import { useMemo } from 'react'
import { BREAKPOINTS } from 'theme'

import { useALDandLiqData } from '../hooks'
import Table from './LiquidationTable'
import TableM from './LiquidationTable-m'

export function LiquidationList({ token01 }: { token01: boolean }) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const data = useALDandLiqData()

  const resData = useMemo(() => {
    if (!data) return null
    if (token01) {
      return (
        data?.sort((a, b) => {
          let priceDifferenceA, priceDifferenceB
          if (!a.long0) {
            priceDifferenceA = Number(a.twapPrice) - Number(a.close0Price)
          } else {
            priceDifferenceA = Number(a.close0Price) - Number(a.twapPrice)
          }
          if (!b.long0) {
            priceDifferenceB = Number(b.twapPrice) - Number(b.close0Price)
          } else {
            priceDifferenceB = Number(b.close0Price) - Number(b.twapPrice)
          }
          return priceDifferenceB - priceDifferenceA
        }) || null
      )
    } else {
      return (
        data?.sort((a, b) => {
          let priceDifferenceA, priceDifferenceB
          if (!a.long0) {
            priceDifferenceA = 1 / Number(a.twapPrice) - 1 / Number(a.close0Price)
          } else {
            priceDifferenceA = 1 / Number(a.close0Price) - 1 / Number(a.twapPrice)
          }
          if (!b.long0) {
            priceDifferenceB = 1 / Number(b.twapPrice) - 1 / Number(b.close0Price)
          } else {
            priceDifferenceB = 1 / Number(b.close0Price) - 1 / Number(b.twapPrice)
          }
          return priceDifferenceA - priceDifferenceB
        }) || null
      )
    }
  }, [data, token01])
  return (
    <>
      {isSmallScreen ? <TableM token01={token01} resData={resData} /> : <Table token01={token01} resData={resData} />}
    </>
  )
}
