import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ManagerButton } from 'components/Button'
import Column from 'components/Column'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import dayjs from 'dayjs'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { ReactComponent as NftIcon } from '../../assets/imgs/nft.svg'
import HeaderCell from './HeaderCell'
import ManageModal from './LockModal/ManageModal'
import { LockSortMethod } from './state'
import { LockStakeListProps } from './types'

const StyledLockRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  grid-template-columns: 2fr 3fr 3fr 3fr 2fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
`

const StyledTrRow = styled(StyledLockRow)``
const StyledHeaderRow = styled(StyledLockRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 12px 0;

  &:hover {
    background-color: transparent;
  }
`

const DataCell = styled(Cell)<{ sortable?: boolean }>`
  padding: 0 8px;
  user-select: ${({ sortable }) => (sortable ? 'none' : 'unset')};
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
`

export const TextLeftCell = styled(DataCell)<{ sortable?: boolean }>`
  justify-content: flex-start;
`

export const TextRightCell = styled(DataCell)<{ sortable?: boolean }>`
  justify-content: flex-end;
  text-align: right;
`

const NFTCell = styled(TextLeftCell)<{ header: boolean }>``

/* Token Row: skeleton row component */
function LockRow({
  header,
  poolInfo,
  amount,
  votePower,
  expires,
  manager,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  expires: ReactNode
  amount: ReactNode
  votePower: ReactNode
  poolInfo: ReactNode
  manager: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <NFTCell data-testid="pool-cell" header={header}>
        {poolInfo}
      </NFTCell>
      <TextLeftCell data-testid="amount-cell" sortable={header}>
        {amount}
      </TextLeftCell>
      <TextLeftCell data-testid="vote-power-cell">{votePower}</TextLeftCell>
      <TextRightCell data-testid="expires-cell">{expires}</TextRightCell>
      <TextRightCell data-testid="manager-cell">{manager}</TextRightCell>
    </>
  )
  const rowTrCells = (
    <>
      <NFTCell data-testid="pool-cell" header={header}>
        <Box display="flex" alignItems="center" p="16px 0" width="100%" height="100%">
          {poolInfo}
        </Box>
      </NFTCell>
      <TextLeftCell data-testid="amount-cell" sortable={header}>
        <Box display="flex" alignItems="center" p="16px 0" width="100%" height="100%">
          {amount}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="vote-power-cell">
        <Box display="flex" alignItems="center" p="16px 0" width="100%" height="100%">
          {votePower}
        </Box>
      </TextLeftCell>
      <TextRightCell data-testid="expires-cell">
        <Box display="flex" alignItems="center" p="16px 0" width="100%" height="100%">
          {expires}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="manager-cell">
        <Box display="flex" alignItems="center" p="16px 0" width="100%" height="100%">
          {manager}
        </Box>
      </TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="lock-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledTrRow {...rest}>{rowTrCells}</StyledTrRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LockRow
      header={true}
      poolInfo={<HeaderCell category={LockSortMethod.NFT_ID} justify="flex-start" />}
      amount={
        <Row justify="flex-start">
          <HeaderCell category={LockSortMethod.LOCK_AMOUNT} justify="flex-start" />
        </Row>
      }
      votePower={<HeaderCell category={LockSortMethod.VOTING_POWER} justify="flex-start" />}
      expires={
        <Row justify="end">
          <Trans>{LockSortMethod.EXPIRES}</Trans>
        </Row>
      }
      manager={
        <Row justify="end">
          <Trans>{LockSortMethod.EARN}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LockRow
      header={false}
      $loading
      poolInfo={
        <Row gap="xmd">
          <IconLoadingBubble width="48px" height="48px" />
          <MediumLoadingBubble />
        </Row>
      }
      amount={<MediumLoadingBubble />}
      votePower={<LoadingBubble />}
      expires={
        <Row justify="end">
          <LoadingBubble />
        </Row>
      }
      manager={
        <Row justify="end">
          <ManagerButton>Manager</ManagerButton>
        </Row>
      }
      {...props}
    />
  )
}

interface LoadedRowProps {
  lockListIndex: number
  lockListLength: number
  lock: LockStakeListProps
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { lock, lockListIndex, lockListLength } = props
  const [tokenId, setTokenId] = useState<any>('')
  const [endTime, setEndTime] = useState<any>('')
  const [veAmount, setVeAmount] = useState<any>('')
  const [showManage, setShowManage] = useState<boolean>(false)
  const handleDismissManage = () => {
    setShowManage(false)
  }
  const handlerManage = (lock: any) => {
    setTokenId(lock.tokenId)
    setEndTime(lock.endTime)
    setVeAmount(lock.voteAmount)
    setShowManage(true)
  }

  return (
    <>
      <div ref={ref} data-testid={`lock-table-row-${lock?.tokenId}`}>
        <LockRow
          header={false}
          poolInfo={
            <Row gap="xmd">
              <NftIcon width={48} height={48} />
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                #{lock.tokenId}
              </ThemedText.TextPrimary>
            </Row>
          }
          amount={
            <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
              {formatCurrencyAmount(lock.lockAmount, 4)} ROX
            </ThemedText.TextPrimary>
          }
          votePower={
            <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
              {formatCurrencyAmount(lock.voteAmount, 4)} {lock.voteAmount?.currency.symbol}
            </ThemedText.TextPrimary>
          }
          expires={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {dayjs(Number(lock.endTime) * 1000).format('YYYY-MM-DD')}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>
                  Expired in{' '}
                  {formatAmount(
                    Math.round((Number(lock.endTime) - Math.round(Number(new Date()) / 1000)) / 86400),
                    0,
                    true
                  )}{' '}
                  Days
                </Trans>
              </ThemedText.TextSecondary>
            </Column>
          }
          manager={
            <Row justify="end">
              <ManagerButton onClick={() => handlerManage(lock)}>Manager</ManagerButton>
            </Row>
          }
          first={lockListIndex === 0}
          last={lockListIndex === lockListLength - 1}
        />
      </div>
      {showManage && (
        <ManageModal
          isOpen={showManage}
          onDismiss={handleDismissManage}
          tokenId={tokenId}
          endTime={endTime}
          veAmount={veAmount}
          lockAmount={lock.lockAmount}
        />
      )}
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
