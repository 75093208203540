import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { BrowserEvent, InterfaceElementName, InterfaceEventName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { FeeAmount, NonfungiblePositionManager } from '@uniswap/v3-sdk'
import { TraceEvent } from 'analytics'
import { sendEvent } from 'components/analytics'
import { ButtonError, ButtonLight, ButtonPrimary, StyledPendingIcon } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Row, { RowBetween } from 'components/Row'
import { useActiveChainId } from 'connection/useActiveChainId'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from 'constants/addresses'
import { ZERO_PERCENT } from 'constants/misc'
import { BigNumber } from 'ethers/lib'
import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useArgentWalletContract } from 'hooks/useArgentWalletContract'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import { useDerivedPositionInfo } from 'hooks/useDerivedPositionInfo'
import { useIsSwapUnsupported } from 'hooks/useIsSwapUnsupported'
import { useStablecoinValue } from 'hooks/useStablecoinPrice'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import { Review } from 'pages/AddLiquidity/Review'
import { useMemo } from 'react'
import { useCallback } from 'react'
import { Text } from 'rebass'
import { Bound, Field } from 'state/mint/v3/actions'
import { useV3DerivedMintInfo, useV3MintActionHandlers, useV3MintState } from 'state/mint/v3/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { useUserSlippageToleranceWithDefault } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme/components/text'
import approveAmountCalldata from 'utils/approveAmountCalldata'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { currencyId } from 'utils/currencyId'
import { handlerError } from 'utils/formatError'
import { maxAmountSpend } from 'utils/maxAmountSpend'

import { Dots } from '../styleds'

const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)
const IncreasePoolBox = styled(Box)`
  padding: 16px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 0px 16px 16px 16px;
`

const BGCurrencyInputPanel = styled(CurrencyInputPanel)`
  background-color: ${({ theme }) => theme.backgroundModule};
`

export default function IncreasePool({
  currencyIdA,
  currencyIdB,
  tokenId,
  feeAmountFromUrl,
  setshowIncreasePool,
  setTxHash,
  setAttemptingTxn,
  setTxError,
}: {
  currencyIdA: string
  currencyIdB: string
  tokenId: number
  feeAmountFromUrl: string
  setTxHash: any
  setAttemptingTxn: any
  setTxError: any
  setshowIncreasePool?: any
}) {
  const { account, chainId, provider } = useActiveChainId()

  // check for existing position if tokenId in url
  const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
    tokenId ? BigNumber.from(tokenId) : undefined
  )
  const hasExistingPosition = !!existingPositionDetails && !positionLoading
  const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails)

  const baseCurrency = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  // fee selection from url
  const feeAmount: FeeAmount | undefined =
    feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
      ? parseFloat(feeAmountFromUrl)
      : undefined

  const {
    dependentField,
    pricesAtTicks,
    parsedAmounts,
    currencyBalances,
    position,
    noLiquidity,
    currencies,
    errorMessage,
    invalidRange,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    ticksAtLimit,
  } = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    baseCurrency ?? undefined,
    existingPosition
  )

  const isValid = !errorMessage && !invalidRange

  const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks
  const positionManager = useV3NFTPositionManagerContract()
  const addTransaction = useTransactionAdder()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers(noLiquidity)

  // mint state
  const { independentField, typedValue } = useV3MintState()

  const argentWalletContract = useArgentWalletContract()

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings

  const [approvalA, approveACallback] = useApproveCallback(
    argentWalletContract ? undefined : parsedAmounts[Field.CURRENCY_A],
    chainId ? NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId] : undefined
  )

  const [approvalB, approveBCallback] = useApproveCallback(
    argentWalletContract ? undefined : parsedAmounts[Field.CURRENCY_B],
    chainId ? NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId] : undefined
  )

  const showApprovalA =
    !argentWalletContract && approvalA !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_A]
  const showApprovalB =
    !argentWalletContract && approvalB !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_B]

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const usdcValues = {
    [Field.CURRENCY_A]: useStablecoinValue(parsedAmounts[Field.CURRENCY_A]),
    [Field.CURRENCY_B]: useStablecoinValue(parsedAmounts[Field.CURRENCY_B]),
  }

  const usdcValueCurrencyA = usdcValues[Field.CURRENCY_A]
  const usdcValueCurrencyB = usdcValues[Field.CURRENCY_B]

  const currencyAFiat = useMemo(
    () => ({
      data: usdcValueCurrencyA ? parseFloat(usdcValueCurrencyA.toSignificant()) : undefined,
      isLoading: false,
    }),
    [usdcValueCurrencyA]
  )
  const currencyBFiat = useMemo(
    () => ({
      data: usdcValueCurrencyB ? parseFloat(usdcValueCurrencyB.toSignificant()) : undefined,
      isLoading: false,
    }),
    [usdcValueCurrencyB]
  )

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const allowedSlippage = useUserSlippageToleranceWithDefault(
    outOfRange ? ZERO_PERCENT : DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )
  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const onAdd = useCallback(
    async function onAdd() {
      if (!chainId || !provider || !account) return

      if (!positionManager || !baseCurrency || !quoteCurrency) {
        return
      }

      if (position && account && deadline) {
        const useNative = baseCurrency.isNative ? baseCurrency : quoteCurrency.isNative ? quoteCurrency : undefined
        const { calldata, value } =
          hasExistingPosition && tokenId
            ? NonfungiblePositionManager.addCallParameters(position, {
                tokenId,
                slippageTolerance: allowedSlippage,
                deadline: deadline.toString(),
                useNative,
              })
            : NonfungiblePositionManager.addCallParameters(position, {
                slippageTolerance: allowedSlippage,
                recipient: account,
                deadline: deadline.toString(),
                useNative,
                createPool: noLiquidity,
              })

        let txn: { to: string; data: string; value: string } = {
          to: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId],
          data: calldata,
          value,
        }

        if (argentWalletContract) {
          const amountA = parsedAmounts[Field.CURRENCY_A]
          const amountB = parsedAmounts[Field.CURRENCY_B]
          const batch = [
            ...(amountA && amountA.currency.isToken
              ? [approveAmountCalldata(amountA, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId])]
              : []),
            ...(amountB && amountB.currency.isToken
              ? [approveAmountCalldata(amountB, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId])]
              : []),
            {
              to: txn.to,
              data: txn.data,
              value: txn.value,
            },
          ]
          const data = argentWalletContract.interface.encodeFunctionData('wc_multiCall', [batch])
          txn = {
            to: argentWalletContract.address,
            data,
            value: '0x0',
          }
        }

        setAttemptingTxn(true)

        provider
          .getSigner()
          .estimateGas(txn)
          .then((estimate) => {
            const newTxn = {
              ...txn,
              gasLimit: calculateGasMargin(estimate) || 4000000,
            }

            return provider
              .getSigner()
              .sendTransaction(newTxn)
              .then((response: TransactionResponse) => {
                // setAttemptingTxn(false)
                addTransaction(response, {
                  type: TransactionType.ADD_LIQUIDITY_V3_POOL,
                  baseCurrencyId: currencyId(baseCurrency),
                  quoteCurrencyId: currencyId(quoteCurrency),
                  createPool: Boolean(noLiquidity),
                  expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient?.toString() ?? '0',
                  expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient?.toString() ?? '0',
                  feeAmount: position.pool.fee,
                })
                setTxHash(response.hash)
                sendEvent({
                  category: 'Liquidity',
                  action: 'Add',
                  label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
                })
              })
          })
          .catch((error) => {
            setAttemptingTxn(false)
            setTxError(handlerError(error))
            console.error('Failed to send transaction', error)
            // we only care if the error is something _other_ than the user rejected the tx
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      } else {
        return
      }
    },
    [
      account,
      addTransaction,
      allowedSlippage,
      argentWalletContract,
      baseCurrency,
      chainId,
      currencies,
      deadline,
      hasExistingPosition,
      noLiquidity,
      parsedAmounts,
      position,
      positionManager,
      provider,
      quoteCurrency,
      setAttemptingTxn,
      setTxError,
      setTxHash,
      tokenId,
    ]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Prview = (
    <>
      <Review
        parsedAmounts={parsedAmounts}
        position={position}
        existingPosition={existingPosition}
        priceLower={priceLower}
        priceUpper={priceUpper}
        outOfRange={outOfRange}
        ticksAtLimit={ticksAtLimit}
      />
      <ButtonPrimary style={{ marginTop: '1rem' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          <Trans>Add</Trans>
        </Text>
      </ButtonPrimary>
    </>
  )

  // const toggleWalletDrawer = useToggleAccountDrawer()
  const { connectModalOpen: walletDrawerOpen, openConnectModal } = useConnectModal()
  const toggleWalletDrawer = () => openConnectModal && openConnectModal()

  const showReview = useCallback(() => {
    setshowIncreasePool(Prview)
  }, [Prview, setshowIncreasePool])

  const Buttons = useCallback(
    () =>
      addIsUnsupported ? (
        <ButtonPrimary disabled={true} $borderRadius="12px" padding="12px">
          <ThemedText.DeprecatedMain mb="4px">
            <Trans>Unsupported Asset</Trans>
          </ThemedText.DeprecatedMain>
        </ButtonPrimary>
      ) : !account ? (
        <TraceEvent
          events={[BrowserEvent.onClick]}
          name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
          properties={{ received_swap_quote: false }}
          element={InterfaceElementName.CONNECT_WALLET_BUTTON}
        >
          <ButtonLight onClick={toggleWalletDrawer} $borderRadius="12px" padding="12px">
            <Trans>Connect Wallet</Trans>
          </ButtonLight>
        </TraceEvent>
      ) : (
        <AutoColumn gap="md">
          {(approvalA === ApprovalState.NOT_APPROVED ||
            approvalA === ApprovalState.PENDING ||
            approvalB === ApprovalState.NOT_APPROVED ||
            approvalB === ApprovalState.PENDING) &&
            isValid && (
              <RowBetween>
                {showApprovalA && (
                  <ButtonPrimary
                    onClick={approveACallback}
                    disabled={approvalA === ApprovalState.PENDING}
                    width={showApprovalB ? '48%' : '100%'}
                  >
                    {approvalA === ApprovalState.PENDING ? (
                      <Dots>
                        <Trans>Approving {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                        <StyledPendingIcon />
                      </Dots>
                    ) : (
                      <Trans>Approve {currencies[Field.CURRENCY_A]?.symbol}</Trans>
                    )}
                  </ButtonPrimary>
                )}
                {showApprovalB && (
                  <ButtonPrimary
                    onClick={approveBCallback}
                    disabled={approvalB === ApprovalState.PENDING}
                    width={showApprovalA ? '48%' : '100%'}
                  >
                    {approvalB === ApprovalState.PENDING ? (
                      <Dots>
                        <Trans>Approving {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                        <StyledPendingIcon />
                      </Dots>
                    ) : (
                      <Trans>Approve {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                    )}
                  </ButtonPrimary>
                )}
              </RowBetween>
            )}
          <ButtonError
            onClick={showReview}
            disabled={
              !isValid ||
              (!argentWalletContract && approvalA !== ApprovalState.APPROVED && !depositADisabled) ||
              (!argentWalletContract && approvalB !== ApprovalState.APPROVED && !depositBDisabled)
            }
            error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
          >
            <Text fontWeight={500}>{errorMessage ? errorMessage : <Trans>Preview</Trans>}</Text>
          </ButtonError>
        </AutoColumn>
      ),
    [
      account,
      addIsUnsupported,
      approvalA,
      approvalB,
      approveACallback,
      approveBCallback,
      argentWalletContract,
      currencies,
      depositADisabled,
      depositBDisabled,
      errorMessage,
      isValid,
      parsedAmounts,
      showApprovalA,
      showApprovalB,
      showReview,
      toggleWalletDrawer,
    ]
  )

  return (
    <IncreasePoolBox>
      <Row align="stretch">
        <RowBetween gap="12px">
          <Box flex={1}>
            <BGCurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A] ?? null}
              id="add-liquidity-input-tokena"
              fiatValue={currencyAFiat}
              showCommonBases
              locked={depositADisabled}
            />
          </Box>
          <Box flex={1}>
            <BGCurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              fiatValue={currencyBFiat}
              currency={currencies[Field.CURRENCY_B] ?? null}
              id="add-liquidity-input-tokenb"
              showCommonBases
              locked={depositBDisabled}
            />
          </Box>
        </RowBetween>
      </Row>
      <Box mt="16px">{Buttons()}</Box>
    </IncreasePoolBox>
  )
}
