import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import Row from 'components/Row'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const StyledTimeCard = styled(Box)`
  width: 100%;
  display: grid;
  gap: 6px;
  grid-template-columns: repeat(4, 1fr);
  .timeItem {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.swapSectionBorder};
    padding: 10px 8px;
    border-radius: 8px;
    cursor: pointer;
  }
  .timeItemActive {
    border: 1px solid ${({ theme }) => theme.activeBorder};
  }
`
interface TimeProps {
  setWeeks: any
}
export default function TimeCard({ setWeeks }: TimeProps) {
  const timeList = [
    {
      desc: '1 Weeks',
      val: 1,
    },
    {
      desc: '2 Weeks',
      val: 2,
    },
    {
      desc: '1 Months',
      val: 4,
    },
    {
      desc: '3 Months',
      val: 13,
    },
    {
      desc: '6 Months',
      val: 26,
    },
    {
      desc: '1 Years',
      val: 52,
    },
    {
      desc: '2 Years',
      val: 104,
    },
    {
      desc: '4 Years',
      val: 208,
    },
  ]
  const [curr, setCurr] = useState<any>(0)

  const tabChange = (i: number, tab: { desc?: string; val: any }) => {
    setCurr(i)
    setWeeks(tab.val)
  }
  return (
    <StyledTimeCard>
      {timeList?.map((item, i) => (
        <Row
          className={`timeItem ${curr == i && 'timeItemActive'}`}
          justify="center"
          key={i}
          onClick={() => tabChange(i, item)}
        >
          {curr == i ? (
            <ThemedText.TextPrimary fontWeight={700}>
              <Trans>{item?.desc}</Trans>
            </ThemedText.TextPrimary>
          ) : (
            <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
              <Trans>{item?.desc}</Trans>
            </ThemedText.TextSecondary>
          )}
        </Row>
      ))}
    </StyledTimeCard>
  )
}
