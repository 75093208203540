import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { GridContainer, NoTokensState } from 'pages/Liquidity/Table'
import { VoteDataProps } from 'pages/Vote/types'
import { AlertTriangle } from 'react-feather'

import { useLeaderboardData } from './Hooks'
import { HeaderRow, LoadedRow, LoadingRow } from './LeaderboardRow'

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function Table() {
  const loadingLeaderboards = true
  const leaderboardList = useLeaderboardData()

  /* loading and error state */
  if (loadingLeaderboards && !leaderboardList) {
    return <LoadingTokenTable rowCount={4} />
  } else if (!leaderboardList) {
    return (
      <NoTokensState
        thRow={<HeaderRow />}
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading leaderboardList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (leaderboardList?.length === 0) {
    return <NoTokensState thRow={<HeaderRow />} message={<Trans>No leaderboardList found</Trans>} />
  } else {
    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {leaderboardList?.map((leaderboard: VoteDataProps, index: number) => (
            <LoadedRow
              key={index}
              leaderboardListIndex={index}
              leaderboardListLength={leaderboardList?.length || 0}
              leaderboard={leaderboard}
            />
          ))}
        </TableDataContainer>
      </>
    )
  }
}
