import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import Column from 'components/Column'
import { StyledDropDown } from 'components/Icons/StyledIcon'
import { NavDropdown } from 'components/NavBar/NavDropdown'
import Row from 'components/Row'
import dayjs from 'dayjs'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { EpochList } from 'pages/Leaderboard/EpochDropdown'
import { VeRoxSelect } from 'pages/Vote/HeaderWrapper'
import { useRef } from 'react'
import { ThemedText } from 'theme'

export default function RoundEpoch({ isOpen, setIsOpen, epoch, setEpoch, roundList }: any) {
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const epochNode = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(epochNode, isOpen ? toggleOpen : undefined)

  return (
    <div style={{ position: 'relative' }} ref={epochNode}>
      <VeRoxSelect
        onClick={() => {
          toggleOpen()
        }}
      >
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>{epoch ? 'Epoch' + epoch : 'All'}</Trans>
        </ThemedText.TextSecondary>
        <StyledDropDown />
      </VeRoxSelect>
      {isOpen && (
        <NavDropdown right="0">
          <EpochList>
            <Row className="epochHeader">
              <Box width="80px">
                <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                  Epoch
                </ThemedText.TextSecondary>
              </Box>
              <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                Date
              </ThemedText.TextSecondary>
            </Row>
            <Column gap="6px" width="100%" style={{ paddingTop: '8px' }}>
              {roundList?.map((item: any, index: number) => (
                <Row
                  key={index}
                  className="epochItem"
                  onClick={() => {
                    setEpoch(item.round), toggleOpen()
                  }}
                >
                  <Box width="80px">
                    <ThemedText.TextPrimary>{item.round}</ThemedText.TextPrimary>
                  </Box>
                  {index > 0 ? (
                    <ThemedText.TextSecondary fontSize={14}>
                      {dayjs(item.startTime * 1000).format('YYYY/MM/DD')}-
                      {item.endTime ? dayjs(item.endTime * 1000).format('YYYY/MM/DD') : ' '}
                    </ThemedText.TextSecondary>
                  ) : (
                    <ThemedText.TextSecondary fontSize={14}>All</ThemedText.TextSecondary>
                  )}
                </Row>
              ))}
            </Column>
          </EpochList>
        </NavDropdown>
      )}
    </div>
  )
}
