import { Trans } from '@lingui/macro'
import Column from 'components/Column'
import { RowBetween } from 'components/Row'
import Tooltip from 'components/Tooltip'
import { TooltipBox, TooltipRow } from 'pages/Liquidity/LiquidityRow'
import { useState } from 'react'
import { useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { countZeros } from 'utils/countZeros'

export function PositionNetValue({
  token,
  initialCollateral,
  netValueFees,
}: {
  token?: string
  initialCollateral?: string
  netValueFees?: {
    borrowFee: string
    closeSpread: string
    allFee: string
    PnL: string
    Positionfees: string
    netValue: string | number
    currentCollateral: string
    PnLAfterFees: string
    AfterHasProfiet?: boolean
  }
}) {
  const [showHover_NetValue, setShowHover_NetValue] = useState(false)
  const theme = useTheme()
  return (
    <Tooltip
      width="380px"
      show={showHover_NetValue}
      text={
        <TooltipBox>
          <RowBetween alignItems="flex-start" className="tooltipItem">
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>Initial Collateral</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700}>
              {countZeros(initialCollateral)}&nbsp;{token}
            </ThemedText.TextPrimary>
          </RowBetween>
          <RowBetween alignItems="flex-start" className="tooltipItem">
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>PnL</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} textAlign="end" flex={1}>
              {countZeros(netValueFees?.PnL)}&nbsp;{token}
            </ThemedText.TextPrimary>
          </RowBetween>
          <Column gap="1px" className="tooltipItem">
            <RowBetween alignItems="flex-start" className="">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Fees</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                -{countZeros(netValueFees?.allFee)}&nbsp;{token}
              </ThemedText.TextPrimary>
            </RowBetween>
            <RowBetween alignItems="flex-start" className="">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>- Borrow Fee</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={12}>
                -{countZeros(netValueFees?.borrowFee)}&nbsp;{token}
              </ThemedText.TextSecondary>
            </RowBetween>
            <RowBetween alignItems="flex-start" className="">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>- Open Fee</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={12}>
                -{countZeros(netValueFees?.Positionfees)}&nbsp;{token}
              </ThemedText.TextSecondary>
            </RowBetween>
            <RowBetween alignItems="flex-start" className="">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>- Close Fee</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={12}>
                -{countZeros(netValueFees?.Positionfees)}&nbsp;{token}
              </ThemedText.TextSecondary>
            </RowBetween>
          </Column>
          <RowBetween alignItems="flex-start" className="tooltipItem">
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>Net Pnl</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary color={netValueFees?.AfterHasProfiet ? 'long' : 'tradeRed'} fontWeight={700}>
              {countZeros(netValueFees?.PnLAfterFees)}&nbsp;{token}
            </ThemedText.TextPrimary>
          </RowBetween>
          <div className="tooltipItem">
            <ThemedText.TextSecondary fontSize={12}>
              *Net Value: Initial Collateral + PnL After Fees
            </ThemedText.TextSecondary>
          </div>
        </TooltipBox>
      }
    >
      <TooltipRow onMouseEnter={() => setShowHover_NetValue(true)} onMouseLeave={() => setShowHover_NetValue(false)}>
        <ThemedText.TextPrimary
          style={{ borderBottom: ` 1px dashed ${theme.textSecondary}` }}
          fontWeight={700}
          fontSize={12}
        >
          {/* {countZeros(initialCollateral)}&nbsp;{token} */}
          {countZeros(netValueFees?.netValue.toString())}&nbsp;{token}
        </ThemedText.TextPrimary>
      </TooltipRow>
    </Tooltip>
  )
}
