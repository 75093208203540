import { useRoxUtils } from "hooks/useContract";
import { useSingleCallResult } from "lib/hooks/multicall";
import { useMemo } from "react";

export function usePerpInfoValue(spotPoolAddr: any) {
    const utilsContract = useRoxUtils()
    const { result: perpInfo,error } = useSingleCallResult(utilsContract, 'poolSetting', [spotPoolAddr ?? undefined])
    return useMemo(() => {
        if (!perpInfo) return
        if (!perpInfo.set) return
        return perpInfo
    }, [perpInfo])
}