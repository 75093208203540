import { Trans } from '@lingui/macro'
import { CurrencyAmount } from '@uniswap/sdk-core'
import Column from 'components/Column'
import { MediumLoadingBubble } from 'components/Loading'
import { RowBetween } from 'components/Row'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils/addresses'
import { formatAmount } from 'utils/formatAmout'

import { LoadedRowProps } from './DetailRow'
import { TokenSortMethod } from './state'

/* Token Row: skeleton row component */
function LeaderboardRow({
  rank,
  trader,
  swapVolume,
  preVolume,
  totalVolume,
  Rewards,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  preVolume: ReactNode
  trader: ReactNode
  swapVolume: ReactNode
  rank: ReactNode
  totalVolume: ReactNode
  Rewards: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="rank-cell">{rank}</TextLeftCell>
        <TextRightCell data-testid="trader-cell">{trader}</TextRightCell>
      </RowBetween>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="swap-volume-cell">{swapVolume}</TextLeftCell>
        <TextRightCell data-testid="pre-volume-cell">{preVolume}</TextRightCell>
      </RowBetween>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="total-volume-cell">{totalVolume}</TextLeftCell>
        <TextRightCell data-testid="earn-cell">{Rewards}</TextRightCell>
      </RowBetween>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LeaderboardRow
      $loading
      rank={
        <Column gap="xs" width="100%" align="flex-start">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{TokenSortMethod.RANK}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      trader={
        <Column gap="xs" width="100%" align="flex-end">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{TokenSortMethod.TRADER}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      swapVolume={
        <Column gap="xs" width="100%" align="flex-start">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{TokenSortMethod.SWAP_VOLUME}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      preVolume={
        <Column gap="xs" width="100%" align="flex-end">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{TokenSortMethod.PRE_VOLUME}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      totalVolume={
        <Column gap="xs" width="100%" align="flex-start">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{TokenSortMethod.TOTAL_VOLUME}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      Rewards={
        <Column gap="xs" width="100%" align="flex-end">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{TokenSortMethod.REWARDS}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      {...props}
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { leaderboardListIndex, leaderboardListLength, leaderboard, token } = props

  const swapVolume = useMemo(() => {
    if (!token) return
    return CurrencyAmount.fromRawAmount(token, leaderboard.swapVolume)
  }, [leaderboard.swapVolume, token])

  const perpVolume = useMemo(() => {
    if (!token) return
    return CurrencyAmount.fromRawAmount(token, leaderboard.perpVolume)
  }, [leaderboard.perpVolume, token])

  const totalVolume = useMemo(() => {
    if (!token) return
    return CurrencyAmount.fromRawAmount(token, leaderboard.totalValume)
  }, [leaderboard.totalValume, token])

  return (
    <div ref={ref} data-testid={`leaderboard-detail-row-${leaderboard.userAddress}`}>
      <LeaderboardRow
        rank={
          <Column gap="xs" width="100%" align="flex-start">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>{TokenSortMethod.RANK}</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextSecondary fontSize={14} fontWeight={500}>
              #{leaderboardListIndex + 1}
            </ThemedText.TextSecondary>
          </Column>
        }
        trader={
          <Column gap="xs" width="100%" align="flex-end">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>{TokenSortMethod.TRADER}</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextSecondary fontWeight={500} fontSize={14} style={{ textDecoration: 'underLine' }}>
              {shortenAddress(leaderboard.userAddress)}
            </ThemedText.TextSecondary>
          </Column>
        }
        swapVolume={
          <Column gap="xs" width="100%" align="flex-start">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>{TokenSortMethod.SWAP_VOLUME}</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={500} fontSize={14}>
              {formatAmount(swapVolume?.toSignificant(), 2, true)} {token?.symbol}
            </ThemedText.TextPrimary>
          </Column>
        }
        preVolume={
          <Column gap="xs" width="100%" align="flex-end">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>{TokenSortMethod.PRE_VOLUME}</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={500} fontSize={14}>
              {formatAmount(perpVolume?.toSignificant(), 2, true)} {token?.symbol}
            </ThemedText.TextPrimary>
          </Column>
        }
        totalVolume={
          <Column gap="xs" width="100%" align="flex-start">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>{TokenSortMethod.TOTAL_VOLUME}</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={500} fontSize={14}>
              {formatAmount(totalVolume?.toSignificant(), 2, true)} {token?.symbol}
            </ThemedText.TextPrimary>
          </Column>
        }
        Rewards={
          <Column gap="xs" width="100%" align="flex-end">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>{TokenSortMethod.REWARDS}</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.BodySmall color="textTitle" fontWeight={500} fontSize={14}>
              --{' '}
            </ThemedText.BodySmall>
          </Column>
        }
        first={leaderboardListIndex === 0}
        last={leaderboardListIndex === leaderboardListLength - 1}
      />
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
