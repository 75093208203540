import { atom, useAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { useCallback } from 'react'

export enum VoteSortMethod {
  POOL = 'POOL',
  TVL = 'TVL',
  VAPR = 'vAPR',
  FEES = 'Fees',
  BRIBES = 'Bribes',
  TOTAL_REWARDS = 'Total Rewards',
  TOTAL_VOTES = 'Total Votes',
  MY_VOTE = 'My Vote',
  EARN = 'Earn',
}

export const filterStringAtom = atomWithReset<string>('')
export const sortMethodAtom = atom<VoteSortMethod>(VoteSortMethod.TVL)
export const sortAscendingAtom = atom<boolean>(false)
export const VoteSortPage = atom<number>(1)
/* keep track of sort category for token table */
export function useSetSortMethod(newSortMethod: VoteSortMethod) {
  const [sortMethod, setSortMethod] = useAtom(sortMethodAtom)
  const [sortAscending, setSortAscending] = useAtom(sortAscendingAtom)

  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending(!sortAscending)
    } else {
      setSortMethod(newSortMethod)
      setSortAscending(false)
    }
  }, [sortMethod, setSortMethod, setSortAscending, sortAscending, newSortMethod])
}
