import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import { ButtonSecondary } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { StyledTableRow } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT, SMALL_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useCurrency } from 'hooks/Tokens'
import { useOrderBookContract } from 'hooks/useContract'
import { TextLeftCell } from 'pages/Lock/LockRow'
import { useGetPositions, useTwapPrice } from 'pages/Trade/Hooks'
import EditLimitModal from 'pages/Trade/Modal/EditLimitModal'
import { OrederListProps } from 'pages/Trade/types'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useCallback, useMemo, useState } from 'react'
import { Box } from 'rebass'
import { useTradeSWAPState } from 'state/positionSwap/hooks'
import styled, { css, useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromSqrt96Wei, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { LimitSortMethod } from './state'

const StyledLimitRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: center;
  grid-template-columns: 200px 0.6fr 1fr 1fr 1fr 1fr 0.8fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  .bg {
    height: 100%;
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }
`

const StyledTrRow = styled(StyledLimitRow)``

const StyledHeaderRow = styled(StyledLimitRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

export const EditBtn = styled(ButtonSecondary)`
  min-height: 36px;
  width: auto;
  font-size: 12px;
  padding: 4px 2px;
`

export const StyledBonus = styled.div`
  font-size: 12px;
  font-weight: 700;
`

/* Token Row: skeleton row component */
function LimitRow({
  header,
  type,
  orders,
  triggerPrice,
  markPrice,
  size,
  bonus,
  action,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  size: ReactNode
  type: ReactNode
  triggerPrice?: ReactNode
  markPrice: ReactNode
  orders: ReactNode
  bonus: ReactNode
  action: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="orders-cell">{orders}</TextLeftCell>
      <TextLeftCell data-testid="trigger-price-cell">{type}</TextLeftCell>
      <TextLeftCell data-testid="trigger-price-cell">{triggerPrice}</TextLeftCell>
      <TextLeftCell data-testid="mark-price-cell">{markPrice}</TextLeftCell>
      <TextLeftCell data-testid="size-cell">{size}</TextLeftCell>
      <TextLeftCell data-testid="bonus-cell">{bonus}</TextLeftCell>
      <TextLeftCell data-testid="total-rewards-cell">{action}</TextLeftCell>
    </>
  )
  const rowTrCells = (
    <>
      <TextLeftCell data-testid="orders-cell">
        <Box p="16px 0" width="100%" height="100%">
          {orders}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="trigger-price-cell">
        <Box p="16px 0" width="100%" height="100%">
          {type}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="trigger-price-cell">
        <Box p="16px 0" width="100%" height="100%">
          {triggerPrice}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="mark-price-cell">
        <Box p="16px 0" width="100%" height="100%">
          {markPrice}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="size-cell">
        <Box p="16px 0" width="100%" height="100%">
          {size}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="bonus-cell">
        <Box p="16px 0" width="100%" height="100%">
          {bonus}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="total-rewards-cell">
        <Box p="16px 0" width="100%" height="100%">
          {action}
        </Box>
      </TextLeftCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="limit-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledTrRow {...rest}>{rowTrCells}</StyledTrRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LimitRow
      header={true}
      orders={<Trans>{LimitSortMethod.Orders}</Trans>}
      type={<Trans>{LimitSortMethod.Type}</Trans>}
      triggerPrice={<Trans>{LimitSortMethod.TriggerPrice}</Trans>}
      markPrice={<Trans>{LimitSortMethod.MarkPrice}</Trans>}
      size={<Trans>{LimitSortMethod.Size}</Trans>}
      bonus={<Trans>{LimitSortMethod.Bonus}</Trans>}
      action={<Trans>{LimitSortMethod.Action}</Trans>}
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LimitRow
      header={false}
      $loading
      orders={
        <Row gap="xs">
          <IconLoadingBubble width="40px" height="40px" />
          <MediumLoadingBubble />
        </Row>
      }
      type={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      triggerPrice={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      markPrice={
        <Row justify="flex-start" gap="xs">
          <LoadingBubble />
        </Row>
      }
      size={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      bonus={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      action={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      {...props}
    />
  )
}

export interface LoadedRowProps {
  limitListIndex: number
  limitListLength: number
  limit: OrederListProps
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { limitListIndex, limitListLength, limit } = props
  const [showEdit, setShowEdit] = useState(false)

  const theme = useTheme()
  const { isToken0 } = useTradeSWAPState()
  const currency0 = useCurrency(limit.token0)
  const currency1 = useCurrency(limit.token1)

  const twapPrice = useTwapPrice(limit.spotpool)
  const orderbookContract = useOrderBookContract()
  const allPostions = useGetPositions()

  const decimals = limit.long0 ? currency1?.decimals : currency0?.decimals
  const feesymbol = limit.long0 ? currency0?.symbol : currency1?.symbol
  const feesdecimals = limit.long0 ? currency0?.decimals : currency1?.decimals
  const sizeymbol = limit.long0 ? currency1?.symbol : currency0?.symbol

  const lever = useMemo(() => {
    if (!allPostions) return '1'
    const key = limit.perpPool + limit.long0
    const len = allPostions.filter((x) => {
      return x.pool + x.long0 === key
    })
    if (len.length > 0) {
      const data = BN(len[0].size.toString()).div(len[0].collateral.toString()).toFixed(2)
      return data
    }

    return '1.2'
  }, [allPostions, limit.long0, limit.perpPool])

  // const lever = useMemo(() => {
  //   const collateral = limit.collateralIn || limit.collateralDelta
  //   if (limit.long0) {
  //     return BN(
  //       BN(limit.sizeDelta.toString()).div(fromSqrt96Wei(limit.triggerPrice.toString()).toFixed()).toFixed()
  //     ).div(collateral.toString())
  //   } else {
  //     return BN(
  //       BN(limit.sizeDelta.toString()).multipliedBy(fromSqrt96Wei(limit.triggerPrice.toString()).toFixed()).toFixed()
  //     ).div(collateral.toString())
  //   }
  // }, [limit.collateralIn, limit.long0, limit.sizeDelta, limit.triggerPrice])

  const handleDismissEdit = () => {
    setShowEdit(false)
  }

  const closeOrder = useCallback(
    async (key: any) => {
      if (!orderbookContract) return
      await orderbookContract.cancelIncreaseOrder(key)
    },
    [orderbookContract]
  )

  const symbol = useMemo(() => {
    if (isToken0) {
      return ` ${currency0?.symbol}/${currency1?.symbol}`
    } else {
      return ` ${currency1?.symbol}/${currency0?.symbol}`
    }
  }, [currency0?.symbol, currency1?.symbol, isToken0])

  const triggerPrice = useMemo(() => {
    if (isToken0) {
      return fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals).toFixed()
    } else {
      return BN(1)
        .div(fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals).toFixed())
        .toFixed()
    }
  }, [currency0?.decimals, currency1?.decimals, isToken0, limit.triggerPrice])

  const currenttwapPrice = useMemo(() => {
    if (isToken0) {
      return fromSqrt96Wei(twapPrice?.toString(), currency0?.decimals, currency1?.decimals).toFixed()
    } else {
      return BN(1).div(fromSqrt96Wei(twapPrice?.toString(), currency0?.decimals, currency1?.decimals)).toFixed()
    }
  }, [currency0?.decimals, currency1?.decimals, isToken0, twapPrice])
  return (
    <>
      <div ref={ref} data-testid={`limit-table-row-${limit.rgPool}`}>
        <LimitRow
          header={false}
          orders={
            <Row>
              <DoubleCurrencyLogo
                size={30}
                margin
                currency0={isToken0 ? currency1 ?? undefined : currency0 ?? undefined}
                currency1={!isToken0 ? currency1 ?? undefined : currency0 ?? undefined}
              />
              <Column gap="xs" align="flex-start" ml="8px">
                <ThemedText.TextPrimary fontWeight={500} fontSize={14}>
                  {symbol}
                </ThemedText.TextPrimary>
                <Row gap="xs">
                  <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                    {lever}x
                  </ThemedText.TextSecondary>
                  {isToken0 ? limit.long0 ? <LongIcon /> : <ShortIcon /> : limit.long0 ? <ShortIcon /> : <LongIcon />}
                </Row>
              </Column>
            </Row>
          }
          type={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={500} fontSize={12}>
                {limit.type}
              </ThemedText.TextPrimary>
            </Column>
          }
          triggerPrice={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={500} fontSize={12}>
                {isToken0
                  ? limit.triggerAboveThreshold == true
                    ? ' >'
                    : '<'
                  : !limit.triggerAboveThreshold == true
                  ? ' >'
                  : '<'}{' '}
                {countZeros(triggerPrice)}
              </ThemedText.TextPrimary>
            </Column>
          }
          markPrice={
            <Row justify="flex-start" gap="xs">
              <ThemedText.TextPrimary fontWeight={500} fontSize={12}>
                {countZeros(currenttwapPrice)}
              </ThemedText.TextPrimary>
            </Row>
          }
          size={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                {countZeros(fromWei(limit.sizeDelta.toString(), decimals).toFixed())} {sizeymbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          bonus={
            <Column align="flex-start" width="100%" gap="xs">
              <StyledBonus className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}>
                {countZeros(fromWei(limit.executionFee.toString(), feesdecimals).toFixed())} {feesymbol}
              </StyledBonus>
            </Column>
          }
          action={
            <Row align="center" gap="sm">
              <ThemedText.TextPrimary
                fontWeight={400}
                fontSize={12}
                style={{ cursor: 'pointer' }}
                onClick={() => closeOrder(limit.key)}
              >
                Close
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary
                fontWeight={400}
                fontSize={12}
                style={{ cursor: 'pointer' }}
                onClick={() => setShowEdit(true)}
              >
                Edit
              </ThemedText.TextPrimary>
            </Row>
          }
          first={limitListIndex === 0}
          last={limitListIndex === limitListLength - 1}
        />
      </div>

      <EditLimitModal isOpen={showEdit} onDismiss={handleDismissEdit} limit={limit} />
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
