import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { PageWrapper } from 'components/swap/styleds'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { AccountInfo } from './AccountInfo'
import Assets from './Assets'
import History from './History'
import Position from './Position'
import Reward from './Reward'
import VeROX from './VeROX'

const ProfileTabBox = styled(Box)`
  margin-top: 24px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  .tabItem {
    flex: 1;
    padding: 8px;
    cursor: pointer;
  }
  .tabItemActive {
    border-bottom: 1px solid ${({ theme }) => theme.textPrimary};
  }
`

export default function Profile() {
  const [curr, setCurr] = useState<any>(0)
  const tabList = [
    'Assets',
    'Position',
    'veROX',
    'Reward',
    // 'History',
  ]

  return (
    <PageWrapper data-testid="profile-page">
      {/* <TitleRow>
        <ThemedText.LargeHeader color="textPrimary">
          <Trans>Profile</Trans>
        </ThemedText.LargeHeader>
      </TitleRow> */}
      <AccountInfo />
      <ProfileTabBox>
        {tabList.map((item, index) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            key={index}
            className={`tabItem ${curr == index && 'tabItemActive'}`}
            onClick={() => setCurr(index)}
          >
            {curr == index ? (
              <ThemedText.TextPrimary fontWeight={700}>
                <Trans>{item}</Trans>
              </ThemedText.TextPrimary>
            ) : (
              <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
                <Trans>{item}</Trans>
              </ThemedText.TextSecondary>
            )}
          </Box>
        ))}
      </ProfileTabBox>
      {curr == 0 && <Assets />}
      {curr == 1 && <Position />}
      {curr == 2 && <VeROX />}
      {curr == 3 && <Reward />}
      {curr == 4 && <History />}
    </PageWrapper>
  )
}
