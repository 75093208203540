import { Trans } from '@lingui/macro'
import { formatPrice, NumberType } from '@uniswap/conedison/format'
import { Currency } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { AutoColumn } from 'components/Column'
import { RateToggle2 } from 'components/RateToggle'
import Row from 'components/Row'
import { ThemedText } from 'theme'

export default function CurrentPriceCard({
  inverted,
  pool,
  currencyQuote,
  currencyBase,
  manuallyInverted,
  setManuallyInverted,
}: {
  inverted?: boolean
  pool?: Pool | null
  currencyQuote?: Currency
  currencyBase?: Currency
  manuallyInverted?: boolean | null
  setManuallyInverted?: any
}) {
  if (!pool || !currencyQuote || !currencyBase) {
    return null
  }

  return (
    <AutoColumn gap="sm" justify="center">
      <Row gap="sm" justify="flex-end">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Price</Trans>
        </ThemedText.TextSecondary>
        <RateToggle2
          currencyA={currencyBase}
          currencyB={currencyQuote}
          handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
        />
      </Row>
      <ThemedText.TextPrimary fontSize={16} fontWeight={700} textAlign="center">
        {formatPrice(inverted ? pool.token1Price : pool.token0Price, NumberType.TokenTx)} {currencyQuote?.symbol} per{' '}
        {currencyBase?.symbol}
      </ThemedText.TextPrimary>
    </AutoColumn>
  )
}
