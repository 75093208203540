import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { ButtonConfirmed } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import { StyledCloseIcon } from 'components/Icons/StyledIcon'
import Row, { RowBetween } from 'components/Row'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { BigNumber } from 'ethers'
import { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formFeeWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { useDetailsPool } from '../Hooks'
import SwapSectionPreview from '../SwapSectionPreview'
import { SpreadWithLevrage } from '../TradeRightWrapper'

interface ConfirmModalProps {
  isOpen: boolean
  inputCurrency?: CurrencyAmount<Currency>
  outputCurrency?: CurrencyAmount<Currency>
  entryPrice?: string
  onDismiss: () => void
  onConfirm: () => void
  txHash?: string
  txError?: string
  pendingText?: string
  attemptingTxn: boolean
  liqPrice?: string
  fee?: string
  spread?: string
  isLimit: boolean
  positionFee?: BigNumber
  limitEntryPrice?: string
  limitfee: string
  inputtrade?: JSX.Element
  outputtrade?: JSX.Element
  prevOpenPrice?: string
  prevLiqPrice?: string
  maxSpreadL?: string
  sliederLever?: number
}

const Wrapper = styled.div`
  /* background-color: ${({ theme }) => theme.backgroundSurface}; */
  /* border-radius: 20px; */
  /* outline: 1px solid ${({ theme }) => theme.backgroundOutline}; */
  width: 100%;
  padding: 16px;
  /* padding: 16px 8px; */
`

function ConfirmModalContent({
  title,
  onDismiss,
  modalContent,
}: {
  title: ReactNode
  onDismiss: () => void
  modalContent: () => ReactNode
}) {
  return (
    <Wrapper>
      <AutoColumn gap="sm">
        <Row>
          <Row justify="center" marginLeft="24px">
            <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
          </Row>
          <StyledCloseIcon
            onClick={onDismiss}
            data-testid="confirmation-close-icon"
            style={{ marginRight: '8px', cursor: 'pointer' }}
          />
        </Row>
      </AutoColumn>
      {modalContent()}
    </Wrapper>
  )
}
type ModalContentProps = {
  isLimit: boolean
  inputCurrency?: CurrencyAmount<Currency>
  outputCurrency?: CurrencyAmount<Currency>
  entryPrice?: string
  confirm?: () => void
  liqPrice?: string
  fee?: string
  spread?: string
  positionFee?: BigNumber
  limitEntryPrice?: string
  limitfee: string
  inputtrade?: JSX.Element
  outputtrade?: JSX.Element
  prevOpenPrice?: string
  prevLiqPrice?: string
  maxSpreadL?: string
  sliederLever?: number
}
function ModalContent({
  inputCurrency,
  outputCurrency,
  entryPrice,
  confirm,
  liqPrice,
  fee,
  spread,
  positionFee,
  isLimit,
  limitEntryPrice,
  limitfee,
  inputtrade,
  outputtrade,
  prevOpenPrice,
  prevLiqPrice,
  maxSpreadL,
  sliederLever,
}: ModalContentProps) {
  const { symbol } = useDetailsPool()
  return (
    <Column gap="sm">
      <SwapSectionPreview
        inputCurrency={inputCurrency}
        outputCurrency={outputCurrency}
        inputtrade={inputtrade}
        outputtrade={outputtrade}
      />
      {/* <RowBetween>
        <ThemedText.TextSecondary fontSize={14}>Profits In</ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontWeight={500}>$0.13</ThemedText.TextPrimary>
      </RowBetween> */}
      {isLimit ? (
        <>
          <RowBetween mt="8px">
            <ThemedText.TextSecondary fontSize={14}>Entry Price</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={500}>{countZeros(limitEntryPrice)} </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>{symbol}</ThemedText.TextSecondary>
            </Row>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Liq. Price</ThemedText.TextSecondary>

            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={500}>{countZeros('0')} </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>{symbol}</ThemedText.TextSecondary>
            </Row>
          </RowBetween>

          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Fees</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={500}>
                {countZeros(limitfee)} {inputCurrency?.wrapped.currency.symbol}{' '}
              </ThemedText.TextPrimary>
            </Row>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Spread</ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={500}>{0}%</ThemedText.TextPrimary>
          </RowBetween>
        </>
      ) : (
        <>
          <RowBetween mt="8px">
            <ThemedText.TextSecondary fontSize={14}>Entry Price</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={500}>
                {prevOpenPrice && prevOpenPrice != '0' ? (
                  <Row>
                    <ThemedText.TextSecondary fontSize={12}>{countZeros(prevOpenPrice)}</ThemedText.TextSecondary>
                    &nbsp;→ {countZeros(entryPrice)}
                  </Row>
                ) : (
                  countZeros(entryPrice || '0')
                )}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>{symbol}</ThemedText.TextSecondary>
            </Row>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Liq. Price</ThemedText.TextSecondary>

            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={500}>
                {' '}
                {prevLiqPrice && prevLiqPrice != '0' ? (
                  <Row>
                    <ThemedText.TextSecondary fontSize={12}>{countZeros(prevLiqPrice)}</ThemedText.TextSecondary>
                    &nbsp;→ {countZeros(liqPrice)}
                  </Row>
                ) : (
                  countZeros(liqPrice || '0')
                )}{' '}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>{symbol}</ThemedText.TextSecondary>
            </Row>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Fees</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={500}>
                {countZeros(fee)} {inputCurrency?.wrapped.currency.symbol}{' '}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12}>
                (
                {formFeeWei(positionFee || BigNumber.from(0))
                  .times(100)
                  .toFixed(2)}
                %)
              </ThemedText.TextSecondary>
            </Row>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Spread with leverage</ThemedText.TextSecondary>
            {/* <ThemedText.TextPrimary fontWeight={500}>{spread}%</ThemedText.TextPrimary> */}
            <SpreadWithLevrage sliederLever={sliederLever} spread={spread} />
          </RowBetween>
        </>
      )}

      <RowBetween>
        <ThemedText.TextSecondary fontSize={14}>Max Slippage with leverage</ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontWeight={500}>{maxSpreadL}%</ThemedText.TextPrimary>
      </RowBetween>
      <ButtonConfirmed
        data-testid="confirm-open-position-button"
        id="confirm-open-position-button"
        mt="8px"
        onClick={confirm}
      >
        <ThemedText.HeadlineSmall color="accentTextLightPrimary">
          <Trans>Confirm</Trans>
        </ThemedText.HeadlineSmall>
      </ButtonConfirmed>
    </Column>
  )
}

export default function TradeConfirmModal({
  isOpen,
  onDismiss,
  inputCurrency,
  outputCurrency,
  entryPrice,
  onConfirm,
  pendingText,
  txHash,
  txError,
  attemptingTxn,
  liqPrice,
  fee,
  isLimit,
  spread,
  positionFee,
  limitEntryPrice,
  limitfee,
  inputtrade,
  outputtrade,
  prevOpenPrice,
  prevLiqPrice,
  maxSpreadL,
  sliederLever,
}: ConfirmModalProps) {
  const modalContent = () => {
    return (
      <ModalContent
        inputCurrency={inputCurrency}
        outputCurrency={outputCurrency}
        entryPrice={entryPrice}
        liqPrice={liqPrice}
        confirm={onConfirm}
        limitEntryPrice={limitEntryPrice}
        isLimit={isLimit}
        limitfee={limitfee}
        fee={fee}
        spread={spread}
        positionFee={positionFee}
        inputtrade={inputtrade}
        outputtrade={outputtrade}
        prevOpenPrice={prevOpenPrice}
        prevLiqPrice={prevLiqPrice}
        maxSpreadL={maxSpreadL}
        sliederLever={sliederLever}
      />
    )
  }
  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      width="400px"
      reviewContent={() => (
        <ConfirmModalContent
          title={<Trans>Open Position Preview</Trans>}
          onDismiss={onDismiss}
          modalContent={modalContent}
        />
      )}
      pendingText={pendingText}
    />
  )
}
