import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { StyledBackIcon, StyledWinIcon } from 'components/Icons/StyledIcon'
import { MediumLoadingBubble } from 'components/Loading'
import { PageWrapper } from 'components/swap/styleds'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useAtom } from 'jotai'
import { NoTokensStateM } from 'pages/Liquidity/Table'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { formatAmount } from 'utils/formatAmout'

import InfoBox from './InfoBox'
import useRankingData, { epochAtom, useRankingInfo } from './rankingHooks'
import RoundInfo from './RoundInfo'
import TableM from './Table-m'

const LeaderBoardTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .title {
    position: absolute;
    left: 46px;
    // bottom: 0;
  }
`

const RankingPage = styled(Box)`
  margin-top: 40px;
  padding: 10px 16px 22px 16px;
  /* background: ${({ theme }) => (theme.darkMode ? 'rgba(40, 40, 40, 0.5)' : 'rgba(0, 0, 0, 0.05)')}; */
  border: 1px solid ${({ theme }) => theme.primaryBtn};
  border-radius: 16px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding: 10px 8px 22px 8px;
  }
`

const StyledTr = styled(Box)`
  display: grid;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  grid-template-columns: 100px 200px 1fr 1fr 1fr;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    grid-template-columns: 50px 120px 1fr 1fr 1fr;
  }
`

const StyledThHeader = styled(StyledTr)`
  border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
`

const StyledTh = styled(StyledTr)`
  &.rank1 {
    background: rgba(1, 147, 210, 0.4);
  }
  &.rank2 {
    background: rgba(1, 147, 210, 0.2);
  }
  &.rank3 {
    background: rgba(1, 147, 210, 0.1);
  }
  &.border {
    border-bottom: 1px dashed ${({ theme }) => theme.swapSectionBorder};
  }
`

const StyledTd = styled(Box)`
  padding: 0 8px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 0 6px;
  }
`

export default function Ranking() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const { account } = useActiveChainId()

  const publicAddress = account?.toLowerCase()

  const { rankingList, userPtsInfo, roundGold, roundPoint, isRankLoading } = useRankingData()
  const { goldPoint, totalUser, totalPoint, roundList } = useRankingInfo()
  const [epoch, setEpoch] = useAtom(epochAtom)

  const navigate = useNavigate()
  const handlerBack = () => {
    navigate('/points')
  }

  return (
    <PageWrapper data-testid="airdrop-ranking-page">
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isSmallScreen ? 'flex-start' : 'center'}
        gap={isSmallScreen ? '16px' : '8px'}
      >
        <Box gap={isSmallScreen ? '12px' : '24px'} display="flex" alignItems="center">
          <StyledBackIcon onClick={handlerBack} />
          <LeaderBoardTitle>
            <StyledWinIcon />
            <ThemedText.TextPrimary
              fontSize={isSmallScreen ? 36 : 48}
              fontWeight={800}
              className="title active-gradient-text"
            >
              <Trans>Ranking</Trans>
            </ThemedText.TextPrimary>
          </LeaderBoardTitle>
        </Box>
        <InfoBox goldPoint={goldPoint} totalUser={totalUser} totalPoint={totalPoint} />
        {/* <Box display="flex" width={isSmallScreen ? '100%' : 'auto'} justifyContent="flex-end">
          <LeaderBoardIcon />
        </Box> */}
      </Box>
      <RankingPage>
        <RoundInfo
          setEpoch={setEpoch}
          epoch={epoch}
          roundList={roundList}
          roundGold={roundGold}
          roundPoint={roundPoint}
        />
        {isSmallScreen ? (
          <TableM rankingList={rankingList} userPtsInfo={userPtsInfo} />
        ) : (
          <>
            <StyledThHeader>
              <StyledTd>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>#</Trans>
                </ThemedText.TextSecondary>
              </StyledTd>
              <StyledTd>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>Address</Trans>
                </ThemedText.TextSecondary>
              </StyledTd>
              <StyledTd>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>PTS.</Trans>
                </ThemedText.TextSecondary>
              </StyledTd>
              <StyledTd>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>Blast Points</Trans>
                </ThemedText.TextSecondary>
              </StyledTd>
              <StyledTd>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>Blast Gold</Trans>
                </ThemedText.TextSecondary>
              </StyledTd>
            </StyledThHeader>
            <div className="" style={{ position: 'relative' }}>
              <StyledTh>
                <StyledTd>
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    {formatAmount(userPtsInfo?.rank, 0, true)}
                  </ThemedText.TextSecondary>
                </StyledTd>
                <StyledTd>
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700} color="primaryBtn">
                    {shortenAddress(publicAddress)}
                  </ThemedText.TextSecondary>
                </StyledTd>
                <StyledTd>
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    {formatAmount(userPtsInfo?.pts_total, 2, true)} PTS.
                  </ThemedText.TextSecondary>
                </StyledTd>
                <StyledTd>
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    {formatAmount(userPtsInfo?.est_point, 2, true)} PTS.
                  </ThemedText.TextSecondary>
                </StyledTd>
                <StyledTd>
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    {formatAmount(userPtsInfo?.est_gold, 2, true)} Gold
                  </ThemedText.TextSecondary>
                </StyledTd>
              </StyledTh>
              {/* <div style={{ position: 'absolute', bottom: '6px', right: '16px' }}>
                <ShareBox />
              </div> */}
            </div>
            {!isRankLoading ? (
              rankingList?.length > 0 ? (
                rankingList?.map((item: any, i: number) => (
                  <StyledTh key={i} className={i == 0 ? 'rank1' : i == 1 ? 'rank2' : i == 2 ? 'rank3' : 'border'}>
                    <StyledTd>
                      <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                        {i + 1}
                      </ThemedText.TextSecondary>
                    </StyledTd>
                    <StyledTd>
                      <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                        {shortenAddress(item.wallet_address)}
                      </ThemedText.TextSecondary>
                    </StyledTd>
                    <StyledTd>
                      <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                        {formatAmount(item.pts_total, 2, true)} PTS.
                      </ThemedText.TextSecondary>
                    </StyledTd>
                    <StyledTd>
                      <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                        {formatAmount(item.est_point, 2, true)} PTS.
                      </ThemedText.TextSecondary>
                    </StyledTd>
                    <StyledTd>
                      <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                        {formatAmount(item.est_gold, 2, true)} Gold
                      </ThemedText.TextSecondary>
                    </StyledTd>
                  </StyledTh>
                ))
              ) : (
                <NoTokensStateM message={<Trans>No rankingList found</Trans>} />
              )
            ) : (
              <MediumLoadingBubble />
            )}
          </>
        )}
      </RankingPage>
    </PageWrapper>
  )
}
