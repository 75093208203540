import { useAtomValue } from 'jotai/utils'
import { useFetchVoter } from 'pages/Vote/hooks'
import { VoteDataProps } from 'pages/Vote/types'
import { useMemo } from 'react'

import {
  filterLiquidityStringAtom,
  LiquiditysortAscendingAtom,
  LiquiditySortMethod,
  LiquiditysortMethodAtom,
  LiquiditySortPage,
} from './state'
export const pageSize = 10
export function useLiquidityLength() {
  const { voteList } = useFetchVoter()
  if (!voteList) return 0
  return Math.ceil(voteList.length / pageSize)
}
export function useLiquidityData() {
  const { voteList } = useFetchVoter()

  const sortMethod = useAtomValue(LiquiditysortMethodAtom)
  const sortAscending = useAtomValue(LiquiditysortAscendingAtom)
  const sortPage = useAtomValue(LiquiditySortPage)
  const sortData = useMemo(() => {
    if (!voteList) return
    let voteArray = voteList
    switch (sortMethod) {
      case LiquiditySortMethod.TVL:
        voteArray = voteArray.sort((a, b) => Number(b.tvl || 0) - Number(a.tvl || 0))
        break

      case LiquiditySortMethod.APR:
        voteArray = voteArray.sort((a, b) => Number(b.liquityAPR || 0) - Number(a.liquityAPR || 0))
        break
      case LiquiditySortMethod.INCENTIVIZATION:
        voteArray = voteArray.sort((a, b) => Number(b.LiquidityrewardUSD || 0) - Number(a.LiquidityrewardUSD || 0))
        break

      case LiquiditySortMethod.MY_STAKED:
        voteArray = voteArray.sort((a, b) => Number(b.stakeData.tvl || 0) - Number(a.stakeData.tvl || 0))
        break
    }
    return sortAscending ? voteArray.reverse() : voteArray
  }, [sortAscending, sortMethod, voteList])
  const filteredVote = useFilteredVote(sortData)?.slice((sortPage - 1) * pageSize, (sortPage - 1) * pageSize + pageSize)

  return filteredVote
}

const useFilteredVote = (voteData: VoteDataProps[] | undefined) => {
  const filterString = useAtomValue(filterLiquidityStringAtom)

  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString])

  return useMemo(() => {
    if (!voteData) return undefined
    let returnDatas = voteData
    if (lowercaseFilterString) {
      returnDatas = returnDatas?.filter((token) => {
        const allStrsymbol = token.token0.symbol + token.token1.symbol
        const allStrNAME = token.token0.name + token.token1.name
        const nameIncludesFilterString = allStrNAME.toLowerCase().includes(lowercaseFilterString)
        const symbolIncludesFilterString = allStrsymbol.toLowerCase().includes(lowercaseFilterString)
        const tokenAddressIncludesFilterString0 = token.token0.id.toLowerCase().includes(lowercaseFilterString)
        const tokenAddressIncludesFilterString1 = token.token1.id.toLowerCase().includes(lowercaseFilterString)
        return nameIncludesFilterString || symbolIncludesFilterString ||  tokenAddressIncludesFilterString0 || tokenAddressIncludesFilterString1
      })
    }
    return returnDatas
  }, [lowercaseFilterString, voteData])
}
