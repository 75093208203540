import { BigNumber } from 'ethers'
import { useRoxUtils } from 'hooks/useContract'
import { IOrder, useOrderBookList } from 'hooks/useQueryLiquidity'
import { useAtomValue } from 'jotai/utils'
import { useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'

import { filterStringAtom } from '../state'

export interface LimitDataPrpos extends IOrder {
  twapPrice: BigNumber
}

export const useLimitData = () => {
  const limitList = useOrderBookList()

  const roxutils = useRoxUtils()

  const limitPrams = useMemo(() => {
    if (!limitList) return [[undefined]]
    return limitList.map((x) => [x.spotpool])
  }, [limitList])

  const lists = useSingleContractMultipleData(roxutils, 'getSqrtTwapX96', limitPrams ?? [[undefined]])

  const loading = lists.some(({ loading }) => loading)
  const error = lists.some(({ error }) => error)

  const alldata = useMemo(() => {
    if (!lists || !limitList || loading || error || limitList.length == 0) return

    return limitList.reduce<LimitDataPrpos[]>((acc: any, list, index) => {
      const { result } = lists[index]
      const data = {
        ...list,
        twapPrice: BigNumber.from(0),
      }
      if (!result) {
        acc.push(data)
        return acc
      }
      data.twapPrice = result.sqrtPriceX96
      acc.push(data)
      return acc
    }, [])
  }, [error, limitList, lists, loading])

  return useFilteredLimit(alldata)
}

const useFilteredLimit = (voteData: LimitDataPrpos[] | undefined) => {
  const filterString = useAtomValue(filterStringAtom)

  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString])

  return useMemo(() => {
    if (!voteData) return undefined
    let returnDatas = voteData
    if (lowercaseFilterString) {
      returnDatas = returnDatas?.filter((token) => {
        const allStrsymbol = token.token0.symbol + token.token1.symbol
        const allStrNAME = token.token0.name + token.token1.name
        const nameIncludesFilterString = allStrNAME.toLowerCase().includes(lowercaseFilterString)
        const symbolIncludesFilterString = allStrsymbol.toLowerCase().includes(lowercaseFilterString)
        return nameIncludesFilterString || symbolIncludesFilterString
      })
    }
    return returnDatas
  }, [lowercaseFilterString, voteData])
}
