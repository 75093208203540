import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { useActiveChainId } from 'connection/useActiveChainId'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { useAtom } from 'jotai'
import AddBribesModal from 'pages/Vote/AddBribesModal'
import { VoteDataProps } from 'pages/Vote/types'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import FarmPoolModal from './FarmPoolModal'
import { useLiquidityData, useLiquidityLength } from './hooks'
import { LoadedRow, LoadingRow } from './LiquidityRow-m'
import { LiquiditySortPage } from './state'
import { StyledPagination } from './StyledPagination'
import { GridContainer, NoTokensStateM } from './Table'

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function TableM(): JSX.Element {
  const { account } = useActiveChainId()
  const [timeCount, setTimeCount] = useState(10)
  // const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)
  const [liquiditySortPage, setLiquiditySortPage] = useAtom(LiquiditySortPage)
  const location = useLocation()

  const isProfile = useMemo(() => {
    return location.pathname == '/profile' ? true : false
  }, [location])

  const liquidityListData = useLiquidityData()

  const allLiquidityList = liquidityListData
  const liquidityListStaked = allLiquidityList?.filter((item: any) => Number(item.stakeData.tvl) > 0)
  const liquidityList = isProfile ? liquidityListStaked : allLiquidityList

  const [showAddBribes, setShowAddBribes] = useState(false)
  const [showFarmPool, setShowFarmPool] = useState(false)
  const [currentLiquidity, setcurrentLiquidity] = useState<undefined | VoteDataProps>()

  const handleDismissCreate = () => {
    setShowFarmPool(false)
  }

  const handleDismissAddBribes = () => {
    setShowAddBribes(false)
  }

  const addBribeChage = (data: VoteDataProps) => {
    if (!data) return
    setcurrentLiquidity(data)
    setShowAddBribes(true)
  }

  const showPoolMidalChage = (data: VoteDataProps) => {
    if (!data) return
    setcurrentLiquidity(data)
    setShowFarmPool(true)
  }
  const handleNextPage = (e: any, p: any) => {
    setLiquiditySortPage(p)
  }
  const count = useLiquidityLength()
  /* loading and error state */
  if (!liquidityList) {
    return <LoadingTokenTable rowCount={PAGE_SIZE} />
  } else if (liquidityList?.length === 0) {
    return <NoTokensStateM message={<Trans>No liquidityList found</Trans>} />
  } else {
    return (
      <>
        <TableDataContainer>
          {liquidityList.map((liquidity, index) => (
            <LoadedRow
              key={index}
              addBribeChage={addBribeChage}
              showPoolMidalChage={showPoolMidalChage}
              liquidityListIndex={index}
              liquidityListLength={liquidityList.length}
              liquidity={liquidity}
            />
          ))}
          {!isProfile && <StyledPagination style={{ marginTop: '4px' }} count={count} onChange={handleNextPage} />}

          <AddBribesModal
            brigeType="LP"
            isOpen={showAddBribes}
            details={currentLiquidity}
            count={timeCount}
            setCount={setTimeCount}
            onDismiss={handleDismissAddBribes}
          />
          <FarmPoolModal
            poolAddress={currentLiquidity?.lpAddr}
            token0={currentLiquidity?.token0}
            token1={currentLiquidity?.token1}
            fee={currentLiquidity?.poolFee}
            isOpen={showFarmPool}
            hypervisorAddr={currentLiquidity?.hypervisorAddress}
            onDismiss={handleDismissCreate}
          />
        </TableDataContainer>
      </>
    )
  }
}
