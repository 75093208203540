import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as RectangleIcon1 } from 'assets/svg/perpInfo/rectangle1.svg'
import { ReactComponent as RectangleIcon2 } from 'assets/svg/perpInfo/rectangle2.svg'
import { ReactComponent as RectangleIcon3 } from 'assets/svg/perpInfo/rectangle3.svg'
import Column from 'components/Column'
import { StyledSettingIcon } from 'components/Icons/StyledIcon'
import { RowBetween } from 'components/Row'
import { Input } from 'components/Settings/Input'
import Tips from 'components/Tips'
import { CollectButton } from 'pages/Pool/StyledPosition'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'

const StyledLine = styled.div`
  flex: 1;
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.primaryBorder};
`

const StyledInfoBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`

const StyledInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
`
const StyledInfo1 = styled(StyledInfo)`
  border-color: ${({ theme }) => theme.long};
`
const StyledInfo2 = styled(StyledInfo)`
  border-color: ${({ theme }) => theme.threshold};
`
const StyledInfo3 = styled(StyledInfo)`
  border-color: ${({ theme }) => theme.removeThreshold};
`
const StyledInputBox = styled(Box) <{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 0 16px 12px;
  border-bottom: 1px solid ${({ theme, isActive }) => (isActive ? theme.activeBorder : theme.primaryBorder)};
`

const StyledRectangleBox = styled.div`
  margin-top: 12px;
  position: relative;
  width: 100%;
  height: 8px;
  background: ${({ theme }) => theme.primaryBorder};
`
const StyledRectangle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`
const StyledRectanglePosition = styled(StyledRectangle) <{ rectangle?: any }>`
  left: ${({ rectangle }) => rectangle}%;
`
const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  grid-gap: 16px;
  .item {
    flex: 1;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`
export default function PerpInfo({ showUpDate, upFunc, isPoolOwner, curr, setCurr, utilizationValue, setUtilizationValue, thresholdValue, setThresholdValue, thresholdRemoveValue, setThresholdRemoveValue, leverageValue, setLeverageValue, timeValue_TWAP, setTimeValue_TWAP, timeValue_close, setTimeValue_close }: any) {
  // const [curr, setCurr] = useState<any>(0)
  // const [utilizationValue, setUtilizationValue] = useState<any>(50)
  // const [thresholdValue, setThresholdValue] = useState<any>(70)
  // const [thresholdRemoveValue, setThresholdRemoveValue] = useState<any>(80)
  // const [leverageValue, setLeverageValue] = useState<any>(1.1)
  // const [timeValue_TWAP, setTimeValue_TWAP] = useState<any>(10)
  // const [timeValue_close, setTimeValue_close] = useState<any>(10)
  const onUtilizationInput = (e: any) => {
    if (e.target.value <= 0) {
      setUtilizationValue(0)
      setLeverageValue(1)
    } else if (e.target.value > 100) {
      setUtilizationValue(100)
    } else {
      setUtilizationValue(BN(e.target.value).toFixed(0))
    }
  }

  const onThresholdInput = (e: any) => {
    if (e.target.value < 0) {
      setThresholdValue(0)
    } else if (e.target.value > 100) {
      setThresholdValue(100)
    } else {
      setThresholdValue(BN(e.target.value).toFixed(0))
    }
  }

  const onThresholdRemoveInput = (e: any) => {
    if (e.target.value < 0) {
      setThresholdRemoveValue(0)
    } else if (e.target.value > 100) {
      setThresholdRemoveValue(100)
    } else {
      setThresholdRemoveValue(BN(e.target.value).toFixed(0))
    }
  }

  const onLeverageValueInput = (e: any) => {
    if (BN(e.target.value).eq(1)) {
      setUtilizationValue(0)
    }
    setLeverageValue(BN(e.target.value).toFixed(0))
  }
  const leverageValueInputBlur = (e: any) => {
    if (e.target.value < 0) {
      setLeverageValue(0)
    } else if (e.target.value > 51) {
      setLeverageValue(51)
    }
  }

  const onTimeInput_TWAP = (e: any) => {
    setTimeValue_TWAP(BN(e.target.value).toFixed(0))
  }
  const timeBlur_TWAP = (e: any) => {
    if (e.target.value < 10) {
      setTimeValue_TWAP(10)
    } else if (e.target.value > 600) {
      setTimeValue_TWAP(600)
    }
  }

  const onTimeInput_close = (e: any) => {
    setTimeValue_close(BN(e.target.value).toFixed(0))
  }
  const timeBlur_close = (e: any) => {
    if (e.target.value < 10) {
      setTimeValue_close(10)
    } else if (e.target.value > 600) {
      setTimeValue_close(600)
    }
  }

  return (
    <Column gap="16px" align="flex-start" mt="20px">
      <Box display="flex" alignItems="center" gap="16px" width="100%">
        <Box display="flex" alignItems="center" gap="10px">
          <StyledSettingIcon />
          <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
            <Trans>{!isPoolOwner ? "Set" : ''} Perp Info</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <StyledLine />
        {showUpDate && <CollectButton onClick={upFunc}>UpDate</CollectButton>}
      </Box>
      <StyledInfoBox>
        <StyledInfo1 onClick={() => setCurr(0)}>
          <Box display="flex" alignItems="center" justifyContent="space-between" gap="16px">
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>Max open position utilization</Trans>
            </ThemedText.TextSecondary>
            <Tips tipsStr="Max open position utilization" />
          </Box>
          <Box display="flex" justifyContent="center">
            <StyledInputBox isActive={curr == 0 && !isPoolOwner}>
              <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                ≤
              </ThemedText.TextSecondary>
              <Input
                type="number"
                className="utilization-input"
                value={utilizationValue}
                onChange={onUtilizationInput}
                align="left"
                style={{ width: '32px' }}
                disabled={isPoolOwner}
              />
              <ThemedText.TextPrimary
                fontSize={16}
                fontWeight={700}
                color={curr == 0 ? 'textPrimary' : 'textSecondary'}
              >
                %
              </ThemedText.TextPrimary>
            </StyledInputBox>
          </Box>
        </StyledInfo1>
        <StyledInfo2 onClick={() => setCurr(1)}>
          <Box display="flex" alignItems="center" justifyContent="space-between" gap="16px">
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>Auto-deleveraging threshold</Trans>
            </ThemedText.TextSecondary>
            <Tips tipsStr="Auto-deleveraging threshold" />
          </Box>
          <Box display="flex" justifyContent="center">
            <StyledInputBox isActive={curr == 1 && !isPoolOwner} >
              <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                ≤
              </ThemedText.TextSecondary>
              <Input
                type="number"
                className="threshold-input"
                value={thresholdValue}
                onChange={onThresholdInput}
                align="left"
                style={{ width: '32px' }}
                disabled={isPoolOwner}
              />
              <ThemedText.TextPrimary
                fontSize={16}
                fontWeight={700}
                color={curr == 1 ? 'textPrimary' : 'textSecondary'}
              >
                %
              </ThemedText.TextPrimary>
            </StyledInputBox>
          </Box>
        </StyledInfo2>
        <StyledInfo3 onClick={() => setCurr(2)}>
          <Box display="flex" alignItems="center" justifyContent="space-between" gap="16px">
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>Frozen Swap and LP remove threshold</Trans>
            </ThemedText.TextSecondary>
            <Tips tipsStr="Frozen Swap and LP remove threshold" />
          </Box>
          <Box display="flex" justifyContent="center">
            <StyledInputBox isActive={curr == 2 && !isPoolOwner} >
              <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                ≤
              </ThemedText.TextSecondary>
              <Input
                type="number"
                className="threshold-remove-input"
                value={thresholdRemoveValue}
                onChange={onThresholdRemoveInput}
                align="left"
                style={{ width: '32px' }}
                disabled={isPoolOwner}
              />
              <ThemedText.TextPrimary
                fontSize={16}
                fontWeight={700}
                color={curr == 2 ? 'textPrimary' : 'textSecondary'}
              >
                %
              </ThemedText.TextPrimary>
            </StyledInputBox>
          </Box>
        </StyledInfo3>
      </StyledInfoBox>
      <StyledRectangleBox>
        <StyledRectanglePosition rectangle={utilizationValue}>
          <RectangleIcon1 />
        </StyledRectanglePosition>
        <StyledRectanglePosition rectangle={thresholdValue}>
          <RectangleIcon2 />
        </StyledRectanglePosition>
        <StyledRectanglePosition rectangle={thresholdRemoveValue}>
          <RectangleIcon3 />
        </StyledRectanglePosition>
      </StyledRectangleBox>
      <Column width="100%" gap="12px" mt="6px">
        <StyledBox>
          <RowBetween className="item">
            <Box display="flex" alignItems="center" gap="8px">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>Maximum Leverage</Trans>
              </ThemedText.TextSecondary>
              <Tips tipsStr="Maximum Leverage" />
            </Box>
            <StyledInputBox>
              <Input
                type="number"
                className="leverage-input"
                value={leverageValue}
                onChange={onLeverageValueInput}
                onBlur={leverageValueInputBlur}
                align="left"
                style={{ width: '32px' }}
                disabled={isPoolOwner}
              />
              <ThemedText.TextPrimary
                fontSize={16}
                fontWeight={700}
                color={curr == 2 ? 'textPrimary' : 'textSecondary'}
              >
                x
              </ThemedText.TextPrimary>
            </StyledInputBox>
          </RowBetween>
          <RowBetween className="item">
            <Box display="flex" alignItems="center" gap="8px">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>TWAP time frame</Trans>
              </ThemedText.TextSecondary>
              <Tips tipsStr="TWAP time frame" />
            </Box>
            <StyledInputBox>
              <Input
                type="number"
                className="TWAP-time-input"
                value={timeValue_TWAP}
                onChange={onTimeInput_TWAP}
                onBlur={timeBlur_TWAP}
                align="left"
                style={{ width: '32px' }}
                disabled={isPoolOwner}
              />
              <ThemedText.TextPrimary
                fontSize={16}
                fontWeight={700}
                color={curr == 2 ? 'textPrimary' : 'textSecondary'}
              >
                s
              </ThemedText.TextPrimary>
            </StyledInputBox>
          </RowBetween>
        </StyledBox>
        <StyledBox>
          <RowBetween className="item">
            <Box display="flex" alignItems="center" gap="8px">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>Close position slippage time frame</Trans>
              </ThemedText.TextSecondary>
              <Tips tipsStr="Close position slippage time frame" />
            </Box>
            <StyledInputBox>
              <Input
                type="number"
                className="close-time-input"
                value={timeValue_close}
                onChange={onTimeInput_close}
                onBlur={timeBlur_close}
                align="left"
                style={{ width: '32px' }}
                disabled={isPoolOwner}
              />
              <ThemedText.TextPrimary
                fontSize={16}
                fontWeight={700}
                color={curr == 2 ? 'textPrimary' : 'textSecondary'}
              >
                min
              </ThemedText.TextPrimary>
            </StyledInputBox>
          </RowBetween>
        </StyledBox>
      </Column>
    </Column>
  )
}
