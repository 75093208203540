import { Trans } from '@lingui/macro'
import { ManagerButton } from 'components/Button'
import Column from 'components/Column'
import { IconLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import dayjs from 'dayjs'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useState } from 'react'
import { ThemedText } from 'theme'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { ReactComponent as NftIcon } from '../../assets/imgs/nft.svg'
import ManageModal from './LockModal/ManageModal'
import { LockSortMethod } from './state'
import { LockStakeListProps } from './types'

/* Token Row: skeleton row component */
function LockRow({
  poolInfo,
  amount,
  votePower,
  expires,
  manager,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  expires: ReactNode
  amount: ReactNode
  votePower: ReactNode
  poolInfo: ReactNode
  manager: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="pool-cell">{poolInfo}</TextLeftCell>
        <TextRightCell data-testid="amount-cell">{amount}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="vote-power-cell">{votePower}</TextLeftCell>
        <TextRightCell data-testid="expires-cell">{expires}</TextRightCell>
      </RowBetween>
      <TextRightCell data-testid="manager-cell">{manager}</TextRightCell>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LockRow
      $loading
      poolInfo={
        <Row gap="sm">
          <IconLoadingBubble width="48px" height="48px" />
          <SmallLoadingBubble />
        </Row>
      }
      amount={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LockSortMethod.LOCK_AMOUNT}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      votePower={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LockSortMethod.VOTING_POWER}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      expires={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LockSortMethod.EXPIRES}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      manager={
        <Row justify="flex-end">
          <ManagerButton>Manager</ManagerButton>
        </Row>
      }
      {...props}
    />
  )
}

interface LoadedRowProps {
  lockListIndex: number
  lockListLength: number
  lock: LockStakeListProps
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { lock, lockListIndex, lockListLength } = props
  const [tokenId, setTokenId] = useState<any>('')
  const [endTime, setEndTime] = useState<any>('')
  const [veAmount, setVeAmount] = useState<any>('')
  const [showManage, setShowManage] = useState<boolean>(false)
  const handleDismissManage = () => {
    setShowManage(false)
  }
  const handlerManage = (lock: any) => {
    setTokenId(lock.tokenId)
    setEndTime(lock.endTime)
    setVeAmount(lock.voteAmount)
    setShowManage(true)
  }

  return (
    <>
      <div ref={ref} data-testid={`lock-table-row-${lock?.tokenId}`}>
        <LockRow
          poolInfo={
            <Row gap="sm">
              <NftIcon width={48} height={48} />
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                #{lock.tokenId}
              </ThemedText.TextPrimary>
            </Row>
          }
          amount={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LockSortMethod.LOCK_AMOUNT}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {formatCurrencyAmount(lock.lockAmount, 4)} ROX
              </ThemedText.TextPrimary>
            </Column>
          }
          votePower={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LockSortMethod.VOTING_POWER}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {formatCurrencyAmount(lock.voteAmount, 4)} {lock.voteAmount?.currency.symbol}
              </ThemedText.TextPrimary>
            </Column>
          }
          expires={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LockSortMethod.EXPIRES}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {dayjs(Number(lock.endTime) * 1000).format('YYYY-MM-DD')}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12}>
                Expired in{' '}
                {formatAmount(
                  Math.round((Number(lock.endTime) - Math.round(Number(new Date()) / 1000)) / 86400),
                  0,
                  true
                )}{' '}
                Days
              </ThemedText.TextSecondary>
            </Column>
          }
          manager={
            <Row justify="end">
              <ManagerButton onClick={() => handlerManage(lock)}>Manager</ManagerButton>
            </Row>
          }
          first={lockListIndex === 0}
          last={lockListIndex === lockListLength - 1}
        />
      </div>
      {showManage && (
        <ManageModal
          isOpen={showManage}
          onDismiss={handleDismissManage}
          tokenId={tokenId}
          endTime={endTime}
          veAmount={veAmount}
          lockAmount={lock.lockAmount}
        />
      )}
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
