import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ClaimButton } from 'components/Button'
import Column from 'components/Column'
import { ToastError } from 'components/Toast'
import { useVoter } from 'hooks/useContract'
import { useLiquidityData } from 'pages/Liquidity/hooks'
import { LiquidityTable } from 'pages/Liquidity/LiquidityTable'
import { useVoteData } from 'pages/Vote/hooks'
import { useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { handlerError } from 'utils/formatError'

import Tab from '../Tab'
import { useLiquidityTotalUsd, useVoteTotalUsd } from './hooks'
import { VoteList } from './Vote'

const RewardBox = styled(Box)`
  margin-top: 24px;
`

export default function Reward() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const [curr, setCurr] = useState<any>(0)
  const tabList = [
    'LP',
    'Vote',
    //  'Trade to Earn',
  ]
  const liquidityList = useLiquidityData()

  const masterchefAddrList = liquidityList?.map((item) => item.masterChefAddress)

  const totalRewards = useLiquidityTotalUsd()
  const voteUsd = useVoteTotalUsd()

  // const voteUsd = 0
  // const masterChefContract = useMasterChefV3(item.masterChefAddress)

  // TODO:  TotalLiquityReward
  // const totalRewardU =0;
  //  liquidityList?.map((item) => {
  //   const masterChefContract = useMasterChefV3(item.masterChefAddress)
  //   const rewardList = await masterChefContract?.getRewardList()
  //   rewardList?.map((rewardTokenAddr)=>{
  //     //get Token Price by rewardTokenAddr
  //     const rewardTokenPrice = 0
  //     const earnAmount =await masterChefContract.earned(rewardTokenAddr,account)
  //     if(rewardTokenPrice >0 &&  earnAmount > 0){
  //       totalRewardU +=   earnAmount *rewardTokenPrice
  //     }
  //   })
  // })

  const voterContract = useVoter()

  const voteList = useVoteData()
  const votingRewardAddrList = voteList?.map((item) => item.votingRewardAddress)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const handleClaim = async () => {
    if (curr == 0) {
      if (!masterchefAddrList || !voterContract) return
      setAttemptingTxn(true)
      try {
        const response = await voterContract.claimMasterChefs(masterchefAddrList)
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.CLAIM_REWARDS,
          token0Address: '',
          token1Address: '',
        })
        setTxHash(response.hash)
      } catch (error) {
        setAttemptingTxn(false)
        setTxError(handlerError(error))
        ToastError(handlerError(error))
        console.log('claimRewards error', error)
      }
    } else {
      if (!votingRewardAddrList) return
      //TODO:
      // await voterContract?.claimBribes(votingRewardAddrList, tokenIdListInput)
    }
  }

  return (
    <RewardBox>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Tab tabList={tabList} curr={curr} setCurr={setCurr} />
        <Box
          display="flex"
          justifyContent={isSmallScreen ? 'space-between' : 'flex-start'}
          alignItems="center"
          gap={isSmallScreen ? '16px' : '32px'}
          width={isSmallScreen ? ' 100%' : 'auto'}
        >
          <Column align="center" gap="xs">
            <ThemedText.TextSecondary fontSize={14} fontWeight={300}>
              <Trans>Total Rewards</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontSize={18} fontWeight={800}>
              ${curr == 0 ? totalRewards?.toFixed(2) : voteUsd?.toFixed(2)}
            </ThemedText.TextPrimary>
          </Column>
          <ClaimButton width="150px" onClick={handleClaim}>
            Claim All
          </ClaimButton>
        </Box>
      </Box>
      <Box mt="24px">
        {curr == 0 && <LiquidityTable />}
        {curr == 1 && <VoteList />}
      </Box>
    </RewardBox>
  )
}
function setAttemptingTxn(arg0: boolean) {
  throw new Error('Function not implemented.')
}
