import { Trans } from '@lingui/macro'
import { useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import BigNumberj from 'bignumber.js'
import { ButtonSecondary, ClaimButton, Pending } from 'components/Button'
import Column from 'components/Column'
import Row from 'components/Row'
import { ToastError } from 'components/Toast'
import { BigNumber } from 'ethers/lib'
import { useRewardsDistributor } from 'hooks/useContract'
import { useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useCallback, useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'

import { useGetLockTokenIds } from './hooks'
import CreateLockModal from './LockModal/CreateLockModal'
import MergeModal from './LockModal/MergeModal'
const LockContentBox = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`

export default function LockRewardWrapper() {
  const isMobileScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`)
  const [showCreateLock, setShowCreateLock] = useState(false)
  const [showMergeLock, setShowMergeLock] = useState(false)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const handleDismissCreate = () => {
    setShowCreateLock(false)
  }
  const handleDismissMerge = () => {
    setShowMergeLock(false)
  }
  const { tokenIds } = useGetLockTokenIds()

  const rewardContract = useRewardsDistributor()

  const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])

  const handleClaim = useCallback(async () => {
    if (!tokenIds || !rewardContract) return

    setAttemptingTxn(true)
    try {
      const response = await rewardContract.claim_many(tokenIds)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('masterChefContract claimRewards error', error)
    }
  }, [tokenIds, rewardContract, addTransaction])

  const allResult = useSingleContractMultipleData(rewardContract, 'claimable', inputs)
  //
  // const voteRewardRteUsd = voteRewardRte.reduce((pre: BigNumber, cur: any, index: any) => {
  //        const tokenPirce = tokensPrice[cur.token.toLocaleLowerCase()]
  //        const rate = cur.rate
  //        const tokenapr = fromWei(rate, Number(tokenPirce.decimals)).times(3600).times(24).times(365)
  //        const amount = tokenapr.times(tokenPirce?.derivedETH || 0).times(ethprice)
  //        pre = pre.plus(amount)
  //        return pre
  //      }, BN(0))
  //
  //
  const lockRewardVeAmount = useMemo(() => {
    return allResult.reduce((acc: BigNumberj, curr: any) => {
      if (curr?.result?.[0]) {
        acc = acc.plus(curr?.result?.[0].toString())
      }
      return acc
    }, BN(0))
  }, [allResult])

  return (
    <LockContentBox>
      <Box display="flex" justifyContent="space-between" flexDirection={isMobileScreen ? 'column' : 'row'} gap="16px">
        <Row width={isMobileScreen ? '100%' : 'auto'} gap="xmd">
          <ButtonSecondary
            height="40px"
            width={isMobileScreen ? '100%' : 'auto'}
            onClick={() => setShowCreateLock(true)}
          >
            Create Lock
          </ButtonSecondary>
          <ButtonSecondary
            height="40px"
            width={isMobileScreen ? '100%' : 'auto'}
            onClick={() => setShowMergeLock(true)}
          >
            Merge
          </ButtonSecondary>
        </Row>
        <Box
          display="flex"
          justifyContent={isMobileScreen ? 'space-between' : 'flex-end'}
          width={isMobileScreen ? '100%' : 'auto'}
          gap="16px"
        >
          <Column align={isMobileScreen ? 'flex-start' : 'flex-end'}>
            <ThemedText.TextSecondary fontSize={12}>
              <Trans>Lock Reward</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontSize={17} fontWeight={500} mt="2px">
              {formatAmount(fromWei(lockRewardVeAmount.toString(), 18), 4, true)} ROX
            </ThemedText.TextPrimary>
          </Column>
          <ClaimButton disabled={attemptingTxn} onClick={handleClaim}>
            {attemptingTxn ? <Pending /> : 'Claim'}
          </ClaimButton>
        </Box>
      </Box>
      {showCreateLock && <CreateLockModal isOpen={showCreateLock} onDismiss={handleDismissCreate} />}
      {showMergeLock && <MergeModal isOpen={showMergeLock} onDismiss={handleDismissMerge} />}
    </LockContentBox>
  )
}
