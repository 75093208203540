import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import { ManagerButton } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { useCurrency } from 'hooks/Tokens'
import { useTradeRouter } from 'hooks/useContract'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { ExecuteBtn, StyledBonus } from '../LimitList/LimitRow'
import { LoadedRowProps } from './LiquidationRow'
import { LiquidationSortMethod } from './state'

/* Token Row: skeleton row component */
function LiquidationRow({
  positions,
  liqPrice,
  markPrice,
  size,
  bonus,
  action,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  size: ReactNode
  bonus: ReactNode
  liqPrice: ReactNode
  markPrice: ReactNode
  positions: ReactNode
  action: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="pool-cell">{positions}</TextLeftCell>
        <TextRightCell data-testid="size-cell">{size}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="liqPrice-cell">{liqPrice}</TextLeftCell>
        <TextRightCell data-testid="vote-power-cell">{markPrice}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextRightCell data-testid="bonus-cell">{bonus}</TextRightCell>
        <TextRightCell data-testid="action-cell">{action}</TextRightCell>
      </RowBetween>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LiquidationRow
      $loading
      positions={
        <Row gap="sm">
          <IconLoadingBubble width="48px" height="48px" />
          <SmallLoadingBubble />
        </Row>
      }
      liqPrice={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LiquidationSortMethod.LiqPrice}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      markPrice={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LiquidationSortMethod.MarkPrice}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      size={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LiquidationSortMethod.Size}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      bonus={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{LiquidationSortMethod.Bonus}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      action={
        <Row justify="flex-end">
          <ManagerButton>Manager</ManagerButton>
        </Row>
      }
      {...props}
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { liquidationListIndex, liquidationListLength, liquidation, token01 } = props
  const theme = useTheme()
  const currency0 = useCurrency(liquidation.v3Pool.token0.id)
  const currency1 = useCurrency(liquidation.v3Pool.token1.id)
  const decimals = liquidation.long0 ? currency1?.decimals : currency0?.decimals
  const feesymbol = liquidation.long0 ? currency0?.symbol : currency1?.symbol
  const feesdecimals = liquidation.long0 ? currency0?.decimals : currency1?.decimals
  const sizeymbol = liquidation.long0 ? currency1?.symbol : currency0?.symbol

  const lever = useMemo(() => {
    const collateral = liquidation.collateral
    return BN(liquidation.size).div(collateral)
  }, [liquidation.collateral, liquidation.size])

  const routerContract = useTradeRouter()
  const twapPrice = liquidation.twapPrice

  const canExec = useMemo(() => {
    if (liquidation.isLiq) return true
    if (!twapPrice) {
      return false
    }
    if (token01) {
      const execPirce = liquidation.close0Price
      if (!liquidation.long0) {
        return twapPrice >= execPirce
      } else {
        return twapPrice <= execPirce
      }
    } else {
      const execPirce = 1 / liquidation.close0Price
      if (liquidation.long0) {
        return 1 / twapPrice >= execPirce
      } else {
        return 1 / twapPrice <= execPirce
      }
    }
  }, [liquidation, token01, twapPrice])

  const handleEecu = async () => {
    try {
      await routerContract?.liquidatePosition(liquidation.prepPoolAddress, liquidation.key)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div ref={ref} data-testid={`liquidation-table-row-${liquidation?.tokenId}`}>
        <LiquidationRow
          positions={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LiquidationSortMethod.Positions}</Trans>
              </ThemedText.TextSecondary>
              <Row>
                <DoubleCurrencyLogo
                  size={40}
                  margin
                  currency0={token01 ? currency1 ?? undefined : currency0 ?? undefined}
                  currency1={token01 ? currency0 ?? undefined : currency1 ?? undefined}
                />
                <Column gap="xs" align="flex-start" ml="8px">
                  <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                    {token01
                      ? `${currency0?.symbol}/${currency1?.symbol}`
                      : `${currency1?.symbol}/${currency0?.symbol}`}
                  </ThemedText.TextPrimary>
                  <Row gap="xs">
                    <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                      {lever.toFixed(2)}x
                    </ThemedText.TextSecondary>
                    {token01 ? (
                      liquidation.long0 ? (
                        <LongIcon />
                      ) : (
                        <ShortIcon />
                      )
                    ) : !liquidation.long0 ? (
                      <LongIcon />
                    ) : (
                      <ShortIcon />
                    )}
                  </Row>
                </Column>
              </Row>
            </Column>
          }
          liqPrice={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LiquidationSortMethod.LiqPrice}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {token01 ? (liquidation.long0 ? '<' : '>') : !liquidation.long0 ? '<' : '>'}{' '}
                {countZeros(
                  token01 ? liquidation.close0Price : liquidation.close0Price == 0 ? 0 : 1 / liquidation.close0Price
                )}
              </ThemedText.TextPrimary>
            </Column>
          }
          markPrice={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LiquidationSortMethod.MarkPrice}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {countZeros(token01 ? twapPrice : 1 / twapPrice)}
              </ThemedText.TextPrimary>
            </Column>
          }
          size={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LiquidationSortMethod.Size}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary>
                {' '}
                {countZeros(fromWei(liquidation.size, decimals).toFixed())} {sizeymbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          bonus={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>{LiquidationSortMethod.Bonus}</Trans>
              </ThemedText.TextSecondary>
              <StyledBonus className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}>
                {countZeros(fromWei(liquidation.liqResv, feesdecimals).toFixed())} {feesymbol}
              </StyledBonus>
            </Column>
          }
          action={
            <Row justify="end">
              <ExecuteBtn onClick={handleEecu} disabled={!canExec}>
                <Trans>{canExec ? 'Execute' : 'Not available'}</Trans>
              </ExecuteBtn>
            </Row>
          }
          first={liquidationListIndex === 0}
          last={liquidationListIndex === liquidationListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
