import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { GridContainer, NoTokensStateM } from 'pages/Liquidity/Table'
import { AlertTriangle } from 'react-feather'

import { LoadedRow, LoadingRow } from './HistoryRow-m'

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function TableM() {
  const loadingHistorys = false
  const historyList = [{}, {}]
  /* loading and error state */
  if (loadingHistorys && !historyList) {
    return <LoadingTokenTable rowCount={5} />
  } else if (!historyList) {
    return (
      <NoTokensStateM
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading historyList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (historyList?.length === 0) {
    return <NoTokensStateM message={<Trans>No historyList found</Trans>} />
  } else {
    return (
      <>
        <TableDataContainer>
          {historyList.map((history, index) => (
            <LoadedRow key={index} historyListIndex={index} historyListLength={historyList.length} history={history} />
          ))}
        </TableDataContainer>
      </>
    )
  }
}
