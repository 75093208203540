import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const LockPickerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  width: 100%;
  margin-top: 8px;
`

interface PickerProps {
  title: string
}
export default function LockPicker({ title }: PickerProps) {
  // const time = getEndRound()
  // const day = dayjs.unix(time).format('YYYY-MM-DD')
  return (
    <LockPickerBox>
      <ThemedText.HeadNav054>
        <Trans>{title}</Trans>
      </ThemedText.HeadNav054>
      {/* <ThemedText.TextSecondary fontSize={14}>{day}</ThemedText.TextSecondary> */}
      {/* <BasicDatePicker /> */}
    </LockPickerBox>
  )
}
