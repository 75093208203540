import { useMediaQuery } from '@mui/material'
import { BREAKPOINTS } from 'theme'

import Table from './LimitTable'
import TableM from './LimitTable-m'

function LimitList() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  return <>{isSmallScreen ? <TableM /> : <Table />}</>
}

// eslint-disable-next-line import/no-unused-modules
export default LimitList
