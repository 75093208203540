import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { MediumLoadingBubble } from 'components/Loading'
import { StyledTableRow } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { TextLeftCell } from 'pages/Lock/LockRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { TokenSortMethod } from './state'

const StyledHistoryRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  grid-template-columns: 1fr 1fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
`
const StyledHeaderRow = styled(StyledHistoryRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 0;

  &:hover {
    background-color: transparent;
  }
`

/* Token Row: skeleton row component */
function HistoryRow({
  header,
  invited,
  rewards,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  rewards: ReactNode
  invited: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="invited-cell">{invited}</TextLeftCell>
      <TextLeftCell data-testid="rewards-cell">{rewards}</TextLeftCell>
    </>
  )
  const rowTrCells = (
    <>
      <TextLeftCell data-testid="invited-cell">
        <Box p="16px 0" width="100%" height="100%">
          {invited}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="rewards-cell">
        <Box p="16px 0" width="100%" height="100%">
          {rewards}
        </Box>
      </TextLeftCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="history-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledHistoryRow {...rest}>{rowTrCells}</StyledHistoryRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <HistoryRow
      header={true}
      invited={<Trans>{TokenSortMethod.INVITED}</Trans>}
      rewards={<Trans>{TokenSortMethod.REWARDS}</Trans>}
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <HistoryRow
      key={props?.first + '' + props?.last}
      header={false}
      $loading
      invited={<MediumLoadingBubble />}
      rewards={<MediumLoadingBubble />}
    />
  )
}

interface LoadedRowProps {
  historyListIndex: number
  historyListLength: number
  history: any
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { history, historyListIndex, historyListLength } = props

  return (
    <>
      <div ref={ref} data-testid={`history-table-row-${history?.tokenId}`}>
        <HistoryRow
          header={false}
          invited={
            <ThemedText.TextPrimary
              fontWeight={500}
              fontSize={16}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              0x124...15ef
            </ThemedText.TextPrimary>
          }
          rewards={
            <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
              10.00 USDC
            </ThemedText.TextPrimary>
          }
          first={historyListIndex === 0}
          last={historyListIndex === historyListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
