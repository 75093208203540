import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import Column from 'components/Column'
import { NavDropdown } from 'components/NavBar/NavDropdown'
import { scrollbarStyle } from 'components/SearchModal/CurrencyList/index.css'
import dayjs from 'dayjs'
import { VotedItem, VotedList } from 'pages/Vote/HeaderWrapper'
import { ThemedText } from 'theme'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

interface DropdownProps {
  list: any
  handleChange: any
  toggleShow: any
}
export default function DropdownList({ list, handleChange, toggleShow }: DropdownProps) {
  return (
    <NavDropdown right="0">
      <VotedList className={scrollbarStyle}>
        {list?.map((item: any) => (
          <div
            className="voteItem"
            key={item.tokenId}
            onClick={() => {
              handleChange(item), toggleShow()
            }}
          >
            <VotedItem>
              <Column gap="sm" align="flex-start">
                <Box display="flex" gap="4px">
                  <ThemedText.TextPrimary fontSize={16}>
                    <Trans>NFT ID:</Trans>
                  </ThemedText.TextPrimary>
                  <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                    <Trans>#{item.tokenId}</Trans>
                  </ThemedText.TextPrimary>
                  {item.isVoted == true && (
                    <Box
                      marginLeft="20px"
                      padding="2px 8px"
                      style={{
                        borderRadius: '2px',
                        background: 'var(--light-alert, #CA8A0D)',
                      }}
                      display="flex"
                      alignItems="center"
                    >
                      <ThemedText.TextSecondary fontSize={12}>
                        <Trans>Voted</Trans>
                      </ThemedText.TextSecondary>
                    </Box>
                  )}
                </Box>
                <Box display="flex" gap="16px">
                  <ThemedText.TextSecondary fontSize={12}>
                    <Trans>Lock Amount:</Trans> {formatCurrencyAmount(item.lockAmount, 4)}{' '}
                    {item.lockAmount?.currency.symbol}
                  </ThemedText.TextSecondary>
                  <ThemedText.TextSecondary fontSize={12}>
                    <Trans>Expires:</Trans> {dayjs(Number(item.endTime) * 1000).format('YYYY-MM-DD')}
                  </ThemedText.TextSecondary>
                </Box>
              </Column>
            </VotedItem>
          </div>
        ))}
      </VotedList>
    </NavDropdown>
  )
}
