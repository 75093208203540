import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import Column from 'components/Column'
import Row from 'components/Row'
import Tips from 'components/Tips'
import { useEthPriceUSD, useRoxPriceETH } from 'hooks/useQueryLiquidity'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'
import { ThemedText } from 'theme/components/text'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'

import { useAvgtime, useCirLocked, useLockAmount, useTVP } from './hooks'

const LockInfoBox = styled.div`
  margin-top: 30px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
`
export default function LockInfo() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isMobileScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`)

  const amount = useLockAmount()
  const cirLocked = useCirLocked()
  const avgTime = useAvgtime()

  const tvp = useTVP()
  const ethPrice = useEthPriceUSD()
  const roxPrice = useRoxPriceETH()
  return (
    <LockInfoBox>
      <Box
        display="flex"
        flexDirection={isMobileScreen ? 'column' : 'row'}
        justifyContent="space-around"
        gap={isMobileScreen ? '20px' : '8px'}
        className="lockInfo"
      >
        <Column align="center">
          <Row width="auto" gap="sm">
            <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
              <Trans>TVL</Trans>
            </ThemedText.TextSecondary>
            <Tips tipsStr="Total Value Locked" />
          </Row>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 14 : 16} fontWeight={700} mt="8px">
            {formatAmount(amount?.toSignificant(), 2, true)} ROX
          </ThemedText.TextPrimary>
          <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 14} mt="4px">
            $
            {roxPrice && ethPrice && amount
              ? formatAmount(
                BN(amount.toSignificant()).multipliedBy(ethPrice).multipliedBy(roxPrice).toString(),
                2,
                true
              )
              : 0}
          </ThemedText.TextSecondary>
        </Column>
        <Column align="center">
          <Row width="auto" gap="sm">
            <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
              <Trans>TVP</Trans>
            </ThemedText.TextSecondary>
            <Tips tipsStr="Total Voting Power" />
          </Row>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700} mt="8px">
            {formatAmount(tvp?.toSignificant(), 2, true)} veROX
          </ThemedText.TextPrimary>
        </Column>
        <Column align="center">
          <Row width="auto" gap="sm">
            <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
              <Trans>%Cir. Locked</Trans>
            </ThemedText.TextSecondary>
            <Tips tipsStr="Total Locked / Circulating Supply" />
          </Row>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700} mt="8px">
            {!cirLocked ? 0 : cirLocked.toFixed(2)}%
          </ThemedText.TextPrimary>
        </Column>
        <Column align="center">
          <ThemedText.TextSecondary fontSize={isSmallScreen ? 12 : 16}>
            <Trans>Avg. Lock Time</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={isSmallScreen ? 16 : 24} fontWeight={700} mt="8px">
            {avgTime ? formatAmount(avgTime?.toFixed(2), 2, true) : 0} Years
          </ThemedText.TextPrimary>
        </Column>
      </Box>
    </LockInfoBox>
  )
}
