import { Trans } from '@lingui/macro'
import Column from 'components/Column'
import Row from 'components/Row'
import styled from 'styled-components/macro'

import { ActionButtonResponsiveRow, IncreasePrimaryButton } from '../StyledPosition'
import IncreasePool from './IncreasePool'
import RemovePool from './RemovePool'

const PositionNavBox = styled(Row)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  padding: 16px 16px 8px;
  border-radius: 16px 16px 0px 0px;
`

export default function PositionNavList({
  curr,
  setCurr,
  currencyIdA,
  currencyIdB,
  tokenId,
  feeAmountFromUrl,
  setAttemptingTxn,
  setTxHash,
  setTxError,
  setshowIncreasePool,
  setshowRemovePool,
}: {
  curr: number | string
  setCurr: any
  currencyIdA: string
  currencyIdB: string
  tokenId: number
  feeAmountFromUrl: string
  setAttemptingTxn: any
  setTxHash: any
  setTxError: any
  setshowIncreasePool?: any
  setshowRemovePool?: any
}) {
  return (
    <Column align="stretch" width="100%" mt="-50px">
      <PositionNavBox width="50%">
        <ActionButtonResponsiveRow>
          <IncreasePrimaryButton
            active={curr == 1}
            onClick={() => {
              setCurr(1)
            }}
          >
            <Trans>+ Increase</Trans>
          </IncreasePrimaryButton>
          <IncreasePrimaryButton
            active={curr == 2}
            onClick={() => {
              setCurr(2)
            }}
          >
            <Trans>- Remove</Trans>
          </IncreasePrimaryButton>
        </ActionButtonResponsiveRow>
        <div></div>
      </PositionNavBox>

      {curr == 1 && (
        <IncreasePool
          currencyIdA={currencyIdA}
          currencyIdB={currencyIdB}
          tokenId={tokenId}
          feeAmountFromUrl={feeAmountFromUrl}
          setshowIncreasePool={setshowIncreasePool}
          setAttemptingTxn={setAttemptingTxn}
          setTxHash={setTxHash}
          setTxError={setTxError}
        />
      )}
      {curr == 2 && (
        <RemovePool
          tokenId={tokenId}
          setAttemptingTxn={setAttemptingTxn}
          setTxHash={setTxHash}
          setTxError={setTxError}
          setshowRemovePool={setshowRemovePool}
        />
      )}
    </Column>
  )
}
