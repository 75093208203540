import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import { ButtonSecondary, Pending } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { StyledTableRow } from 'components/Table'
import { ToastError } from 'components/Toast'
import { MAX_WIDTH_MEDIA_BREAKPOINT, SMALL_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import { useOrderBookContract } from 'hooks/useContract'
import { IOrder } from 'hooks/useQueryLiquidity'
import { TextLeftCell } from 'pages/Lock/LockRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useCallback, useMemo, useState } from 'react'
import { Box } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { css, useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { fromSqrt96Wei, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { handlerError } from 'utils/formatError'

import { LimitSortMethod } from './state'

const StyledLimitRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: center;
  grid-template-columns: 200px 1fr 1fr 1fr 1fr 0.8fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
  .bg {
    height: 100%;
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }
`

const StyledTrRow = styled(StyledLimitRow)``

const StyledHeaderRow = styled(StyledLimitRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

export const ExecuteBtn = styled(ButtonSecondary)`
  min-height: 36px;
  width: auto;
  font-size: 14px;
  &:disabled {
    border: none;
  }
`

export const StyledBonus = styled.div`
  font-size: 16px;
  font-weight: 700;
`

/* Token Row: skeleton row component */
function LimitRow({
  header,
  orders,
  triggerPrice,
  markPrice,
  size,
  bonus,
  action,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  size: ReactNode
  triggerPrice?: ReactNode
  markPrice: ReactNode
  orders: ReactNode
  bonus: ReactNode
  action: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="orders-cell">{orders}</TextLeftCell>
      <TextLeftCell data-testid="trigger-price-cell">{triggerPrice}</TextLeftCell>
      <TextLeftCell data-testid="mark-price-cell">{markPrice}</TextLeftCell>
      <TextLeftCell data-testid="size-cell">{size}</TextLeftCell>
      <TextLeftCell data-testid="bonus-cell">{bonus}</TextLeftCell>
      <TextLeftCell data-testid="total-rewards-cell">{action}</TextLeftCell>
    </>
  )
  const rowTrCells = (
    <>
      <TextLeftCell data-testid="orders-cell">
        <Box p="16px 0" width="100%" height="100%">
          {orders}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="trigger-price-cell">
        <Box p="16px 0" width="100%" height="100%">
          {triggerPrice}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="mark-price-cell">
        <Box p="16px 0" width="100%" height="100%">
          {markPrice}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="size-cell">
        <Box p="16px 0" width="100%" height="100%">
          {size}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="bonus-cell">
        <Box p="16px 0" width="100%" height="100%">
          {bonus}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="total-rewards-cell">
        <Box p="16px 0" width="100%" height="100%">
          {action}
        </Box>
      </TextLeftCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="limit-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledTrRow {...rest}>{rowTrCells}</StyledTrRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LimitRow
      header={true}
      orders={<Trans>{LimitSortMethod.Orders}</Trans>}
      triggerPrice={<Trans>{LimitSortMethod.TriggerPrice}</Trans>}
      markPrice={<Trans>{LimitSortMethod.MarkPrice}</Trans>}
      size={<Trans>{LimitSortMethod.Size}</Trans>}
      bonus={<Trans>{LimitSortMethod.Bonus}</Trans>}
      action={<Trans>{LimitSortMethod.Action}</Trans>}
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LimitRow
      header={false}
      $loading
      orders={
        <Row gap="xs">
          <IconLoadingBubble width="40px" height="40px" />
          <MediumLoadingBubble />
        </Row>
      }
      triggerPrice={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      markPrice={
        <Row justify="flex-start" gap="xs">
          <LoadingBubble />
        </Row>
      }
      size={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      bonus={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      action={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      {...props}
    />
  )
}

export interface LoadedRowProps {
  token01: boolean
  limitListIndex: number
  limitListLength: number
  limit: IOrder
}

/* Loaded State: row component with token information */
// eslint-disable-next-line react/display-name
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { limitListIndex, limitListLength, limit, token01 } = props

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [, setTxHash] = useState<string>('')
  const [, setTxError] = useState<string>('')

  const theme = useTheme()
  const currency0 = useCurrency(limit.token0.id)
  const currency1 = useCurrency(limit.token1.id)
  const twapPrice = fromSqrt96Wei(limit.twapPrice.toString(), currency0?.decimals, currency1?.decimals).toNumber()
  const openPrice = fromSqrt96Wei(limit.triggerPrice?.toString(), currency0?.decimals, currency1?.decimals).toNumber()
  const { account } = useActiveChainId()
  const orderbookContract = useOrderBookContract()

  const decimals = limit.long0 ? currency1?.decimals : currency0?.decimals
  const feesymbol = limit.long0 ? currency0?.symbol : currency1?.symbol
  const feesdecimals = limit.long0 ? currency0?.decimals : currency1?.decimals
  const sizeymbol = limit.long0 ? currency1?.symbol : currency0?.symbol

  const addTransaction = useTransactionAdder()

  const handlerIncrease = useCallback(async () => {
    if (!account || !limit.key || !orderbookContract) return
    setAttemptingTxn(true)
    try {
      const response = await orderbookContract.executeIncreaseOrder(limit.key, account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.EXECUTE_ORDERLIST,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('Execute error', error)
    }
  }, [account, addTransaction, limit, orderbookContract])

  const handlerDecrease = useCallback(async () => {
    if (!account || !limit.key || !orderbookContract) return
    setAttemptingTxn(true)

    try {
      const response = await orderbookContract.executeDecreaseOrder(limit.key, account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.EXECUTE_ORDERLIST,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('Execute error', error)
    }
  }, [account, addTransaction, limit.key, orderbookContract])

  const handlerExecute = useCallback(() => {
    limit.__typename == 'IncreaseOrder' ? handlerIncrease() : handlerDecrease()
  }, [handlerDecrease, handlerIncrease, limit.__typename])

  const canExec = useMemo(() => {
    if (!twapPrice) {
      return false
    }
    if (token01) {
      if (limit.triggerAboveThreshold == true) {
        return twapPrice >= openPrice
      } else {
        return twapPrice <= openPrice
      }
    } else {
      if (limit.triggerAboveThreshold == false) {
        return 1 / twapPrice >= 1 / openPrice
      } else {
        return 1 / twapPrice <= 1 / openPrice
      }
    }
  }, [limit.triggerAboveThreshold, openPrice, token01, twapPrice])

  return (
    <>
      <div ref={ref} data-testid={`limit-table-row-${limit.key}`}>
        <LimitRow
          header={false}
          orders={
            <Row>
              <DoubleCurrencyLogo
                size={40}
                margin
                currency0={token01 ? currency1 ?? undefined : currency0 ?? undefined}
                currency1={token01 ? currency0 ?? undefined : currency1 ?? undefined}
              />
              <Column gap="xs" align="flex-start" ml="16px">
                <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                  {token01 ? `${currency0?.symbol}/${currency1?.symbol}` : `${currency1?.symbol}/${currency0?.symbol}`}
                </ThemedText.TextPrimary>
                <Row gap="xs">
                  {token01 ? limit.long0 ? <LongIcon /> : <ShortIcon /> : !limit.long0 ? <LongIcon /> : <ShortIcon />}
                </Row>
              </Column>
            </Row>
          }
          triggerPrice={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {token01
                  ? limit.triggerAboveThreshold == true
                    ? ' >'
                    : '<'
                  : !limit.triggerAboveThreshold == true
                  ? ' >'
                  : '<'}{' '}
                {token01 ? countZeros(openPrice) : countZeros(1 / openPrice)}
              </ThemedText.TextPrimary>
            </Column>
          }
          markPrice={
            <Row justify="flex-start" gap="xs">
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {countZeros(token01 ? twapPrice : 1 / twapPrice)}
              </ThemedText.TextPrimary>
            </Row>
          }
          size={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={13}>
                {limit.__typename == 'IncreaseOrder' ? 'Increase' : 'Decrease'}
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary>
                {limit.__typename == 'IncreaseOrder' ? '+' : '-'}{' '}
                {countZeros(fromWei(limit.sizeDelta.toString(), decimals).toFixed())} {sizeymbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          bonus={
            <Column align="flex-start" width="100%" gap="xs">
              <StyledBonus className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}>
                {countZeros(fromWei(limit.executionFee.toString(), feesdecimals).toFixed())} {feesymbol}
              </StyledBonus>
            </Column>
          }
          action={
            <Column align="flex-start" gap="xs">
              <ExecuteBtn onClick={handlerExecute} disabled={!canExec}>
                <Trans>{attemptingTxn ? <Pending /> : canExec ? 'Execute' : 'Not available'}</Trans>
              </ExecuteBtn>
            </Column>
          }
          first={limitListIndex === 0}
          last={limitListIndex === limitListLength - 1}
        />
      </div>
    </>
  )
})
