import { Trans } from '@lingui/macro'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import PositionList from 'components/PositionList'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useFilterPossiblyMaliciousPositions } from 'hooks/useFilterPossiblyMaliciousPositions'
import { useV3Positions } from 'hooks/useV3Positions'
import { useMemo } from 'react'
import { useUserHideClosedPositions } from 'state/user/hooks'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'

import { ErrorContainer, InboxIcon, MainContentWrapper } from './StyledPool'
import { LoadingRows } from './styleds'

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

export default function PoolList() {
  const { account } = useActiveChainId()
  const { positions, loading: positionsLoading } = useV3Positions(account)

  // const toggleWalletDrawer = useToggleAccountDrawer()
  const { connectModalOpen: walletDrawerOpen, openConnectModal } = useConnectModal()
  const toggleWalletDrawer = () => openConnectModal && openConnectModal()

  const theme = useTheme()
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const userSelectedPositionSet = useMemo(
    () => [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)],
    [closedPositions, openPositions, userHideClosedPositions]
  )

  const filteredPositions = useFilterPossiblyMaliciousPositions(userSelectedPositionSet)

  const showConnectAWallet = Boolean(!account)

  return (
    <MainContentWrapper>
      {positionsLoading ? (
        <PositionsLoadingPlaceholder />
      ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
        <PositionList
          positions={filteredPositions}
          setUserHideClosedPositions={setUserHideClosedPositions}
          userHideClosedPositions={userHideClosedPositions}
        />
      ) : (
        <ErrorContainer>
          <ThemedText.DeprecatedBody color={theme.textTertiary} textAlign="center">
            <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
            <div>
              <Trans>Your active V3 liquidity positions will appear here.</Trans>
            </div>
          </ThemedText.DeprecatedBody>
          {!showConnectAWallet && closedPositions.length > 0 && (
            <ButtonSecondary
              style={{ marginTop: '.5rem' }}
              onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
            >
              <Trans>Show closed positions</Trans>
            </ButtonSecondary>
          )}
          {showConnectAWallet && (
            <ButtonPrimary
              style={{ marginTop: '2em', marginBottom: '2em', padding: '8px 16px' }}
              onClick={toggleWalletDrawer}
            >
              <Trans>Connect a wallet</Trans>
            </ButtonPrimary>
          )}
        </ErrorContainer>
      )}
    </MainContentWrapper>
  )
}
