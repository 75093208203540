import { usePositonDetail } from 'pages/Trade/Hooks'
import { TradePositionProps } from 'pages/Trade/types'
import { ThemedText } from 'theme'
import { toFromBN } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

export function PositionSize({ position }: { position: TradePositionProps }) {
  const { token0, token1 } = usePositonDetail(position)

  return (
    <ThemedText.TextPrimary fontWeight={700} fontSize={12}>
      {countZeros(
        toFromBN(position.size, position.long0 ? token1?.wrapped.decimals : token0?.wrapped.decimals).toFixed()
      )}{' '}
      {position.long0 ? token1?.wrapped.symbol : token0?.wrapped.symbol}
    </ThemedText.TextPrimary>
    // <Tooltip
    //   width="300px"
    //   show={showHover_Size}
    //   text={
    //     <TooltipBox>
    //       <RowBetween alignItems="flex-start" className="tooltipItem">
    //         <ThemedText.TextPrimary fontWeight={700}>
    //           <Trans>Size</Trans>
    //         </ThemedText.TextPrimary>
    //         <ThemedText.TextSecondary fontSize={14}>0</ThemedText.TextSecondary>
    //       </RowBetween>
    //     </TooltipBox>
    //   }
    // >
    //   <TooltipRow onMouseEnter={() => setShowHover_Size(true)} onMouseLeave={() => setShowHover_Size(false)}>
    //     <ThemedText.TextPrimary fontWeight={700} fontSize={12}>
    //       {countZeros(toFromBN(position.size).toFixed())} {position.long0 ? pool?.token1.symbol : pool?.token0.symbol}
    //     </ThemedText.TextPrimary>
    //   </TooltipRow>
    // </Tooltip>
  )
}
