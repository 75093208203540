import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Currency } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import { ButtonConfirmed } from 'components/Button'
import Column from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { useDynamicApprove } from 'components/DynamicApprove'
import LiquidityChartRangeInput from 'components/LiquidityChartRangeInput'
import { RateToggle3 } from 'components/RateToggle'
import Row, { RowBetween } from 'components/Row'
import { ConfirmationModalContent, TransactionConfirmationModal22222 } from 'components/TransactionConfirmationModal'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency, useToken } from 'hooks/Tokens'
import { useHypervisor } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { AmountBox, BoxCard } from 'pages/Lock/LockModal/CreateLockModal/CreateLockModal'
import { ModalTabCard } from 'pages/Lock/LockModal/ManageModal'
import { TokenInfo } from 'pages/Vote/types'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { Bound } from 'state/mint/v3/actions'
import { useV3DerivedMintInfo } from 'state/mint/v3/hooks'
import { Field } from 'state/swap/actions'
import { useSwapActionHandlers } from 'state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from 'state/swap/reducer'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { ThemedText } from 'theme'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'
import { getTickToPrice } from 'utils/getTickToPrice'

import { useAddPoolLiquidity, useSharsAmount } from './Hooks'
import { useUSDPriceByToken } from 'hooks/useUSDPrice'

interface FarmPoolProps {
  poolAddress?: string
  isOpen: boolean
  onDismiss: () => void
  token0?: TokenInfo
  token1?: TokenInfo
  fee?: string
  hypervisorAddr?: string
}

export default function FarmPoolModal({ isOpen, onDismiss, token0, token1, hypervisorAddr }: FarmPoolProps) {
  const [curr, setCurr] = useState<any>(0)
  const tabList = ['Add', 'Remove']
  const token0Add = useCurrency(token0?.id)
  const token1Add = useCurrency(token1?.id)
  const [token0IsNative, set0IsNative] = useState(false)
  const [token1IsNative, set1IsNative] = useState(false)
  const { account, chainId } = useActiveChainId()
  const native = useNativeCurrency(chainId)
  const addlpToken = useCurrency(hypervisorAddr)
  const balanceToken0 = useCurrencyBalance(account ?? undefined, token0Add ?? undefined)
  const balanceToken1 = useCurrencyBalance(account ?? undefined, token1Add ?? undefined)
  const ethbalance = useCurrencyBalance(account ?? undefined, native)

  const [isToken0, setIsToken0] = useState(true)

  const sharedAmount = useSharsAmount(
    hypervisorAddr,
    token0IsNative ? native : token0Add ?? undefined,
    token1IsNative ? native : token1Add ?? undefined
  )
  const prefilledState: Partial<SwapState> = {
    [Field.INPUT]: {
      currencyId: token0?.id,
    },
    [Field.OUTPUT]: {
      currencyId: token1?.id,
    },
  }
  useEffect(() => {
    if (!isOpen) {
      set0IsNative(false)
      set1IsNative(false)
    } else {
      if (token1Add?.symbol == 'WETH') {
        set1IsNative(true)
      }
      if (token0Add?.symbol == 'WETH') {
        set0IsNative(true)
      }
    }
  }, [isOpen, token1Add, token0Add])

  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })

  const { typedValue, independentField } = state

  const { onUserInput, onCurrencySelection } = useSwapActionHandlers(dispatch)
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const hypervisorContract = useHypervisor(hypervisorAddr)

  const { parsedAmount, inputError, outAmount } = useAddPoolLiquidity(
    state,
    hypervisorAddr,
    token0IsNative ? native : token0Add ?? undefined,
    token1IsNative ? native : token1Add ?? undefined
  )

  const parsedAmounts = useMemo(
    () => ({
      [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : outAmount,
      [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : outAmount,
    }),
    [independentField, outAmount, parsedAmount]
  )
  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: parsedAmounts[dependentField]?.toExact(),
    }),
    [dependentField, independentField, parsedAmounts, typedValue]
  )

  const handleAmount0Change = useCallback(
    (amount: string) => {
      onUserInput(Field.INPUT, amount)
    },
    [onUserInput]
  )
  const handleAmount1Change = useCallback(
    (amount: string) => {
      onUserInput(Field.OUTPUT, amount)
    },
    [onUserInput]
  )

  const {
    inputAmount: amountlp,
    handleUserInput: setAmountlp,
    isInputValid,
    inputError: LPinputError,
    handleResetInput,
  } = useInputAmount(addlpToken)

  const handleAmountlpChange = useCallback(
    (amount: string) => {
      setAmountlp(amount)
    },
    [setAmountlp]
  )

  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const lpBalance = useCurrencyBalance(account ?? undefined, addlpToken ?? undefined)
  const pendingText = 'pending ...'

  const addTransaction = useTransactionAdder()

  const handleDismissConfirmation = () => {
    onDismiss && onDismiss()
    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash('')
      setTxError('')
      onUserInput(Field.INPUT, '')
      handleResetInput()
    }, 300)
  }

  const depositAndStake = useCallback(async () => {
    const amount00 = parsedAmounts?.[Field.INPUT]?.numerator.toString()
    const amount11 = parsedAmounts?.[Field.OUTPUT]?.numerator.toString()
    if (!amount00 || !amount11 || !hypervisorContract || !account) return
    setAttemptingTxn(true)
    try {
      const valueToWei = token0IsNative ? amount00 : token1IsNative ? amount11 : 0
      const esgasLimit = await hypervisorContract.estimateGas.deposit(amount00, amount11, account, {
        value: valueToWei,
      })
      const response = await hypervisorContract.deposit(amount00, amount11, account, {
        gasLimit: calculateGasMargin(esgasLimit),
        value: valueToWei,
      })
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.DEPOSIT,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('create Lock error', error)
    }
  }, [account, addTransaction, hypervisorContract, parsedAmounts, token0IsNative, token1IsNative])

  const rest = (s: string) => {
    console.log('s', s)
  }

  const withdrawAndUnstake = useCallback(async () => {
    const amountlpp = amountlp?.amount?.numerator.toString()
    if (!account || !hypervisorContract || !amountlpp) return
    setAttemptingTxn(true)
    try {
      const esgasLimit = await hypervisorContract.estimateGas.withdraw(amountlpp, account, account)
      const response = await hypervisorContract.withdraw(amountlpp, account, account, {
        gasLimit: calculateGasMargin(esgasLimit),
      })
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.WITHDRAW,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('create Lock error', error)
    }
  }, [account, addTransaction, amountlp?.amount?.numerator, hypervisorContract])

  const { DynamicApprove, isApproved } = useDynamicApprove(
    [parsedAmounts[Field.INPUT], parsedAmounts[Field.OUTPUT]],
    hypervisorContract?.address
  )
  const { price, invertPrice, ticksAtLimit } = useV3DerivedMintInfo(
    token0Add ?? undefined,
    token1Add ?? undefined,
    FeeAmount.MEDIUM ?? undefined,
    token0Add ?? undefined
  )

  const { result: baseLower } = useSingleCallResult(hypervisorContract, 'baseLower')
  const { result: baseUpper } = useSingleCallResult(hypervisorContract, 'baseUpper')

  const Token0 = useToken(token0?.id)
  const Token1 = useToken(token1?.id)
  const pricesAtTicks = useMemo(() => {
    if (!baseLower || !baseUpper)
      return {
        [Bound.LOWER]: undefined,
        [Bound.UPPER]: undefined,
      }

    if (baseLower[0] == baseUpper[0] && baseUpper[0] == 0) {
      return {
        [Bound.LOWER]: undefined,
        [Bound.UPPER]: undefined,
      }
    }
    if (isToken0) {
      return {
        [Bound.LOWER]: getTickToPrice(Token0 ?? undefined, Token1 ?? undefined, baseLower[0] ?? undefined),
        [Bound.UPPER]: getTickToPrice(Token0 ?? undefined, Token1 ?? undefined, baseUpper[0] ?? undefined),
      }
    } else {
      return {
        [Bound.LOWER]: getTickToPrice(Token1 ?? undefined, Token0 ?? undefined, baseLower[0] ?? undefined)?.invert(),
        [Bound.UPPER]: getTickToPrice(Token1 ?? undefined, Token0 ?? undefined, baseUpper[0] ?? undefined)?.invert(),
      }
    }
  }, [Token0, Token1, baseLower, baseUpper, isToken0])

  const { [Bound.LOWER]: lowerPrice, [Bound.UPPER]: upperPrice } = pricesAtTicks

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      if (inputCurrency.isNative) {
        set0IsNative(true)
      } else {
        set0IsNative(false)
      }
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )
  const handleOutputSelect = useCallback(
    (inputCurrency: Currency) => {
      if (inputCurrency.isNative) {
        set1IsNative(true)
      } else {
        set1IsNative(false)
      }
      onCurrencySelection(Field.OUTPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const shareAmounts = useMemo(() => {
    if (!sharedAmount || !lpBalance || !amountlp || !amountlp.amount) return
    if (Number(amountlp.value) === 0 || Number(lpBalance.toExact()) === 0) return

    const raito = amountlp.amount.divide(lpBalance)
    let amount0
    let amount1
    if (raito.greaterThan(1)) {
      amount0 = sharedAmount.amount0.multiply(1)
      amount1 = sharedAmount.amount1.multiply(1)
    } else {
      amount0 = sharedAmount.amount0.multiply(raito)
      amount1 = sharedAmount.amount1.multiply(raito)
    }

    return {
      amount0,
      amount1,
    }
  }, [amountlp, lpBalance, sharedAmount])

  const fiatValueInput = useUSDPriceByToken(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDPriceByToken(parsedAmounts[Field.OUTPUT])
  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT])
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT])


  function ModalContent() {
    return (
      <>
        <Box mt="8px" width="100%">
          <ModalTabCard>
            {tabList?.map((tab: any, i: number) => (
              <Row
                justify="center"
                className={`tabItem ${curr == i && 'tabItemActive'}`}
                key={i}
                onClick={() => setCurr(i)}
              >
                {curr == i ? (
                  <ThemedText.BodySmall fontWeight={700} color="textAddButton" className="">
                    {tab}
                  </ThemedText.BodySmall>
                ) : (
                  <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                    {tab}
                  </ThemedText.TextSecondary>
                )}
              </Row>
            ))}
          </ModalTabCard>
        </Box>
        {curr == 0 ? (
          <Column gap="sm" align="flex-start">
            <ThemedText.TextSecondary fontSize={14} mt="16px">
              <Trans>Liquidity ranges are automatically rebalanced when certain rebalance triggers are met.</Trans>
            </ThemedText.TextSecondary>
            <Box>
              {token0Add && token1Add && (
                <RateToggle3
                  currencyA={token0Add}
                  currencyB={token1Add}
                  isSorted={isToken0}
                  handleRateToggle={() => {
                    setIsToken0(!isToken0)
                  }}
                />
              )}
            </Box>
            {lowerPrice && upperPrice && (
              <LiquidityChartRangeInput
                currencyA={isToken0 ? token0Add ?? undefined : token1Add ?? undefined}
                currencyB={isToken0 ? token1Add ?? undefined : token0Add ?? undefined}
                feeAmount={FeeAmount.MEDIUM}
                ticksAtLimit={ticksAtLimit}
                price={price ? parseFloat((!isToken0 ? price.invert() : price).toSignificant(8)) : undefined}
                priceLower={lowerPrice}
                priceUpper={upperPrice}
                interactive={!true}
                onLeftRangeInput={rest}
                onRightRangeInput={rest}
              />
            )}
            <AmountBox>
              <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                <Trans>Deposit Amounts</Trans>
              </ThemedText.TextSecondary>
              <BoxCard>
                <Box p=" 0 8px" pb="12px">
                  <SwapCurrencyInputPanel
                    label={<Trans>From</Trans>}
                    value={formattedAmounts[Field.INPUT] ?? ''}
                    showMaxButton={true}
                    currency={token0IsNative ? native : token0Add}
                    id="farmAmount"
                    onUserInput={handleAmount0Change}
                    fiatValue={showFiatValueInput ? fiatValueInput : undefined}
                    loading={false}
                    onMax={() => {
                      handleAmount0Change(
                        token0IsNative ? ethbalance?.toFixed(18) || '' : balanceToken0?.toFixed(18) || ''
                      )
                    }}
                    showPerpEth={token0Add?.symbol == 'ETH' || token0Add?.symbol == 'WETH'}
                    onCurrencySelect={
                      token0Add?.symbol == 'ETH' || token0Add?.symbol == 'WETH' ? handleInputSelect : undefined
                    }
                  />
                </Box>
              </BoxCard>
              <BoxCard>
                <Box p=" 0 8px" pb="12px">
                  <SwapCurrencyInputPanel
                    label={<Trans>From</Trans>}
                    value={formattedAmounts[Field.OUTPUT] ?? ''}
                    showMaxButton={true}
                    currency={token1IsNative ? native : token1Add}
                    id="farmAmount"
                    onUserInput={handleAmount1Change}
                    fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
                    loading={false}
                    onMax={() => {
                      handleAmount1Change(
                        token1IsNative ? ethbalance?.toFixed(18) || '' : balanceToken1?.toFixed(18) || ''
                      )
                    }}
                    showPerpEth={token1Add?.symbol == 'ETH' || token1Add?.symbol == 'WETH'}
                    onCurrencySelect={
                      token1Add?.symbol == 'ETH' || token1Add?.symbol == 'WETH' ? handleOutputSelect : undefined
                    }
                  />
                </Box>
              </BoxCard>
            </AmountBox>
            <DynamicApprove />
            <ButtonConfirmed disabled={!!inputError || !isApproved} onClick={depositAndStake}>
              {inputError ? inputError : 'Add'}
            </ButtonConfirmed>
          </Column>
        ) : (
          <Column gap="sm" align="flex-start">
            <Box mt="8px" width="100%">
              <AmountBox>
                <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                  <Trans>Amounts</Trans>
                </ThemedText.TextSecondary>
                <BoxCard>
                  <Box p=" 0 8px" pb="12px">
                    <SwapCurrencyInputPanel
                      label={<Trans>From</Trans>}
                      value={amountlp.value}
                      showMaxButton={false}
                      currency={addlpToken}
                      id="farmAmount"
                      onUserInput={handleAmountlpChange}
                      // fiatValue={{ data: Number(amountlp), isLoading: false }}
                      loading={false}
                      onMaxTab={handleAmountlpChange}
                      showMaxTab={true}
                    />
                  </Box>
                </BoxCard>
              </AmountBox>
            </Box>
            <RowBetween gap="sm" className="infoItem" mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Pooled {token0?.symbol}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(shareAmounts?.amount0.toSignificant(), 4, true) || 0}
              </ThemedText.TextPrimary>
            </RowBetween>
            <RowBetween gap="sm" className="infoItem">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Pooled {token1?.symbol}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(shareAmounts?.amount1.toSignificant(), 4, true) || 0}
              </ThemedText.TextPrimary>
            </RowBetween>
            <ButtonConfirmed onClick={withdrawAndUnstake} disabled={!isInputValid}>
              {LPinputError ? LPinputError : 'Remove'}
            </ButtonConfirmed>
          </Column>
        )}
      </>
    )
  }

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      width="410px"
      pendingText={pendingText}
      reviewContent={
        <ConfirmationModalContent
          title={
            <>
              {token0Add?.symbol}/{token1Add?.symbol} <Trans>Farm Pool</Trans>
            </>
          }
          onDismiss={handleDismissConfirmation}
          topContent={ModalContent}
          gap="0"
        />
      }
    />
  )
}
