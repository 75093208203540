import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Percent } from '@uniswap/sdk-core'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { StyledVoteCloseIcon } from 'components/Icons/StyledIcon'
import { useToken } from 'hooks/Tokens'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { unwrappedToken } from 'utils/unwrappedToken'

const StyledVotedBox = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
`
const StyledVotedList = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`

const StyledVotedItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 16px;
  background: ${({ theme }) => theme.backgroundModule};
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.25);
`

const StyledSplit = styled.div`
  width: 1px;
  height: 33px;
  background: ${({ theme }) => theme.primaryBorder};
`

interface VotedProps {
  inputValueArr: any
  clearVoted: any
}

function StyledLogo(vote: any) {
  const token0 = useToken(vote.vote.token0.id)
  const token1 = useToken(vote.vote.token1.id)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  return <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={24} margin />
}

export default function VotedWrapper({ inputValueArr, clearVoted }: VotedProps) {
  return (
    <StyledVotedBox>
      <StyledVotedList>
        {inputValueArr?.map((vote: any, i: number) => (
          <StyledVotedItem key={i}>
            <StyledVoteCloseIcon onClick={() => clearVoted(0, vote.data)} />
            <StyledSplit />
            <StyledLogo vote={vote.data} />
            <ThemedText.TextPrimary fontWeight={700}>
              <Trans>
                {vote.data.token0.symbol}-{vote.data.token1.symbol}
              </Trans>
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
              ({new Percent(vote.data.poolFee, 1_000_000).toSignificant()}%)
            </ThemedText.TextSecondary>
            <StyledSplit />
            <ThemedText.TextPrimary fontWeight={700}>
              <Trans>{vote.value}%</Trans>
            </ThemedText.TextPrimary>
          </StyledVotedItem>
        ))}
      </StyledVotedList>
    </StyledVotedBox>
  )
}
