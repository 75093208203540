import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import Column from 'components/Column'
import { StyledChangeIcon } from 'components/Icons/StyledIcon'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import Tab from 'pages/Profile/Tab'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'

import { AdlList } from './AdlList'
import { LimitList } from './LimitList'
import { LiquidationList } from './LiquidationList'
import SearchBar from './SearchBar'

const StyledChange = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  height: 34px;
  padding: 0px 16px;
  border-radius: 24px;
  background: ${({ theme }) => theme.backgroundInteractive};
  cursor: pointer;
`

export default function OrderList() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const [curr, setCurr] = useState<any>(0)
  const tabList = ['Limit/Trigger', 'Liquidation', 'ADL']

  const [token01, setToken01] = useState(true)

  return (
    <PageWrapper data-testid="orderList-page">
      <Column align="flex-start">
        <TitleRow>
          <ThemedText.LargeHeader>
            <Trans>Order List</Trans>
          </ThemedText.LargeHeader>
        </TitleRow>
        <TitleDesc>
          <ThemedText.HeaderDesc lineHeight="16px">
            <Trans>
              This section allows users to execute orders that meet their limit or liquidate positions marked for
              liquidation. Earn a fee for each order executed or liquidated!
            </Trans>
          </ThemedText.HeaderDesc>
        </TitleDesc>
      </Column>
      <Box
        mt="36px"
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        gap="16px"
      >
        <Tab tabList={tabList} curr={curr} setCurr={setCurr} />
        <Box display="flex" gap="12px">
          <StyledChange>
            <StyledChangeIcon />
            <Box display="flex" alignItems="center" gap="4px">
              {token01 && (
                <ThemedText.TextPrimary>
                  <Trans>Token0</Trans>
                </ThemedText.TextPrimary>
              )}
              {!token01 && (
                <ThemedText.TextSecondary fontSize={14} onClick={() => setToken01(true)}>
                  <Trans>Token0</Trans>
                </ThemedText.TextSecondary>
              )}
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>/</Trans>
              </ThemedText.TextSecondary>
              {!token01 && (
                <ThemedText.TextPrimary>
                  <Trans>Token1</Trans>
                </ThemedText.TextPrimary>
              )}
              {token01 && (
                <ThemedText.TextSecondary fontSize={14} onClick={() => setToken01(false)}>
                  <Trans>Token1</Trans>
                </ThemedText.TextSecondary>
              )}
            </Box>
          </StyledChange>
          <SearchBar />
        </Box>
      </Box>
      <Column mt="16px">
        {curr == 0 && <LimitList token01={token01} />}
        {curr == 1 && <LiquidationList token01={token01} />}
        {curr == 2 && <AdlList token01={token01} />}
      </Column>
    </PageWrapper>
  )
}
