import { useMediaQuery } from '@mui/material'
import { BREAKPOINTS } from 'theme'

import Table from './Table'
import TableM from './Table-m'

export default function LockTableList() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)

  return <>{isSmallScreen ? <TableM /> : <Table />}</>
}
