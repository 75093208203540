import { Trans } from '@lingui/macro'
import Column from 'components/Column'
import { MediumLoadingBubble } from 'components/Loading'
import { RowBetween } from 'components/Row'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'
import { ThemedText } from 'theme'

import { TokenSortMethod } from './state'

/* Token Row: skeleton row component */
function HistoryRow({
  invited,
  rewards,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  rewards: ReactNode
  invited: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <RowBetween gap="xs">
      <TextLeftCell data-testid="invited-cell">{invited}</TextLeftCell>
      <TextRightCell data-testid="rewards-cell">{rewards}</TextRightCell>
    </RowBetween>
  )

  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <HistoryRow
      $loading
      key={props?.first + '' + props?.last}
      invited={
        <Column width="100%" align="flex-start" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.INVITED}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      rewards={
        <Column width="100%" align="flex-end" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.REWARDS}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
    />
  )
}

interface LoadedRowProps {
  historyListIndex: number
  historyListLength: number
  history: any
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { history, historyListIndex, historyListLength } = props

  return (
    <>
      <div ref={ref} data-testid={`history-table-row-${history?.tokenId}`}>
        <HistoryRow
          invited={
            <Column width="100%" align="flex-start" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.INVITED}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary
                fontWeight={500}
                fontSize={16}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                0x124...15ef
              </ThemedText.TextPrimary>
            </Column>
          }
          rewards={
            <Column width="100%" align="flex-end" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.REWARDS}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                10.00 USDC
              </ThemedText.TextPrimary>
            </Column>
          }
          first={historyListIndex === 0}
          last={historyListIndex === historyListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
