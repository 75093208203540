import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { AddButton, BribesButton, ClaimButton, Pending } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import { NoLiquityTips } from 'components/Tips'
import { ToastError } from 'components/Toast'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency, useToken } from 'hooks/Tokens'
import { useMasterChefV3 } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import { useListTokens } from 'pages/Vote/AddBribesModal/hooks'
import { rewardPorps, VoteDataProps } from 'pages/Vote/types'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo, useState } from 'react'
import CountUp from 'react-countup'
import { Box } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount, NumberType } from 'utils/formatCurrency'
import { handlerError } from 'utils/formatError'
import { computeNumUnit } from 'utils/formatNum'
import { unwrappedToken } from 'utils/unwrappedToken'

import { useSharsAmount } from './FarmPoolModal/Hooks'
import HeaderCell from './HeaderCell'
import { LiquiditySortMethod } from './state'

const StyledLiquidityRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: flex-start;
  grid-template-columns: 200px 1.5fr 1.3fr 1.5fr 2.5fr 1.5fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  .bg {
    height: 100%;
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }
`
const StyledTrRow = styled(StyledLiquidityRow)``

export const StyledFeeValue = styled(ThemedText.TextPrimary)`
  border-radius: 2px;
  background: ${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)')};
  padding: 2px 4px;
  margin-left: 4px !important;
  color: ${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)')};
`
export const StyledMaxLevleageValue = styled(ThemedText.TextPrimary)`
  border-radius: 2px;
  background: linear-gradient(180deg, #1ea7e4 0%, #01bbbe 100%);
  padding: 2px 4px;
  color: #fff;
`

const StyledHeaderRow = styled(StyledLiquidityRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }
`

const PoolCell = styled(Cell)`
  justify-content: flex-start;
  min-width: 240px;
  padding: 0 8px;
`

const TextLeftCell = styled(Cell)`
  justify-content: flex-start;
  padding: 0 8px;
`

const TextRightCell = styled(Cell)`
  padding: 0 8px;
  text-align: right;
`

export const TooltipRow = styled(Row)`
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px dashed ${({ theme }) => theme.textSecondary};
`

export const TooltipBox = styled(Box)`
  .tooltipItem {
    padding: 8px 0;
    align-items: flex-start;
    &:not(:last-child) {
      border-bottom: 1px dashed ${({ theme }) => theme.swapSectionBorder};
    }

    .text {
      word-break: initial;
    }

    .balance {
      align-items: flex-end;
    }
  }
`

const StyledTextWrap = styled(Box)`
  text-wrap: nowrap;
`

const TotalRewardsItem = ({ rewardAddr }: { rewardAddr: rewardPorps }) => {
  const { chainId } = useActiveChainId()
  const currenty = useMemo(
    () =>
      chainId
        ? new Token(
            chainId,
            rewardAddr.tokenInfo.id,
            Number(rewardAddr.tokenInfo.decimals),
            rewardAddr.tokenInfo.symbol,
            rewardAddr.tokenInfo.name
          )
        : undefined,
    [
      chainId,
      rewardAddr.tokenInfo.decimals,
      rewardAddr.tokenInfo.id,
      rewardAddr.tokenInfo.name,
      rewardAddr.tokenInfo.symbol,
    ]
  )

  const tokenInfo = useMemo(() => {
    if (!currenty) return
    return CurrencyAmount.fromRawAmount(currenty, rewardAddr?.rewardAmount || 0)
  }, [currenty, rewardAddr.rewardAmount])
  return (
    <Box alignItems="center" display="flex" justifyContent="center">
      <ThemedText.TextSecondary fontSize={14}>
        {formatAmount(tokenInfo?.toSignificant(), 2, true)} {currenty?.symbol}
      </ThemedText.TextSecondary>
      {rewardAddr.tokenInfo?.shit && <NoLiquityTips />}
    </Box>
  )
}

/* Token Row: skeleton row component */
function LiquidityRow({
  header,
  pools,
  tvl,
  apr,
  rewards,
  my_staked,
  earn,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  rewards: ReactNode
  tvl: ReactNode
  apr: ReactNode
  pools: ReactNode
  my_staked: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowTrCells = (
    <>
      <PoolCell data-testid="pools-cell">
        <Box pt="16px" pb="32px" width="100%" height="100%">
          {pools}
        </Box>
      </PoolCell>
      <TextRightCell data-testid="tvl-cell">
        <Box pt="16px" pb="32px" width="100%" height="100%">
          {tvl}
        </Box>
      </TextRightCell>
      <TextLeftCell data-testid="apr-cell">
        <Box pt="16px" pb="32px" width="100%" height="100%">
          {apr}
        </Box>
      </TextLeftCell>
      <TextRightCell data-testid="rewards-cell">
        <Box pt="16px" pb="32px" width="100%" height="100%">
          {rewards}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="my_staked-cell" className="bg">
        <Box pt="16px" pb="32px" width="100%" height="100%">
          {my_staked}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="my-liquidity-cell" className="bg">
        <Box pt="16px" pb="32px" width="100%" height="100%">
          {earn}
        </Box>
      </TextRightCell>
    </>
  )

  const rowHeaderCells = (
    <>
      <PoolCell data-testid="pools-cell">{pools}</PoolCell>
      <TextRightCell data-testid="tvl-cell" className=" ">
        {tvl}
      </TextRightCell>
      <TextLeftCell data-testid="apr-cell" className=" ">
        {apr}
      </TextLeftCell>
      <TextRightCell data-testid="rewards-cell" className=" ">
        {rewards}
      </TextRightCell>
      <TextRightCell data-testid="my_staked-cell" className="bg  ">
        {my_staked}
      </TextRightCell>
      <TextRightCell data-testid="my-liquidity-cell" className="bg  ">
        {earn}
      </TextRightCell>
    </>
  )

  if (header) return <StyledHeaderRow data-testid="liquidity-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledTrRow {...rest}>{rowTrCells}</StyledTrRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LiquidityRow
      header={true}
      pools={<Trans>{LiquiditySortMethod.POOLS}</Trans>}
      tvl={
        <Row justify="end">
          <HeaderCell category={LiquiditySortMethod.TVL} />
        </Row>
      }
      apr={
        <Row>
          <HeaderCell category={LiquiditySortMethod.APR} justify="flex-start" />
        </Row>
      }
      rewards={
        <Row justify="end">
          <HeaderCell category={LiquiditySortMethod.INCENTIVIZATION} />
        </Row>
      }
      my_staked={
        <Row justify="end">
          <HeaderCell category={LiquiditySortMethod.MY_STAKED} />
        </Row>
      }
      earn={
        <Row justify="end">
          <Trans>{LiquiditySortMethod.EARN}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LiquidityRow
      header={false}
      $loading
      pools={
        <Row gap="xmd">
          <IconLoadingBubble width="40px" height="40px" />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      tvl={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      apr={
        <Row>
          <LoadingBubble />
        </Row>
      }
      rewards={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <Row align="center" justify="flex-end" gap="sm">
            <SmallLoadingBubble />
            <BribesButton>+ Bribes</BribesButton>
          </Row>
        </Column>
      }
      my_staked={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <Box mt="6px">
            <AddButton>+/— Deposit</AddButton>
          </Box>
        </Column>
      }
      earn={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <ClaimButton>Claim</ClaimButton>
        </Column>
      }
      {...props}
    />
  )
}

export type LoadedRowProps = {
  liquidityListIndex: number
  liquidityListLength: number
  liquidity: VoteDataProps
  addBribeChage: (val: VoteDataProps) => void
  showPoolMidalChage: (val: VoteDataProps) => void
}

export const TotalLiquidityEarnsItem = ({ token, masterchefAddr }: { token: Token; masterchefAddr: string }) => {
  const { chainId, account } = useActiveChainId()
  const tokenContract = useMasterChefV3(masterchefAddr)
  const { result } = useSingleCallResult(tokenContract, 'earned', [token.address, account])
  const tokenAmount = useMemo(() => {
    if (!result || !token) return
    return CurrencyAmount.fromRawAmount(token, result[0])
  }, [token, result])
  return Number(tokenAmount?.toSignificant()) > 0 ? (
    <ThemedText.TextRewards>
      <CountUp preserveValue={true} end={Number(tokenAmount?.toSignificant()) || 0} separator="," decimals={2} />{' '}
      {token?.symbol}
    </ThemedText.TextRewards>
  ) : (
    <></>
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { liquidityListIndex, liquidityListLength, liquidity, addBribeChage, showPoolMidalChage } = props

  const masterChefContract = useMasterChefV3(liquidity.masterChefAddress)
  const { account } = useActiveChainId()
  const currrencyA = useCurrency(liquidity.token0.id)
  const currrencyB = useCurrency(liquidity.token1.id)
  const sharedAmount = useSharsAmount(liquidity.hypervisorAddress, currrencyA ?? undefined, currrencyB ?? undefined)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const tokens = useListTokens(masterChefContract)

  const handleClaim = async () => {
    setAttemptingTxn(true)
    if (!masterChefContract || !liquidity || !account || !tokens) return
    try {
      const tempList = tokens?.map((item) => item?.address)
      if (!tempList) return
      const response = await masterChefContract.getReward(account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('masterChefContract claimRewards error', error)
    }
  }
  const liquidityToken0 = useToken(liquidity.token0.id)
  const liquidityToken1 = useToken(liquidity.token1.id)
  const currency0 = liquidityToken0 ? unwrappedToken(liquidityToken0) : undefined
  const currency1 = liquidityToken1 ? unwrappedToken(liquidityToken1) : undefined

  const lpbalance0 = useTokenBalance(liquidity.lpAddr ?? undefined, liquidityToken0 ?? undefined)
  const lpbalance1 = useTokenBalance(liquidity.lpAddr ?? undefined, liquidityToken1 ?? undefined)

  const allLiquidity = useMemo(() => {
    if (!liquidity || !lpbalance0 || !lpbalance1) return
    let token0ValueUSD = BN(0)
    if (liquidity.token0.derivedETH && liquidity.ethPrice && liquidity.ethPrice != '0') {
      token0ValueUSD = BN(lpbalance0.toExact())
        .multipliedBy(liquidity.ethPrice)
        .multipliedBy(liquidity.token0.derivedETH)
    }
    let token1ValueUSD = BN(0)
    if (liquidity.token1.derivedETH && liquidity.ethPrice && liquidity.ethPrice != '0') {
      token1ValueUSD = BN(lpbalance1.toExact())
        .multipliedBy(liquidity.ethPrice)
        .multipliedBy(liquidity.token1.derivedETH)
    }
    return token0ValueUSD.plus(token1ValueUSD).toString()
  }, [lpbalance0, lpbalance1, liquidity])

  return (
    <>
      <div ref={ref} data-testid={`liquidity-table-row-${liquidity.lpAddr}`}>
        <LiquidityRow
          header={false}
          pools={
            <Row gap="xmd">
              <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={40} margin />
              <Column gap="xs" align="flex-start">
                <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                  {liquidity.token0.symbol}-{liquidity.token1.symbol}
                </ThemedText.TextPrimary>
                <Row>
                  <StyledMaxLevleageValue>{liquidity.maxLevleage}x</StyledMaxLevleageValue>
                  <StyledFeeValue>{new Percent(liquidity.poolFee, 1_000_000).toSignificant()}%</StyledFeeValue>
                </Row>
                <Row mt="4px">
                  <StyledTextWrap>
                    <ThemedText.TextSecondary fontSize={12} mr="4px">
                      <Trans>LIQUIDITY</Trans>
                    </ThemedText.TextSecondary>
                  </StyledTextWrap>
                  <ThemedText.TextPrimary fontSize={12}>${computeNumUnit(allLiquidity, 2)}</ThemedText.TextPrimary>
                </Row>
              </Column>
            </Row>
          }
          tvl={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>${formatAmount(liquidity.tvl, 2, true)}</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(liquidity?.liqvidityToken0, NumberType.FiatTokenStats2)} {liquidity.token0.symbol}
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(liquidity.liqvidityToken1, NumberType.FiatTokenStats2)} {liquidity.token1.symbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          apr={
            <Row>
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(Number(liquidity.liquityAPR) * 100, 2, true)}%
              </ThemedText.TextPrimary>
            </Row>
          }
          rewards={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(liquidity.LiquidityrewardUSD, 2, true)}
              </ThemedText.TextPrimary>
              {liquidity?.LiquidityrewardsLists?.map((item, index) => {
                return <TotalRewardsItem key={'liquidity-rewards-item' + index} rewardAddr={item}></TotalRewardsItem>
              })}
              <BribesButton onClick={() => addBribeChage(liquidity)}>+ Bribes</BribesButton>
            </Column>
          }
          my_staked={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(liquidity.stakeData.tvl, 2, true)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(sharedAmount?.amount0, NumberType.FiatTokenStats2)} {liquidity.token0.symbol}
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(sharedAmount?.amount1, NumberType.FiatTokenStats2)} {liquidity.token1.symbol}
              </ThemedText.TextSecondary>
              <Box mt="6px">
                <AddButton onClick={() => showPoolMidalChage(liquidity)}>+/— Deposit</AddButton>
              </Box>
            </Column>
          }
          earn={
            <Column align="end" width="100%" gap="xs">
              {tokens && tokens?.length > 0 ? (
                tokens?.map((item: any, index: number) => {
                  return (
                    <TotalLiquidityEarnsItem
                      key={'earn-liquidity' + index}
                      token={item}
                      masterchefAddr={liquidity.masterChefAddress}
                    />
                  )
                })
              ) : (
                <ThemedText.TextRewards>--</ThemedText.TextRewards>
              )}
              <Row mt="4px" justify="end">
                <ClaimButton disabled={attemptingTxn} onClick={handleClaim}>
                  {attemptingTxn ? <Pending /> : 'Claim'}
                </ClaimButton>
              </Row>
            </Column>
          }
          first={liquidityListIndex === 0}
          last={liquidityListIndex === liquidityListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
