import { Box } from '@mui/material'
import OrderList from 'pages/Trade/NavTabList/LimitList/index'
import PositionTableList from 'pages/Trade/NavTabList/PositionTableList'
import { useState } from 'react'
import styled from 'styled-components/macro'

import Tab from '../Tab'

const PositionBox = styled(Box)`
  margin-top: 24px;
`

export default function Position() {
  const [curr, setCurr] = useState<any>(0)
  const tabList = ['Positions', 'Orders']

  return (
    <PositionBox>
      <Tab tabList={tabList} curr={curr} setCurr={setCurr} />
      <Box mt="12px">
        {curr == 0 && <PositionTableList />}
        {curr == 1 && <OrderList />}
      </Box>
    </PositionBox>
  )
}
