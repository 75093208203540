import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as AddIcon } from 'assets/imgs/lock/add.svg'
import { ButtonConfirmed } from 'components/Button'
import Column from 'components/Column'
import { StyledToIcon } from 'components/Icons/StyledIcon'
import { RowBetween } from 'components/Row'
import { ConfirmationModalContent, TransactionConfirmationModal22222 } from 'components/TransactionConfirmationModal'
import dayjs from 'dayjs'
import { BigNumber } from 'ethers/lib'
import { useLockContract } from 'hooks/useContract'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useLockStakeList } from 'pages/Lock/hooks'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'

import { StyledLockCard } from '../CreateLockModal/CreateLockModal'
import DropdownList from './DropdownList'
import SelectedInfo from './SelectedInfo'
import SelectWrapper from './SelectWrapper'

interface MergeModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export default function MergeModal({ isOpen, onDismiss }: MergeModalProps) {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const handleDismiss = () => {
    onDismiss && onDismiss()
    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash('')
      setTxError('')
    }, 300)
  }

  const [nftInfo1, setNFTInfo1] = useState<any>({})
  const [nftInfo2, setNFTInfo2] = useState<any>({})
  const [isShow1, setIsShow1] = useState(false)
  const [isShow2, setIsShow2] = useState(false)
  const handleChange1 = (nft: any) => {
    setNFTInfo1(nft)
  }
  const handleChange2 = (nft: any) => {
    setNFTInfo2(nft)
  }
  const { tokenIdDetails: lockList } = useLockStakeList()

  const list1 = lockList?.filter((item: any) => item.tokenId != nftInfo2.tokenId)
  const list2 = lockList?.filter((item: any) => item.tokenId != nftInfo1.tokenId)
  const toggleShow1 = () => {
    setIsShow1(!isShow1)
  }
  const toggleShow2 = () => {
    setIsShow2(!isShow2)
  }

  const voteNode1 = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(voteNode1, isShow1 ? toggleShow1 : undefined)

  const voteNode2 = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(voteNode2, isShow2 ? toggleShow2 : undefined)

  const LockContract = useLockContract()

  const addTransaction = useTransactionAdder()
  const handleMerge = useCallback(async () => {
    if (!LockContract || !nftInfo1 || !nftInfo2) return
    setAttemptingTxn(true)

    try {
      const response = await LockContract.merge(BigNumber.from(nftInfo1.tokenId), BigNumber.from(nftInfo2.tokenId))
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.MERGE_LOCK,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('merge Lock error', error)
    }
  }, [LockContract, addTransaction, nftInfo1, nftInfo2])

  const loadMergeInfo = () => {
    if (!nftInfo1 || !nftInfo2 || !LockContract) return undefined
    const isFlag = nftInfo1.endTime >= nftInfo2.endTime
    const params = isFlag ? nftInfo2.tokenId : nftInfo1.tokenId
    const endTime = nftInfo1.endTime >= nftInfo2.endTime ? nftInfo1.endTime : nftInfo2.endTime
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const veResult = useSingleCallResult(LockContract, 'get_last_user_slope', [params])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      if (!veResult || !veResult.result) return undefined
      const lockAmount = BN(nftInfo1.lockAmount?.toSignificant()).plus(BN(nftInfo2.lockAmount?.toSignificant()))
      const shortTimePower = BN(endTime - Math.round(Number(new Date()) / 1000)).multipliedBy(
        BN(veResult?.result[0].toString())
      )
      const longTimePower = isFlag ? BN(nftInfo1.voteAmount?.toSignificant()) : BN(nftInfo2.voteAmount?.toSignificant())
      const votingPower = longTimePower.plus(fromWei(shortTimePower.toString(), 18))
      return { lockAmount, votingPower, endTime }
    }, [veResult, endTime, isFlag])
  }

  const mergeInfo = loadMergeInfo()

  function ModalContent() {
    return (
      <Column gap="xmd">
        <Box
          width="100%"
          style={{ position: 'relative' }}
          ref={voteNode1}
          onClick={() => {
            if (list1 && list1?.length > 0) toggleShow1()
          }}
        >
          {nftInfo1?.tokenId > 0 ? <SelectedInfo nftInfo={nftInfo1} /> : <SelectWrapper />}
          {isShow1 && <DropdownList list={list1} toggleShow={toggleShow1} handleChange={handleChange1} />}
        </Box>
        <AddIcon />
        <Box
          width="100%"
          style={{ position: 'relative' }}
          ref={voteNode2}
          onClick={() => {
            if (list2 && list2?.length > 0) toggleShow2()
          }}
        >
          {nftInfo2?.tokenId > 0 ? <SelectedInfo nftInfo={nftInfo2} /> : <SelectWrapper />}
          {isShow2 && <DropdownList list={list2} toggleShow={toggleShow2} handleChange={handleChange2} />}
        </Box>
        <StyledToIcon />
        <Box width="100%" mt="12px">
          <StyledLockCard>
            <RowBetween gap="sm" className="infoItem">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Lock Amount</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary>
                <Trans>{formatAmount(mergeInfo?.lockAmount, 4, true)} ROX</Trans>
              </ThemedText.TextPrimary>
            </RowBetween>
            <RowBetween gap="sm" className="infoItem">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Voting Power</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                <Trans>{formatAmount(mergeInfo?.votingPower, 4, true)} veROX</Trans>
              </ThemedText.TextPrimary>
            </RowBetween>
            <RowBetween gap="sm" className="infoItem">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Lock Until</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary>
                <Trans>{dayjs(Number(mergeInfo?.endTime) * 1000).format('YYYY-MM-DD')}</Trans>
              </ThemedText.TextPrimary>
            </RowBetween>
          </StyledLockCard>
        </Box>
        <Column width="100%" mt="8px" gap="sm">
          <ButtonConfirmed disabled={!nftInfo1.tokenId || !nftInfo2.tokenId} onClick={handleMerge}>
            Merge
          </ButtonConfirmed>
        </Column>
      </Column>
    )
  }

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={handleDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      width="400px"
      reviewContent={
        <ConfirmationModalContent
          title={<Trans>Merge Lock</Trans>}
          onDismiss={handleDismiss}
          topContent={ModalContent}
        />
      }
      pendingText={pendingText}
    />
  )
}
