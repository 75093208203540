import { Trans } from '@lingui/macro'
import { ManagerButton } from 'components/Button'
import Column from 'components/Column'
import { IconLoadingBubble, MediumLoadingBubble } from 'components/Loading'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row, { RowBetween } from 'components/Row'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useGraphTokenList } from 'hooks/useQueryLiquidity'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'
import { useNavigate } from 'react-router'
import { useHttpEthPrice } from 'state/http/hooks'
import { ThemedText } from 'theme'
import { compareAddress } from 'utils'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'

import { LoadedRowProps } from './AssetRow'
import { TokenSortMethod } from './state'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

/* Token Row: skeleton row component */
function AssetRow({
  asset,
  amount,
  manage,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  amount: ReactNode
  manage: ReactNode
  asset: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween gap="xs">
        <TextLeftCell data-testid="asset-cell">{asset}</TextLeftCell>
        <TextRightCell data-testid="amount-cell">{amount}</TextRightCell>
      </RowBetween>
      <Row gap="sm" data-testid="manage-cell">
        {manage}
      </Row>
    </>
  )

  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <AssetRow
      $loading
      key={props?.first + '' + props?.last}
      asset={
        <Column width="100%" align="flex-start" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.ASSET}</Trans>
          </ThemedText.TextSecondary>
          <Row gap="sm">
            <IconLoadingBubble width="48px" height="48px" />
            <MediumLoadingBubble />
          </Row>
        </Column>
      }
      amount={
        <Column width="100%" align="flex-end" gap="sm">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.AMOUNT}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      manage={
        <Row gap="sm">
          <ManagerButton width="100%">Swap</ManagerButton>
          {/* <ManagerButton width="100%">Earn</ManagerButton> */}
        </Row>
      }
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { asset, assetListIndex, assetListLength } = props

  const { account } = useActiveChainId()
  const balance = useCurrencyBalance(account ?? undefined, asset)

  const ethPrice = useHttpEthPrice()

  const tokenList: any = useGraphTokenList()
  const currTokenArr = tokenList?.filter((token: any) => compareAddress(token.id, asset.address))
  const currToken = currTokenArr?.length > 0 ? currTokenArr[0] : {}

  const navigate = useNavigate()
  const handlerSwap = () => {
    currToken.id ? navigate('/swap/' + currToken.id) : navigate('/swap')
  }

  return (
    <>
      <div ref={ref} data-testid={`asset-table-row-${asset?.tokenId}`}>
        <AssetRow
          asset={
            <Column width="100%" align="flex-start" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.ASSET}</Trans>
              </ThemedText.TextSecondary>
              <Row gap="sm">
                <CurrencyLogo currency={asset} size="48px" />
                <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                  {asset.symbol}
                </ThemedText.TextPrimary>
              </Row>
            </Column>
          }
          amount={
            <Column width="100%" align="flex-end" gap="sm">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.AMOUNT}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                {balance ? formatCurrencyAmount(balance, 4) : '--'} {asset.symbol}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontWeight={500} fontSize={13}>
                $
                {formatAmount(
                  BN(ethPrice || 0)?.times(
                    BN(currToken.derivedETH ? currToken.derivedETH : 1)?.times(BN(balance?.toSignificant() || 0))
                  ),
                  4,
                  true
                )}
              </ThemedText.TextSecondary>
            </Column>
          }
          manage={
            <Row gap="xs">
              <ManagerButton width="100%" onClick={handlerSwap}>
                Swap
              </ManagerButton>
              {/* <ManagerButton width="100%">Earn</ManagerButton> */}
            </Row>
          }
          first={assetListIndex === 0}
          last={assetListIndex === assetListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
