import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useActiveChainId } from 'connection/useActiveChainId'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { useAtom } from 'jotai'
import AddBribesModal from 'pages/Vote/AddBribesModal'
import { VoteDataProps } from 'pages/Vote/types'
import { ReactNode, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as NoDataDarkIcon } from '../../assets/imgs/noData_dark.svg'
import { ReactComponent as NoDataLightIcon } from '../../assets/imgs/noData_light.svg'
import FarmPoolModal from './FarmPoolModal'
import { useLiquidityData, useLiquidityLength } from './hooks'
import { HeaderRow, LoadedRow, LoadingRow } from './LiquidityRow'
import { LiquiditySortPage } from './state'
import { StyledPagination } from './StyledPagination'
export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
`

export const NoTokenDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 0;
  gap: 40px;
`

export function NoTokensState({ message, thRow }: { message: ReactNode; thRow: ReactNode }) {
  const theme = useTheme()
  return (
    <GridContainer>
      {thRow}
      <NoTokenDisplay>
        {theme.darkMode ? <NoDataDarkIcon /> : <NoDataLightIcon />}
        <ThemedText.TextPrimary fontWeight={700} fontSize={14}>
          {message}
        </ThemedText.TextPrimary>
      </NoTokenDisplay>
    </GridContainer>
  )
}

export function NoTokensStateM({ message }: { message: ReactNode }) {
  const theme = useTheme()
  return (
    <GridContainer>
      <NoTokenDisplay>
        {theme.darkMode ? <NoDataDarkIcon /> : <NoDataLightIcon />}
        <ThemedText.TextPrimary fontWeight={700} fontSize={14}>
          {message}
        </ThemedText.TextPrimary>
      </NoTokenDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function Table() {
  const { account } = useActiveChainId()
  const [timeCount, setTimeCount] = useState(10)
  // const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)

  const location = useLocation()
  const [liquiditySortPage, setLiquiditySortPage] = useAtom(LiquiditySortPage)
  const isProfile = useMemo(() => {
    return location.pathname == '/profile' ? true : false
  }, [location])

  // const { loading: positionsLoading } = useV3Positions(account)

  const liquidityListData = useLiquidityData()

  // const loadingLiquiditys = positionsLoading

  const allLiquidityList = liquidityListData
  const liquidityListStaked = allLiquidityList?.filter((item: any) => Number(item.stakeData.tvl) > 0)
  const liquidityList = isProfile ? liquidityListStaked : allLiquidityList

  const [showAddBribes, setShowAddBribes] = useState(false)
  const [showFarmPool, setShowFarmPool] = useState(false)
  const [currentLiquidity, setcurrentLiquidity] = useState<undefined | VoteDataProps>()

  const handleDismissCreate = () => {
    setShowFarmPool(false)
  }

  const handleDismissAddBribes = () => {
    setShowAddBribes(false)
  }

  const addBribeChage = (data: VoteDataProps) => {
    if (!data) return
    setcurrentLiquidity(data)
    setShowAddBribes(true)
  }

  const showPoolMidalChage = (data: VoteDataProps) => {
    if (!data) return
    setcurrentLiquidity(data)
    setShowFarmPool(true)
  }
  const handleNextPage = (e: any, p: any) => {
    setLiquiditySortPage(p)
  }
  const count = useLiquidityLength()
  /* loading and error state */
  if (!liquidityList) {
    return <LoadingTokenTable rowCount={PAGE_SIZE} />
  } else if (liquidityList?.length === 0) {
    return <NoTokensState thRow={<HeaderRow />} message={<Trans>No liquidityList found</Trans>} />
  } else {
    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {liquidityList.map((liquidity, index) => (
            <LoadedRow
              key={index}
              addBribeChage={addBribeChage}
              showPoolMidalChage={showPoolMidalChage}
              liquidityListIndex={index}
              liquidityListLength={liquidityList.length}
              liquidity={liquidity}
            />
          ))}
          {!isProfile && <StyledPagination style={{ marginTop: '8px' }} count={count} onChange={handleNextPage} />}
          <AddBribesModal
            brigeType="LP"
            isOpen={showAddBribes}
            details={currentLiquidity}
            count={timeCount}
            setCount={setTimeCount}
            onDismiss={handleDismissAddBribes}
          />
          <FarmPoolModal
            poolAddress={currentLiquidity?.lpAddr}
            token0={currentLiquidity?.token0}
            token1={currentLiquidity?.token1}
            fee={currentLiquidity?.poolFee}
            isOpen={showFarmPool}
            hypervisorAddr={currentLiquidity?.hypervisorAddress}
            onDismiss={handleDismissCreate}
          />
        </TableDataContainer>
      </>
    )
  }
}
