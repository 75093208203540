import { Box } from 'rebass'
import styled from 'styled-components/macro'

export const ChartBox = styled(Box)`
  padding: 0px 8px 8px;
  filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));
  overflow: hidden;

  .charts {
    height: 436px;
    border-radius: 16px;
    background: rgba(24, 24, 24, 1);
  }
`

export const ChartInfoCard = styled(Box)`
  background: ${({ theme }) => theme.backgroundSurface};
  border-radius: 16px;
  fill: #fff;
  filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));
`
