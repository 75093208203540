import { useMediaQuery } from '@mui/material'
import { AutoColumn } from 'components/Column'
import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useAllPools } from 'hooks/useQueryAllPools'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { usePositionSwapActionHandlers } from 'state/positionSwap/hooks'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'

import ChartInfo from './ChartInfo'
import { NavTabList } from './NavTabList'
import TradeRightWrapper from './TradeRightWrapper'
import TradeTipsDialog from './TradeTipsDialog'
const TradeWrapper = styled(Row)`
  grid-gap: 16px;
  align-items: flex-start;

  top: 200px;

  .trade-l {
    flex: 1;
  }
  .trade-r {
    width: 394px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    flex-direction: column-reverse;
    .trade-l,
    .trade-r {
      width: 100%;
    }
  }
`

export default function Trade() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const Pools = useAllPools()
  const [initInfo, setInfo] = useState(false)
  const { onTradeInfo } = usePositionSwapActionHandlers()

  const { account } = useActiveChainId()
  const publicAddress = account?.toLowerCase()
  const [timeCount, setTimeCount] = useState(5)
  const [showTradeTips, setShowTradeTips] = useState<any>(Cookies.get('showTradeTips' + publicAddress))

  useEffect(() => {
    if (!Pools) return
    if (initInfo) return
    if (!Object.values(Pools)[0]) return
    onTradeInfo(Object.values(Pools)[0])
    setInfo(true)
  }, [Pools, initInfo, onTradeInfo])
  const handleDismiss = () => {
    setShowTradeTips(true)
    Cookies.set('showTradeTips' + publicAddress, 'true', {
      expires: 30,
      SameSite: 'Strict',
    })
  }

  const handleClose = () => {
    setShowTradeTips(true)
  }

  return (
    <PageWrapper data-testid="trade-page">
      <TradeTipsDialog
        isShow={!showTradeTips}
        handleDismiss={handleDismiss}
        handleClose={handleClose}
        count={timeCount}
        setCount={setTimeCount}
      />
      <div className="scale-out-center scale-in-center">
        <TradeWrapper>
          <div className="trade-l">
            {/* <TradeLeftWrapper /> */}
            <AutoColumn gap="xmd" width="100%">
              {!isSmallScreen && <ChartInfo />}
              <NavTabList />
            </AutoColumn>
          </div>
          <div className="trade-r">
            <AutoColumn gap="xmd" width="100%">
              {isSmallScreen && <ChartInfo />}
              <TradeRightWrapper />
            </AutoColumn>
          </div>
        </TradeWrapper>
      </div>
    </PageWrapper>
  )
}
