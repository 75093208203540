import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import Column from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row, { RowBetween } from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as ToIcon } from '../../../assets/imgs/to_black.svg'

// interface PreviewProps {}

const PreviewWrapper = styled(Column)`
  width: 100%;
  grid-gap: 8px;
  margin-top: 16px;
  position: relative;
  .toBox {
    width: 64px;
    height: 42px;
    border-radius: 24px;
    background-color: ${({ theme }) => theme.backgroundModule2};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;

    .toBg {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-color: ${({ theme }) => theme.backgroundTo};
    }
  }
`

const PreviewBox = styled(Row)`
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.backgroundPreview};
  border: 1px solid ${({ theme }) => theme.backgroundPreviewBorder};
`

const FromPreviewBox = styled(PreviewBox)`
  border-radius: 12px 12px 0 0;
`
const ToPreviewBox = styled(PreviewBox)`
  border-radius: 0 0 12px 12px;
`

type SWAPPreviewProps = {
  inputCurrency?: CurrencyAmount<Currency>
  outputCurrency?: CurrencyAmount<Currency>
  inputtrade?: JSX.Element
  outputtrade?: JSX.Element
}
export default function SwapSectionPreview({
  inputCurrency,
  outputCurrency,
  inputtrade,
  outputtrade,
}: SWAPPreviewProps) {
  const inputUsd = inputtrade
  const outputUsd = outputtrade
  return (
    <PreviewWrapper>
      <div className="toBox">
        <Row justify="center" align="center" className="toBg">
          <ToIcon />
        </Row>
      </div>
      <FromPreviewBox>
        <RowBetween>
          <CurrencyLogo currency={inputCurrency?.currency} size="48px" />

          <Column gap="md" align="flex-end">
            <Row>
              <ThemedText.TextPrimary fontSize={28} fontWeight={700}>
                {inputCurrency?.toExact() || 0}
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontSize={28} fontWeight={700} ml="4px">
                {inputCurrency?.currency.symbol}
              </ThemedText.TextPrimary>
            </Row>
            <ThemedText.TextSecondary fontSize={14}>{inputUsd || '-'}</ThemedText.TextSecondary>
          </Column>
        </RowBetween>
      </FromPreviewBox>
      <ToPreviewBox>
        <RowBetween>
          <CurrencyLogo currency={outputCurrency?.currency} size="48px" />
          <Column gap="md" align="flex-end">
            <Row>
              <ThemedText.TextPrimary fontSize={28} fontWeight={700}>
                {outputCurrency?.toExact() || 0}
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontSize={28} fontWeight={700} ml="4px">
                {outputCurrency?.currency.symbol}
              </ThemedText.TextPrimary>
            </Row>
            <ThemedText.TextSecondary fontSize={14}>{outputUsd || '-'}</ThemedText.TextSecondary>
          </Column>
        </RowBetween>
      </ToPreviewBox>
    </PreviewWrapper>
  )
}
