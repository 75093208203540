import { Trans } from '@lingui/macro'
import { Box, Checkbox } from '@mui/material'
import { Currency, Percent } from '@uniswap/sdk-core'
import { ButtonConfirmed } from 'components/Button'
import { useDynamicApprove } from 'components/DynamicApprove'
import Row from 'components/Row'
import {
  ConfirmationModalContent2222,
  TransactionConfirmationModal22222,
} from 'components/TransactionConfirmationModal'
import dayjs from 'dayjs'
import { useMasterChefV3, useSwapMinning, useVotingReward } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import LockAmount from 'pages/Lock/LockModal/CreateLockModal/LockAmount'
import LockPicker from 'pages/Lock/LockModal/CreateLockModal/LockPicker'
import { useEffect, useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { getEndRound } from 'utils/CurrRound'
import { handlerError } from 'utils/formatError'

import { ReactComponent as AgreedIcon } from '../../../assets/imgs/agreedIcon.svg'
import { ReactComponent as AgreeIcon } from '../../../assets/imgs/agreeIcon.svg'
import NoteIcon from '../../../assets/imgs/noteIcon.svg'
import { VoteDataProps } from '../types'
import { useListTokens } from './hooks'

const TypesBox = styled(Row)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  padding: 16px;
  border-radius: 16px;
  margin-top: 8px;
`
interface AddBribesModalProps {
  brigeType: string //brigeType="VOTE" "LP" "Trade"
  isOpen: boolean
  onDismiss: () => void
  details?: VoteDataProps
  count: any
  setCount: any
}

const time = getEndRound()
const day = dayjs.unix(time).format('YYYY/MM/DD')
export default function AddBribesModal({
  brigeType,
  isOpen,
  onDismiss,
  details,
  count,
  setCount,
}: AddBribesModalProps) {
  const theme = useTheme()
  const [showNote, setShowNote] = useState<any>(true)
  const [showNext, setShowNext] = useState<any>(false)
  const votingContract = useVotingReward(details?.votingRewardAddress)
  const masterChefContract = useMasterChefV3(details?.masterChefAddress)
  const swapMinningContract = useSwapMinning(details?.swapMinningAddress)
  const brigeContract = useMemo(
    () => (brigeType == 'VOTE' ? votingContract : brigeType == 'LP' ? masterChefContract : swapMinningContract),
    [brigeType, masterChefContract, swapMinningContract, votingContract]
  )

  const tokens = useListTokens(brigeContract)
  const [currenty, setcurrenty] = useState<Currency | undefined>()

  const { handleUserInput, inputAmount, setInputAmount, isInputValid } = useInputAmount(currenty)

  const setCURRENY = (data: any) => {
    setcurrenty(data.amount.currency)
    setInputAmount(data)
  }
  const { DynamicApprove, isApproved } = useDynamicApprove([inputAmount.amount], brigeContract?.address)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const handleDismissConfirmation = () => {
    onDismiss && onDismiss()
    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash('')
      setTxError('')
    }, 300)
  }
  const addTransaction = useTransactionAdder()

  const Add = async () => {
    const amount = inputAmount?.amount?.numerator.toString()
    const token = inputAmount.amount?.currency.wrapped.address
    if (!amount || !brigeContract || !token) return
    setAttemptingTxn(true)
    try {
      const response = await brigeContract.notifyRewardAmount(token, amount)
      addTransaction(response, {
        type: TransactionType.ADD_BRIBES,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('error', error)
    }
  }

  const [defaultCheckedValue, setCheckValue] = useState(false)

  useEffect(() => {
    const tick = setInterval(() => {
      count - 1 == 0 ? setShowNext(true) : setCount(count - 1)
    }, 1000)

    return () => clearInterval(tick)
  }, [count, setCount])

  const modalContent = (
    <>
      <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
        <Trans>Types</Trans>
      </ThemedText.TextSecondary>
      <TypesBox>
        <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
          <Trans>{brigeType}</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextSecondary fontWeight={700} fontSize={14} ml="12px" mr="12px">
          <Trans>|</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
          {details?.token0.symbol}-{details?.token1.symbol}{' '}
          {new Percent(details?.poolFee || 0, 1_000_000).toSignificant()}%
        </ThemedText.TextSecondary>
      </TypesBox>
      <Box mt="16px">
        <LockAmount
          tokens={tokens}
          handleUserInput={handleUserInput}
          inputAmount={inputAmount}
          setInputAmount={setCURRENY}
        />
      </Box>
      <LockPicker title="Ends in" />
      <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
        {day}
      </ThemedText.TextSecondary>
      <Box
        style={{ cursor: 'pointer' }}
        bgcolor={theme.addAgreeBg}
        padding="8px 0"
        borderRadius="8px"
        display="flex"
        onClick={() => setCheckValue(!defaultCheckedValue)}
      >
        <Checkbox checked={defaultCheckedValue} icon={<AgreeIcon />} checkedIcon={<AgreedIcon />} />
        <ThemedText.DeprecatedWhite fontWeight={defaultCheckedValue ? 700 : 400} fontSize={12}>
          <Trans>
            Please note, this is NOT for LP staking, this is for pool incentivization. If you add a bribe, you will NOT
            get your tokens back as they will be used as incentives.
          </Trans>
        </ThemedText.DeprecatedWhite>
      </Box>
      <DynamicApprove />
      <Box mt="8px">
        <ButtonConfirmed disabled={!isApproved || !isInputValid || !defaultCheckedValue} onClick={Add}>
          Add
        </ButtonConfirmed>
      </Box>
    </>
  )

  const noteModalContent = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" width="100%" justifyContent="center">
        <img src={NoteIcon} />
      </Box>
      <Box display="flex" flexDirection="column" p="0 16px">
        <div className="textCenter" style={{ letterSpacing: '-0.2px' }}>
          <ThemedText.TextSecondary fontWeight={700} fontSize={14} mt="20px" style={{ color: '#FF0000' }} className="">
            <Trans>
              This is NOT for LP staking, <br />
              this is for pool incentivization.
            </Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={700} fontSize={14} mt="24px" className="">
            <Trans>If you add a bribe, you will NOT get your tokens back as they will be used as incentives.</Trans>
          </ThemedText.TextPrimary>
        </div>
        <Box mt="28px" width="100%">
          {showNext ? (
            <ButtonConfirmed onClick={() => setShowNote(false)}>Next</ButtonConfirmed>
          ) : (
            <ButtonConfirmed disabled={true}>{count} s</ButtonConfirmed>
          )}
        </Box>
      </Box>
    </Box>
  )

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      onDismiss={handleDismissConfirmation}
      width={showNote ? '394px' : '500px'}
      pendingText={pendingText}
      reviewContent={
        <ConfirmationModalContent2222
          title="Add Bribes"
          onDismiss={handleDismissConfirmation}
          paddingStr={showNote ? '16px 0' : '16px'}
          topContent={showNote ? noteModalContent : modalContent}
        />
      }
    />
  )
}
