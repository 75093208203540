import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { ClaimButton } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { useToken } from 'hooks/Tokens'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { useFetchVoter } from 'pages/Vote/hooks'
import { TotalRewardsItem, TotalVoteEarnsItem } from 'pages/Vote/VoteRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount, formatAmount2 } from 'utils/formatAmout'
import { unwrappedToken } from 'utils/unwrappedToken'

import { LoadedVoteRowProps } from './RewardRow'
import { TokenSortMethod } from './state'

/* Token Row: skeleton row component */
function RewardRow({
  pools,
  totalRewards,
  totalVotes,
  my_vote,
  earn,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  pools: ReactNode
  totalRewards: ReactNode
  totalVotes: ReactNode
  my_vote: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="pools-cell">{pools}</TextLeftCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="rewards-cell">{totalRewards}</TextLeftCell>
        <TextRightCell data-testid="votes-cell">{totalVotes}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="my-vote-cell">{my_vote}</TextLeftCell>
        <TextRightCell data-testid="earn-cell">{earn}</TextRightCell>
      </RowBetween>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <RewardRow
      $loading
      key={props?.first + '' + props?.last}
      pools={
        <Column width="100%" align="flex-start" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.POOLS}</Trans>
          </ThemedText.TextSecondary>
          <Row gap="sm">
            <IconLoadingBubble width="48px" height="48px" />
            <Column gap="xs" align="flex-start" className="flex-1" width="100%">
              <MediumLoadingBubble />
              <MediumLoadingBubble />
            </Column>
          </Row>
        </Column>
      }
      totalRewards={
        <Column width="100%" align="flex-start" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.TOTAL_REWARDS}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      totalVotes={
        <Column width="100%" align="flex-end" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.TOTAL_REWARDS}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      my_vote={
        <Column width="100%" align="flex-start" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.MY_VOTE}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      earn={
        <Column width="100%" align="flex-end" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.EARN}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <Row mt="4px" justify="end">
            <ClaimButton>Claim</ClaimButton>
          </Row>
        </Column>
      }
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedVoteRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { reward, rewardListIndex, rewardListLength } = props

  const { voteList } = useFetchVoter()
  const arr = voteList?.filter((vote) => vote.lpAddr == reward.votedPool.lpAddr)
  const rewardsList = arr && arr.length > 0 ? arr[0].rewardsList : []
  const votingRewardAddress = arr && arr.length > 0 ? arr[0].votingRewardAddress : ''
  const votetotalRewardsUSD = arr && arr.length > 0 ? arr[0].votetotalRewardsUSD : ''
  const weeklyVotedTotal = arr && arr.length > 0 ? arr[0].weeklyVotedTotal : ''
  const poolWeightRatio = arr && arr.length > 0 ? arr[0].poolWeightRatio : BN(0)
  const epochVote = arr && arr.length > 0 ? arr[0].epochVote : 0
  const epochVoteTotal = arr && arr.length > 0 ? arr[0].epochVoteTotal : 0

  const token0 = useToken(reward.votedPool.token0.id)
  const token1 = useToken(reward.votedPool.token1.id)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  return (
    <>
      <div ref={ref} data-testid={`reward-table-row-${reward?.tokenId}`}>
        <RewardRow
          pools={
            <Column width="100%" align="flex-start" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.POOLS}</Trans>
              </ThemedText.TextSecondary>
              <Row gap="sm">
                <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={40} margin />
                <Column gap="xs" align="flex-start">
                  <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                    {reward.votedPool.token0.symbol}-{reward.votedPool.token1.symbol}
                  </ThemedText.TextPrimary>
                  <ThemedText.TextSecondary fontSize={14}>
                    {new Percent(reward.votedPool.poolFee, 1_000_000).toSignificant()}%
                  </ThemedText.TextSecondary>
                </Column>
              </Row>
            </Column>
          }
          totalRewards={
            <Column width="100%" align="flex-start" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.TOTAL_REWARDS}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(votetotalRewardsUSD, 2, true)}
              </ThemedText.TextPrimary>
              {rewardsList && rewardsList.length > 0 ? (
                rewardsList?.map((item, index: number) => {
                  return <TotalRewardsItem key={'totalRewardItem' + index} rewardAddr={item} />
                })
              ) : (
                <ThemedText.TextSecondary fontSize={14}>--</ThemedText.TextSecondary>
              )}
            </Column>
          }
          totalVotes={
            <Column width="100%" align="flex-end" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.TOTAL_VOTES}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(Number(weeklyVotedTotal) / Math.pow(10, 18), 2, true)} veROX
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>{poolWeightRatio?.toFixed(2, 1)}%</ThemedText.TextSecondary>
            </Column>
          }
          my_vote={
            <Column width="100%" align="flex-start" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.MY_VOTE}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount2(reward.weight, 18, 2, true)} veROX
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {Number(epochVoteTotal) == 0 || Number(epochVote) == 0
                  ? 0
                  : ((Number(epochVote) / Number(epochVoteTotal)) * 100).toFixed(2)}
                %
              </ThemedText.TextSecondary>
            </Column>
          }
          earn={
            <Column width="100%" align="flex-end" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{TokenSortMethod.EARN}</Trans>
              </ThemedText.TextSecondary>
              {rewardsList && rewardsList.length > 0 ? (
                rewardsList?.map((item, index: number) => {
                  return <TotalVoteEarnsItem key={'earn' + index} rewardAddr={item} poolAddr={votingRewardAddress} />
                })
              ) : (
                <ThemedText.TextRewards>--</ThemedText.TextRewards>
              )}
              <Row mt="4px" justify="end">
                <ClaimButton>Claim</ClaimButton>
              </Row>
            </Column>
          }
          first={rewardListIndex === 0}
          last={rewardListIndex === rewardListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
